<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-03-21 12:20:27
-->
<template>
    <div>
        <page-home-one v-if="cutTemplates === 'themeTemplateOne'"></page-home-one>
        <page-home-tow v-if="cutTemplates === 'themeTemplateTow'"></page-home-tow>
        <page-home-three v-if="cutTemplates === 'themeTemplateThree'"></page-home-three>
    </div>
</template>
<script>
// import pageHome from '@/components/page/home'
import mixins from '@/utils/mixins';
export default {
    name: 'homePage',
    mixins: [mixins],
    components: {
        // pageHome
    },
    data() {
        return {
            swiperList: []
        }
    }
}
</script>
<style lang="scss" scoped>

</style>