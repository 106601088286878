/*
 * @Description: 德科中文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-03-29 09:37:18
 */
export default {
    "DK.navTitle.Home": "首页",
    "DK.navTitle.Produch": "产品展示",
    "DK.navTitle.Cart": "购物车",
    "DK.navTitle.Exhibition": "展会计划",
    "DK.navTitle.Message": "联系 / 关于我们",
    "DK.logoBox.placeholder": "请输入产品关键字",
    "DK.ProductNumber": "产品编号",
    "DK.amount": "数量",
    "DK.contactUs": "联系我们",
    "DK.understandDeke": "了解德科",
    "DK.telephone": "座机",
    "DK.phoneAndWeixin": "手机 & 微信",
    "DK.PleaseOrderNum": "请输入订单号",
    "DK.ArticleNo": "货号",
    "DK.TotalVolume": "总体积",
    "DK.totalQTY": "总数量",
    "DK.totalItems": "总款数",
    "DK.ExhibitionRelatedProducts": "展会关联产品",
    "DK.ProductsVideo": "产品视频",
    "DK.ReleaseDate": "发布日期",
    "DK.inquirySheet": "询价单",
    "DK.SendInquiry": "发询价单",
    "DK.productPrice": "产品价格",
    "DK.OuterBoxCBM": "外箱体积",
    "DK.OuterBoxCUFT": "外箱材积",
    "DK.Innerboxes": "内盒个数",
    "DK.OuterCapacity": "外箱装量",
    "DK.productDetails": "产品详情",
    "DK.similarProduct": "相似产品",
    "DK.MyRFQ": "我的询价单",
    "DK.enquiry": "询价",
    "DK.contact": "联系方式",
    "DK.Description": "名称",
    "DK.OuterQTY": "外箱装量",
    "DK.LastSevenDays": "最近七天",
    "DK.All": "全部",
}
