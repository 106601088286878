<template>
  <div class="classify-box">
    <div class="search-tip">
      <p><span style="font-size:24px;font-weight:bold;color: #666666;">{{ $t('classify') }} </span>({{$t('DK.amount')}}：{{productCount}})</p>
      <p @click="isOpen=!isOpen">
        {{ isOpen ? $t('close') : $t('open') }}
        <i :class="isOpen? 'el-icon-arrow-up':'el-icon-arrow-down'"></i>
      </p>
    </div>
    <div class="classify-list">
      <ul>
        <li>
          <span class="el-dropdown-link" :class="activeClass('')? 'active' : ''" @click="clNuSearch({cl_Nu: ''})">{{$t('all')}}</span>
        </li>
        <li v-for="(item, index) in datas.slice(0, 24)" :key="index">
          <span
            class="el-dropdown-link"
            :class="activeClass(item.cl_Nu) ? 'active' : ''"
            @click="clNuSearch(item)"
            v-if="item.children.length ===0"
          >{{ language === 'en' ? item.cl_Ne_Na : language === 'ru' ? item.threeLanguage : item.cl_Na }}</span>
          <el-dropdown v-else placement="right">
            <span class="el-dropdown-link" :class="activeClass(item.cl_Nu) ? 'active' : ''" @click="clNuSearch(item)">
              {{ language === 'en' ? item.cl_Ne_Na : language === 'ru' ? item.threeLanguage : item.cl_Na }}
              <i
                class="el-icon-arrow-down el-icon--right"
                v-if="item.children.length > 0"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(child, k) in item.children" :key="k" :class="activeClass(item.cl_Nu)? 'active' : ''">
                <span
                  @click="clNuSearch(child)"
                >{{ language === 'en' ? child.cl_Ne_Na : language === 'ru' ? child.threeLanguage : child.cl_Na }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
      <el-collapse-transition>
        <ul v-show="isOpen">
          <li v-for="(item, index) in datas.slice(24)" :key="index">
            <span
              class="el-dropdown-link"
              :class="activeClass(item.cl_Nu)? 'active' : ''"
              @click="clNuSearch(item)"
              v-if="item.children.length ===0"
            >{{ language === 'en' ? item.cl_Ne_Na : language === 'ru' ? item.threeLanguage : item.cl_Na }}</span>
            <el-dropdown v-else placement="right">
              <span class="el-dropdown-link" :class="activeClass(item.cl_Nu)? 'active' : ''" @click="clNuSearch(item)">
                {{ language === 'en' ? item.cl_Ne_Na : language === 'ru' ? item.threeLanguage : item.cl_Na }}
                <i
                  class="el-icon-arrow-down el-icon--right"
                  v-if="item.children.length > 0"
                ></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(child, k) in item.children" :key="k" :class="activeClass(item.cl_Nu)? 'active' : ''">
                  <span
                    @click="clNuSearch(child)"
                  >{{ language === 'en' ? child.cl_Ne_Na : language === 'ru' ? child.threeLanguage : child.cl_Na }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </el-collapse-transition>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'classifySearch',
  props: {
    datas: {
      type: Array,
      default: () => []
    },
    active: {
      type: String,
      default: () => ''
    },
    productCount: {
      type:Number,
      default:0
    }
  },
  data() {
    return {
      isOpen: false,
      cl_Nu: ''
    }
  },
  computed: {
    activeClass() {
      return cl_Nu => {
        return this.active === cl_Nu
      }
    },
    ...mapState('operate', ['language'])
  },
  methods: {
    clNuSearch(item) {
      this.cl_Nu = item.cl_Nu
      this.$emit('classifyClick', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.classify-box {
  color: #333;
  font-size: 14px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;

  .search-tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .classify-list {
    width: 100%;

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 16.5%;
        height: 30px;
        line-height: 30px;
        // text-align: center;
        overflow: hidden;
        padding: 5px 0;

        span {
          cursor: pointer;
        }

        .active {
          color: #4A90F1;
        }
      }
    }
  }
}

.el-dropdown-menu{
  max-height: 420px;
  min-width: 120px;
  overflow: hidden;
  overflow-y: auto;
}
</style>