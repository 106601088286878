<template>
  <div class="nav-bar-box">
    <div class="container nav-box">
      <div class="nav-item">
        <el-menu text-color="#444444" :default-active="active" active-text-color="#4A90F1" router mode="horizontal">
          <el-menu-item index="/home">
            <div class="nav-btn">{{$t('DK.navTitle.Home')}}</div>
          </el-menu-item>
          <el-menu-item index="/product" @click="productClick">
            <div class="nav-btn">{{$t('DK.navTitle.Produch')}}</div>
          </el-menu-item>
          <el-menu-item index="/cart">
            <div class="nav-btn">{{!userInfo.id ? $t('DK.inquirySheet') : $t('DK.navTitle.Cart')}}</div>
          </el-menu-item>
          <el-menu-item index="/contact">
            <div class="nav-btn">{{$t('DK.navTitle.Exhibition')}}</div>
          </el-menu-item>
          <el-menu-item index="/about">
            <div class="nav-btn">{{$t('DK.navTitle.Message')}}</div>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapState } from 'vuex'
import bus from '@/utils/bus'
export default {
  name: 'navBar',
  components: {},
  props: {
    tabHeight: {
      type: Number,
      default: 60
    },
    menuBackground: {
      type: String,
      default: '#fe6a00'
    },
    textColor: {
      type: String,
      default: '#fff'
    },
    activetextColor: {
      type: String,
      default: '#fff'
    },
    hasSearchInput: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      keywords: '',
      active: this.$route.path
    }
  },
  watch: {
    $route: {
      handler() {
        this.active = this.$route.path
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('setting', {
      getMenus: 'get_menus'
    }),
    ...mapState('user', ['userInfo'])
  },
  methods: {
    productClick() {
      this.$bus.$emit('anewClick', '')
    },
    /* 图搜 */
    imgSearch() {
      if (this.$route.path !== '/product') {
        this.$router.push({ path: '/product', query: { isImg: true } })
      }
      bus.$emit('imgSearch', true)
    },

    enterSearch() {
      if (this.$route.path !== '/product') {
        this.$router.push({
          path: '/product',
          query: { keywords: this.keywords }
        })
      }
      this.$bus.$emit('searchProduct', this.keywords)
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-bar-box {
  width: 100%;

  .nav-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-item {
      flex: 1;
      .el-menu {
        height: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: none !important;

        .el-menu-item {
          flex: 1;
          font-size: 20px;
          // text-align: center;
          height: 100%;
          color: #444444;
          line-height: 60px;
          border-bottom: none !important;
          overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          .nav-btn {
            display: inline-block;
            border-bottom: 2px solid #fff;
          }

          &:hover {
            color: #4a90f1 !important;
          }
        }

        :deep(.is-active) {
          color: #4a90f1 !important;
          .nav-btn {
            display: inline-block;
            border-bottom: 3px solid #4a90f1;
          }
        }
      }
    }

    .search-box {
      width: 240px;
      display: flex;
      background: #fff;
      align-items: center;
      height: 40px;
      line-height: 40px;

      :deep(.el-input__inner) {
        border: 0;
      }

      .el-icon-camera {
        cursor: pointer;
        padding: 0 10px;
        color: #b2b2b2;
        font-size: 20px;
        border-left: 2px solid #f2f2f2;
      }

      .el-icon-search {
        cursor: pointer;
        padding: 0 10px;
        color: #b2b2b2;
        font-size: 20px;
        border-left: 2px solid #f2f2f2;
      }
    }
  }
}
</style>