/*
 * @Description: 新闻管理-新闻服务接口
 * @Author: zml
 * @Date: 2023-03-23 09:19:41
 * @LastEditors: zml
 * @LastEditTime: 2023-03-24 16:37:51
 */
import request from '@/utils/request'

// 分页查询新闻列表
export function QueryPage(data) {
  return request({
    url: 'front/News/QueryPage',
    method: 'post',
    data
  })
}

// 获取新闻分类列表
export function GetNewsCategoryList(data) {
  return request({
    url: 'front/News/GetNewsCategoryList',
    method: 'post',
    data
  })
}

// 获取新闻明细
export function GetDetail(data) {
  return request({
    url: 'front/News/GetDetail',
    method: 'post',
    data
  })
}

// 获取新闻绑定样品资料列表
export function queryPageByNewsList(data) {
  return request({
    url: 'front/ProductBasicWeb/QueryPageByNewsList',
    method: 'post',
    data
  })
}

// 获取新闻视频列表
export function getByNewsList(data) {
  return request({
    url: 'front/Video/GetByNewsList',
    method: 'post',
    data
  })
}