<template>
  <div class="orders">
    <table class="order-table" border="0" cellspacing="0" cellpadding="0">
      <tr>
        <td :colspan="isShowBtn?4:3" class="header-row">
          <h3>
            {{ $t('number') }}：
            <span>{{ orderInfo.orderNo }}</span>
          </h3>
        </td>
      </tr>
      <tr>
        <td class="data-row">
          <span class="lable">{{ $t('orderTime') }}：</span>
          {{ orderInfo.createdTime }}
        </td>
        <td class="data-row">
          <span class="lable">{{ $t('mobile') }}：</span>
          {{ orderInfo.mobile }}
        </td>
        <td class="data-row">
          <span class="lable">{{ $t('totalRecords') }}：</span>
          {{ orderInfo.productNumber }}
        </td>
        <td v-if="isShowBtn" class="data-row btn-list" rowspan="2">
          <el-button
            type="warning"
            plain
            @click="$router.push({ path: '/order/detail', query: { orderNo: orderInfo.orderNo } })"
          >{{ $t('viewDetails') }}</el-button>
        </td>
      </tr>
      <tr>
        <td class="data-row">
          <span class="lable">{{ $t('email') }}：</span>
          {{ userInfo.email }}
        </td>
        <td class="data-row">
          <span class="lable">{{ $t('totalPrice') }}：</span>
          {{ orderInfo.orderPrice }}
        </td>
        <td class="data-row">
          <span class="lable">{{ $t('linkman') }}：</span>
          {{ orderInfo.consignee }}
        </td>
      </tr>
      <tr>
        <td class="data-row">
          <span class="lable">{{ $t('orderStatus') }} ：</span>
          <span :class="orderInfo.orderStatus===0? 'red': 'blue'">{{ orderStatus(orderInfo.orderStatus)}}</span>
        </td>
        <td class="data-row">
          <span class="lable">{{$t('sendDate')}}：</span>
          {{ orderInfo.sendDate || '-' }}
        </td>
        <td class="data-row">
          <span class="lable">{{$t('expressNumber')}}：</span>
          {{ orderInfo.expressNumber || '-'}}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'orderBox',
  props: {
    orderInfo: {
      type: Object,
      default: () => { }
    },
    isShowBtn: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    orderStatus() {
      return status=>{
        switch(status) {
          case 0:
            return this.$t('unshipped')
          case 1:
            return this.$t('shipped')
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.orders {
  width: 100%;
  margin-bottom: 20px;

  & .order-table {
    width: 100%;
    border: solid 1px #e9e9e9;
    border-radius: 5px;

    & td {
      padding: 10px 15px;
    }

    & .header-row {
      background-color: #f5f5f5;

      & h3 {
        font-size: 16px;
        font-weight: normal;

        & span {
          color: #666;
        }
      }
    }

    & .data-row {
      padding: 5px 15px;
      width: 25%;
      font-size: 14px;
      height: 30px;
      line-height: 30px;

      & .lable {
        color: #666;
      }
      & .red {
        color: #f00;
      }

      & .blue {
        color: #1890ff;
      }
      &.btn-list {
        text-align: center;
      }
    }
  }
}
</style>