<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-03-21 12:22:04
-->
<template>
    <div class="container product-box">
        <page-product-one v-if="cutTemplates === 'themeTemplateOne'"></page-product-one>
        <page-product-tow v-if="cutTemplates === 'themeTemplateTow'"></page-product-tow>
        <page-product-three v-if="cutTemplates === 'themeTemplateThree'"></page-product-three>
    </div>
</template>
<script>
// import pageProduct from '@/components/page/product/index.vue'
import mixins from '@/utils/mixins';
export default {
    name: 'productPage',
    mixins: [mixins],
    components: {
        // pageProduct
    },
}
</script>
<style lang="scss" scoped>
.product-box {
    width: 100%;
    // background: #f1f3f6;
}
</style>