<template>
  <div>
    <div class="container swiper-box" v-if="swiperList.length > 0">
      <div class="nav-left">
        <category-menu :datas="setMenus" :height="tabHeight"></category-menu>
      </div>
      <swiper class="swipers" v-if="swiperList" :options="swiperOption">
        <swiper-slide v-for="(item, index) in swiperList" :key="index">
          <img :src="item.imgUrl" :alt="item.name" class="swiper-img" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <!-- 热销产品 -->
    <div class="hot-product">
      <hot-product :datas="hotProductsLsit"></hot-product>
    </div>

    <!-- 热门推荐 -->
    <div class="hot-recommendation">
      <hot-recommendation :datas="recommendList"></hot-recommendation>
    </div>

    <!-- 公司名片 -->
    <div class="businessCard">
      <business-card :datas="aboutDataList"></business-card>
    </div>

    <!-- 视频中心 -->
    <div class="videoCenter" v-if="videoList.length > 0">
      <video-center :datas="videoList"></video-center>
    </div>

    <!-- 和我们联系 -->
    <div class="contactUs">
      <contact-us :datas="contactUsData"></contact-us>
    </div>
  </div>
</template>
<script>
import {
  CarouselGetList,
  GetProductListByDate,
  GetProductListByDiscount,
  SiteSettingWebGetSiteList,
  ProductClassWebGetTree,
  VideoGetList,
  getCompanyInfo,
  GetContactInfo,
} from '@/api/home'
import { mapState, mapGetters } from 'vuex'
import categoryMenu from '@/components/themeTemplateTow/pc_components/navBar/category'
import hotProduct from '@/components/themeTemplateTow/pc_components/productBox/hotProduct.vue'
import hotRecommendation from '@/components/themeTemplateTow/pc_components/productBox/hotRecommend.vue'
import businessCard from '@/components/themeTemplateTow/pc_components/company/businessCard'
import videoCenter from '@/components/themeTemplateTow/pc_components/video/videoCenter'
import contactUs from '@/components/themeTemplateTow/pc_components/company/contactUs'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import transformMark from '@/utils/water'
export default {
  name: 'pageHome',
  components: {
    swiper,
    swiperSlide,
    categoryMenu,
    hotProduct,
    hotRecommendation,
    businessCard,
    videoCenter,
    contactUs,
  },

  props: {},

  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
        },
      },
      swiperList: [],
      newProductList: [],
      recommendList: [],
      hotProductsLsit: [],
      groupList: [],
      tabHeight: 66,
      setMenus: [],
      videoList: [],
      aboutDataList: {},
      contactUsData: {},
    }
  },
  popos: {},
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('setting', {
      getMenus: 'get_menus',
    }),
  },
  created() {
    this.initMenus()
  },
  mounted() {
    this.initData()
    this.gettingData()
  },
  methods: {
    //  热门推荐、热销产品
    async gettingData() {
      let _this = this
      const params = {
        client_Nu: _this.userInfo.client_Nu || '',
        num: 6,
        category: 0,
      }
      const parameters = {
        client_Nu: _this.userInfo.client_Nu || '',
        num: 9,
        category: 1,
      }
      const res = await GetProductListByDiscount(params) // 热门推荐
      if (res.success) {
        _this.recommendList = res.data || []
        _this.recommendList.forEach(item => {
          transformMark(item.imgUrl).then(result => {
            item.imgUrl = result
          })
        })
      } else {
        _this.$message.error(res.message)
      }

      const result = await GetProductListByDiscount(parameters) // 热销产品
      if (result.success) {
        _this.hotProductsLsit = result.data || []
		_this.hotProductsLsit.forEach(item => {
          transformMark(item.imgUrl).then(result => {
            item.imgUrl = result
          })
        })
      } else {
        _this.$message.error(result.message)
      }
    },
    initData() {
      let _this = this
      // 视频
      const model = {
        success: true,
        code: 0,
        message: 'string',
        data: {},
        extras: {},
        timestamp: new Date(),
      }
      VideoGetList(model)
        .then((res) => {
          if (res.success) {
            _this.videoList = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch((err) => {
          _this.$message.error(err)
        })
      // 首页轮播
      CarouselGetList()
        .then((res) => {
          if (res.success) {
            _this.swiperList = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch((err) => {
          _this.$message.error(err)
        })

      const data = {
        client_Nu: _this.userInfo.client_Nu || '',
        number: 4,
      }
      // 获取最新产品
      GetProductListByDate(data)
        .then((res) => {
          if (res.success) {
            _this.newProductList = res.data || []
            _this.newProductList.forEach(item => {
              transformMark(item.imgUrl).then(result => {
                item.imgUrl = result
              })
            })
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch((err) => {
          _this.$message.error(err)
        })
      // 分组产品
      SiteSettingWebGetSiteList()
        .then((res) => {
          if (res.success) {
            _this.groupList = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch((err) => {
          _this.$message.error(err)
        })

      // 公司简介
      getCompanyInfo()
        .then((res) => {
          if (res.success) {
            _this.aboutDataList = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch((err) => {
          _this.$message.error(err)
        })

      // 和我们联系
      GetContactInfo()
        .then((res) => {
          if (res.success) {
            _this.contactUsData = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch((err) => {
          _this.$message.error(err)
        })
    },
    initMenus() {
      let _this = this
      ProductClassWebGetTree()
        .then((res) => {
          if (res.success) {
            _this.setMenus = res.data
          } else {
            _this.$message(res.message)
          }
        })
        .catch((err) => {
          _this.$message({ err })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.swiper-box {
  display: flex;
  height: 500px;
  padding-top: 10px;

  & .swiper-img {
    width: 100%;
    height: 100%;
  }

  & :deep(.swiper-pagination-bullet-active) {
    background-color: #fca81e;
  }
}

.nav-left {
  width: 300px;
  color: #fff;
  margin-right: 20px;
  background-color: #140770;
  box-shadow: 0 0 60px #2c3baa inset;
}

.new-product {
  width: 100%;
}

.product-recommend {
  width: 100%;
  padding: 40px 0;
  // background-color: #badbec;
}

.swipers {
  flex: 1;
  height: 500px;
}

.hot-product {
  width: 100%;
  position: relative;
}

.hot-recommendation {
  width: 100%;
}

.videoCenter {
  margin-top: 40px;
  width: 100%;
}

.contactUs {
  width: 100%;
}
</style>
