<template>
  <div class="big-box">
    <div class="container">
      <el-card class="order-container">
        <div slot="header">
          <h3>{{ $t('userAddress') }}</h3>
          <div style="margin-top: 20px;">
            <el-button icon="el-icon-plus" type="warning" @click="addAddress">{{ $t('add') }}</el-button>
          </div>
        </div>
        <div v-if="addressList.length > 0">
          <div v-for="(item, index) in addressList" :key="index" class="address-item">
            <div class="address-tools">
              <div v-if="item.isDefault" class="default">{{ $t('defaultAddress')}}</div>
              <div v-else></div>
              <div class="set" style="display: flex;align-items: right;justify-content: right;">
                <el-button @click="editAddress(item.id)" size="small" icon="el-icon-edit">{{$t('edit')}}</el-button>
                <el-button @click="delAddress(item.id)" size="small" icon="el-icon-delete">{{$t('delete')}}</el-button>
              </div>
            </div>
            <el-descriptions :title="$t('addressinformation')">
              <el-descriptions-item :label="$t('consignee')">{{ item.consignee }}</el-descriptions-item>
              <el-descriptions-item :label="$t('addressDetail')">{{ item.addressDetail}}</el-descriptions-item>
              <el-descriptions-item :label="$t('mobile')">{{ item.mobile }}</el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
          </div>
          <div class="pagination">
            <el-pagination
              :current-page="queryParam.pageNo"
              :page-size="queryParam.size"
              :page-sizes="[10, 20, 30, 40]"
              :total="totalRows"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>

        <el-empty :description="$t('noData')" v-else style="color: #909399;font-size: 14px;"></el-empty>
      </el-card>

      <el-dialog
        :title="isEdit ? $t('Edittheaddress') : $t('AddinganAddress')"
        :visible.sync="dialogVisible"
        width="30%"
        @close="closeDidlog"
        :close-on-click-modal="false"
      >
        <span>
          <el-form ref="form" :model="form" label-width="200px" :rules="rules">
            <!-- 收货人 -->
            <el-form-item :label="$t('consignee')" prop="consignee">
              <el-input v-model="form.consignee"></el-input>
            </el-form-item>
            <!-- 联系方式 -->
            <el-form-item :label="$t('mobile')" prop="mobile">
              <el-input v-model="form.mobile"></el-input>
            </el-form-item>
            <!-- 详细地址 -->
            <el-form-item :label="$t('addressDetail')" prop="addressDetail">
              <el-input type="textarea" v-model="form.addressDetail"></el-input>
            </el-form-item>
            <!-- 设置默认 -->
            <el-form-item :label="$t('defaultSettings')">
              <el-switch v-model="form.isDefault"></el-switch>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeDidlog()">{{ $t('cancel') }}</el-button>
          <el-button type="primary" @click="Ok">{{ $t('Ok') }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  QueryPage,
  addressDeleteApi,
  addressAddApi,
  addressUpdateApi,
  addressGetInfoApi
} from '@/api/address'
export default {
  name: 'pageAddress',
  data() {
    return {
      isEdit: false, // 区分是新增还是编辑
      // 表单
      form: {
        id: 0,
        consignee: '', // 收货人
        mobile: '', // 联系方式
        addressDetail: '', // 详细地址
        area: '', //
        isDefault: false // 设置默认
      },
      totalRows: 0,
      dialogVisible: false, // 弹窗
      addressList: [], // 地址数据
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        sortField: '',
        sortOrder: '',
        keywords: '',
        openId: ''
      },
      addressFormData: {
        consignee: '', // 收货人
        mobile: '', // 联系方式
        addressDetail: '', // 详细地址
        isDefault: false // 设置默认地址
      },
      rules: {
        consignee: [
          {
            required: true,
            message: this.$t('Address_Consignee'),
            trigger: 'change'
          }
        ],
        mobile: [
          {
            required: true,
            message: this.$t('Address_Mobile'),
            trigger: 'change'
          }
        ],
        addressDetail: [
          {
            required: true,
            message: this.$t('Address_Address'),
            trigger: 'change'
          }
        ]
      },
      language: this.$t('language')
    }
  },
  created() {
    this.initData()
  },
  methods: {
    closeDidlog() {
      this.dialogVisible = false
      this.form.id = 0
      this.$refs.form.resetFields()
    },
    // 弹窗确认事件
    Ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const formData = Object.assign({}, this.form)
          if (!this.isEdit) {
            // 新增
            addressAddApi(this.form).then(res => {
              if (res.success) {
                if (this.form.isDefault && this.addressList.length > 0) {
                  this.addressList.forEach(item => (item.isDefault = false))
                  this.addressList.unshift(formData)
                } else {
                  this.addressList.push(formData)
                }

                this.totalRows++
                this.closeDidlog()
                this.dialogVisible = false
              }
            })
          } else {
            // 编辑
            addressUpdateApi(this.form).then(res => {
              if (res.success) {
                const index = this.addressList.findIndex(
                  item => item.id === formData.id
                )
                if (index > -1) {
                  if (formData.isDefault) {
                    this.addressList.forEach(item => (item.isDefault = false))
                  }
                  this.addressList[index] = formData
                }
                this.closeDidlog()
                this.dialogVisible = false
              }
            })
          }
        }
      })
    },
    // 新增地址
    addAddress() {
      this.dialogVisible = true
      this.isEdit = false
    },
    // 编辑地址
    editAddress(id) {
      this.dialogVisible = true
      this.isEdit = true
      addressGetInfoApi({ id: id }).then(res => {
        if (res.success) {
          this.form = { ...this.form, ...res.data }
        }
      })
    },
    // 删除收货地址
    delAddress(id) {
      let idsArr = []
      let cart = this.addressList
      idsArr.push(id)
      this.$confirm(this.$t('deleteData'), this.$t('systemTip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      })
        .then(() => {
          addressDeleteApi({ ids: idsArr }).then(res => {
            if (res.success) {
              this.$message('删除成功')
              let index = cart.findIndex(item => item.id === id)
              if (index > -1) {
                cart.splice(index, 1)
                this.totalRows--
              }
            }
          })
        })
        .catch(() => {})
    },
    // 初始化数据
    initData() {
      QueryPage(this.queryParam).then(res => {
        if (res.success) {
          this.addressList = res.data.rows
          this.totalRows = res.data.totalRows
        }
      })
    },
    handleSizeChange(pageSize) {
      this.queryParam.pageSize = pageSize
      this.initData()
    },
    handleCurrentChange(pageNo) {
      this.queryParam.pageNo = pageNo
      this.initData()
    }
  }
}
</script>

<style scoped lang="scss">
.big-box {
  padding: 20px 0;
  background: #f5f5f5;
}
:deep .el-form-item {
  padding: 0 60px 0 0;
}
.address-item {
  width: 100%;
  & .address-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: solid 1px #f5f5f5;

    & .default {
      padding: 0 10px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 12px;
      border-radius: 25px;
      background-color: rgb(254, 106, 0);
    }
  }
}
.pagination {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 15px;
}
</style>