<template>
  <div class="container detail" v-html="language=== 'en' ? datas.enContent : language=== 'ru' ? datas.enContent : datas.content"></div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'pageDetail',
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  }
}
</script>
<style lang="scss" scoped>
.detail {
  padding: 40px 0;

  & img {
    max-width: 100%;
  }
}
</style>
