<template>
    <div>
        <contact-detail-one v-if="cutTemplates === 'themeTemplateOne'" :datas="contactData"></contact-detail-one>
        <contact-detail-tow v-if="cutTemplates === 'themeTemplateTow'" :datas="contactData"></contact-detail-tow>
        <contact-detail-three v-if="cutTemplates === 'themeTemplateThree'" :datas="contactData"></contact-detail-three>
    </div>
</template>
<script>
// import contactDetail from '@/components/page/detail/index' 
import { GetContactInfo } from '@/api/home'
import mixins from '@/utils/mixins';
export default {
    name: 'contactPage',
    mixins: [mixins],
    components: {
        // contactDetail
    },
    data () {
        return {
            contactData: {}
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            let _this = this
            GetContactInfo().then(res=>{
                if(res.success) {
                    _this.contactData = res.data
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>