<template>
    <div class="header-box">
        <div class="header-top"><header-top></header-top></div>
        <nav-bar></nav-bar>
    </div>
</template>

<script>
    import headerTop from '@/components/themeTemplateTow/pc_components/headerTop/index'
    import navBar from '@/components/themeTemplateTow/pc_components/navBar/index'
    export default {
        components: {
            headerTop,
            navBar
        }
    }
</script>
<style lang="scss" scoped>
    .header-box {
        .header-top {
            margin-bottom: 32px;
        }
    }
</style>