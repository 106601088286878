import Vue from 'vue'
import VueRouter from 'vue-router'

import homePage from '@/views/home'
import productPage from '@/views/product'
import proDetailPage from '@/views/product/detail'
import cartPage from '@/views/cart'
import orderPage from '@/views/order'
import orderDetailPage from '@/views/order/detail'
import aboutPage from '@/views/about/index'
import contactPage from '@/views/about/contact'
import addressPage from "../views/address"
import newDetail from "@/views/about/newDetail"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/home'
}, {
    name: 'home',
    path: '/home',
    component: homePage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'product',
    path: '/product',
    component: productPage,
    meta: {
        keepAlive: true // 开启缓存
    }
},
{
    name: 'proDetail',
    path: '/proDetail',
    component: proDetailPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'cart',
    path: '/cart',
    component: cartPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'order',
    path: '/order',
    component: orderPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'address',
    path: '/address',
    component: addressPage,
    meta: {
        keepAlive: false
    }
},

{
    name: 'orderDetail',
    path: '/order/detail',
    component: orderDetailPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'about',
    path: '/about',
    component: aboutPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'contact',
    path: '/contact',
    component: contactPage,
    meta: {
        keepAlive: false
    }
},
{
    name: 'newDetail',
    path: '/newDetail',
    component: newDetail,
    meta: {
        keepAlive: false
    }
}]

const router = new VueRouter({
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
        
    }
})

export default router