<template>
    <!-- 视频中心 -->

    <div class="container product-box" v-if="datas.length > 0">
        <!-- 标题 -->
        <h1>{{ $t('videocenter') }}</h1>

        <!-- 视频内容 -->
        <div class="product_video">
            <!-- 左侧视频 -->
            <div class="video_left">
                <img :src="datas[0].imgUrl" alt="baby image" />
                <div class="video_paly">
                    <i class="el-icon-video-play play" @click="playClick(0)"></i>
                </div>
            </div>

            <!-- 右侧视频 -->
            <div class="video_right">
                <div class="r_top">
                    <img :src="datas[1].imgUrl" alt="baby image" class="r_top_img" />
                    <div class="video_paly">
                        <i class="el-icon-video-play play" @click="playClick(1)"></i>
                    </div>
                </div>
                <div class="r_bottom">
                    <div class="b_left">
                        <img :src="datas[2].imgUrl" alt="baby image" />

                        <div class="video_paly">
                            <i class="el-icon-video-play play" @click="playClick(2)"></i>
                        </div>
                    </div>
                    <div class="b_right">
                        <img :src="datas[3].imgUrl" alt="baby image" />
                        <div class="video_paly">
                            <i class="el-icon-video-play play" @click="playClick(3)"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- 弹窗 -->
        <div class="videoDialog">
            <el-dialog :title="$t('Video_playback')" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
                <span>
                    <video ref="videoPlayer" controls>
                        <source :src="videoUrl" type="video/mp4" />
                    </video>
                </span>
                <span slot="footer" class="dialog-footer"></span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name: 'videoCenter',
    props: {
        datas: {
            type: Array,
            default: () => [],
        },
    },
    data () {
        return {
            dialogVisible: false,
            videoUrl: '',
        }
    },
    methods: {
        // 关闭视频
        handleClose (done) {
            done()
        },
        // 点击播放icon
        playClick (index) {
            let _this = this
            _this.videoUrl = ''
            _this.videoUrl = _this.datas[index].videoUrl
            _this.dialogVisible = true
        },
    },
}
</script>

<style lang="scss" scoped>
.product-box {
    border-radius: 20px;

    // 标题
    h1 {
        font-size: 30px;
        font-weight: 700;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000;
        text-align: center;
        text-align: left;
    }

    // 视频内容
    .product_video {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 700px;

        // 左侧视频
        .video_left {
            position: relative;
            flex: 1;
            margin-bottom: 15px;
            height: 640px;
            overflow: hidden;


            img {
                max-width: 100%;
                max-height: 100%;
                border-radius: 15px;
            }

            .video_paly {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .play {
                    font-size: 70px;
                    color: rgba(255, 255, 255);
                    cursor: pointer;
                }
            }
        }

        // 右侧视频
        .video_right {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 600px;
            height: 600px;

            .r_top {
                width: 540px;
                position: relative;
                margin-top: -22px;
                margin-left: -90px;

                .r_top_img {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 15px;
                }

                .video_paly {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .play {
                        font-size: 50px;
                        color: rgba(255, 255, 255);
                        cursor: pointer;
                    }
                }
            }

            .r_bottom {
                // flex布局 使其底下两视频并列排
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                padding-top: 23px;
                margin-left: -20px;
                height: 650px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 10px;
                }

                // 右侧底部左一视频
                .b_left {
                    width: 44%;
                    position: relative;
                    margin-right: 5px;
                    margin-left: -73px;

                    .video_paly {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .play {
                            font-size: 50px;
                            color: rgba(255, 255, 255);
                            cursor: pointer;
                        }
                    }

                    img {
                        width: 100%;
                        border-radius: 10px;
                    }
                }

                // 右侧底部左二视频
                .b_right {
                    width: 44%;
                    position: relative;
                    margin-left: 20px;

                    img {
                        width: 100%;
                    }

                    .video_paly {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .play {
                            font-size: 50px;
                            color: rgba(255, 255, 255);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    // 弹窗
    //   .videoDialog {
    //     width: 500px;
    //     height: 400px;
    //   }
}
</style>
