
<template>
  <div
    v-if="datas.length != 0"
    class="nav-menu"
    :style="{ height: tabHeight + 'px' }"
    @mouseover="(toysCategoryIsShow = true)"
    @mouseleave="mouseleave"
  >
    <h3>
      <i class="el-icon-s-unfold"></i>
      {{ $t('categories') }}
    </h3>
    <div class="nav" v-show="toysCategoryIsShow">
      <div ref="divDom" @mouseleave="leaveBox">
        <ul class="left" ref="toysCategoryRef">
          <li>
            <div class="nav-tab" v-for="(item, index) in datas" :key="index">
              <div class="tab-left" @click="navBtn(item)" @mouseenter="enterBox(item)">
                <span class="icon"></span>
                <span>{{ language === "en" ? item.cl_Ne_Na : language === "ru" ? item.threeLanguage : item.cl_Na}}</span>
              </div>
              <div v-if="item.children && item.children.length > 0" class="tab-right el-icon-arrow-right"></div>
            </div>
          </li>
        </ul>
        <ul class="right" ref="rightBoxDom">
          <li>
            <div class="nav-tab" v-for="(item, index) in childrenList" :key="index">
              <div class="tab-left" @click="navBtn(item)">
                <span class="icon"></span>
                <span>{{ language === "en" ? item.cl_Ne_Na : language === "ru" ? item.threeLanguage : item.cl_Na}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else class="nav-menu" :style="{ height: tabHeight + 'px' }">
    <h3>
      <i class="el-icon-s-unfold"></i>
      {{ $t('categories') }}
    </h3>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'categoryMenu',
  data() {
    return {
      childrenList: [],
      flag: false,
      toysCategoryIsShow: false
    }
  },
  props: {
    datas: {
      type: Array,
      default: () => []
    },
    tabHeight: {
      type: Number,
      default: () => 60
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  },
  methods: {
    // 鼠标移出
    mouseleave() {
      this.toysCategoryIsShow = false
      this.$refs.toysCategoryRef.scrollTop = 0
    },
    // 点击跳转
    navBtn(value) {
      this.linkUrl(value.cl_Nu)
    },
    //控制hover进入显示
    enterBox(value) {
      console.log(value)
      const rightBoxDom = this.$refs.rightBoxDom
      if (value.children && value.children.length > 0) {
        rightBoxDom.style.display = 'block'
        this.childrenList = value.children
      } else {
        rightBoxDom.style.display = 'none'
        this.childrenList = []
      }
    },
    //控制hover进入隐藏
    leaveBox() {
      const rightBoxDom = this.$refs.rightBoxDom
      rightBoxDom.style.display = 'none'
    },
    linkUrl(cl_Nu) {
      if (this.$route.path !== '/product') {
        this.$router.push({ path: '/product', query: { clNu: cl_Nu } })
      }
      this.$bus.$emit('categoryClick', cl_Nu)
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-menu {
  width: 220px;
  position: relative;
  margin-right: 10px;

  h3 {
    height: 60px;
    font-size: 16px;
    line-height: 60px;
    box-sizing: border-box;
    color: #fff;
    padding: 0 30px;

    .el-icon-s-unfold {
      margin-right: 6px;
    }
  }

  .nav {
    & > div {
      position: relative;
      display: flex;
      width: 600px;
      z-index: 999;

      ul {
        list-style: none;
        height: 542px;
        border-right: 1px solid #fe6a00;
        border-left: 1px solid #fe6a00;
        border-bottom: 1px solid #fe6a00;
      }

      .left,
      .right {
        width: 220px;
        background-color: #fff;
        overflow: auto;
        box-sizing: border-box;

        /*滚动条整体宽度*/
        &::-webkit-scrollbar {
          width: 0px;
          /*宽对应滚动条的尺寸*/
        }

        /*滑块*/
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #fe6a00;
        }

        li {
          width: 100%;
          cursor: pointer;

          .nav-tab {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
            line-height: 48px;
            overflow: hidden;
            padding: 0 10px 0 20px;
          }

          .tab-left {
            width: 100%;
            height: 48px;
            line-height: 48px;
            display: flex;
            align-items: center;
            overflow: hidden;
            & span {
              display: block;
              height: 48px;
              line-height: 48px;
            }
            .icon {
              display: inline-block;
              border: 1px solid #333;
              background-color: #333;
              width: 1px;
              height: 1px;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }
      }

      .right {
        display: none;
        width: 220px;
      }
    }

    & > div:hover {
      ul {
        border-right: 1px solid #fca81e;
      }
    }

    .nav-tab:hover {
      color: #fff;
      background-color: #fca81e;
    }

    & .nav-tab:hover .icon {
      background-color: #fca81e !important;
      border: 1px solid #fff !important;
    }
  }
}
</style>
