<template>
	<!-- 公司名片 -->

	<div class="container product_box" v-if="dataContent !== ''">
		<div class="product_info">
			<!-- 标题 -->
			<div class="info_title">
				<h3>{{ $t('aboutus') }}</h3>
			</div>
			<div class="info_content" v-html="dataContent"></div>
			<!--左侧精灵图 -->
			<div class="info_img_l">
				<img src="@/assets/cloudLet2.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'businessCard',
	props: {
		datas: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		...mapState('operate', ['language']),
		dataContent() {
			const content = this.$t('language') === 'English' ? this.datas.enContent || '' : this.$t('language') === 'русский язык' ? this.datas.enContent || '' : this.datas.content || ''
			console.log(content)
			return content
		},
	},
}
</script>

<style lang="scss" scoped>
.product_box {
	.product_info {
		position: relative;
		width: 100%;
		background: rgba(20, 7, 112, 0.2);
		box-shadow: 0 0 100px #4558ec inset;
		border: 1px solid rgba(20, 7, 112, 0);
		border-radius: 20px;
		box-sizing: border-box;
		margin-top: 50px;

		// 标题
		.info_title {
			width: 600px;
			height: 42px;
			font-size: 52px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #38caf3;
			text-shadow: 0px 5px 5px rgba(29, 147, 131, 0.75);
			margin: 0 auto;
			padding-top: 50px;
			padding-bottom: 50px;
			text-align: center;
		}

		// 内容
		.info_content {
			margin: 20px 100px 100px 100px;

			& img {
				max-width: 100%;
			}
		}

		// 左侧精灵图
		.info_img_l {
			position: absolute;
			left: -353px;
			bottom: 215px;
			width: 270px;
			height: 185px;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}
</style>
