<!--
 * @Description: 
 * @Author: pcc
 * @Date: 2022-12-29 17:18:10
 * @LastEditors: zml
 * @LastEditTime: 2023-03-28 09:08:32
-->
<template>
  <div id="app">
    <pc-layout-one v-if="cutTemplates === 'themeTemplateOne'"></pc-layout-one>
    <pc-layout-tow v-else-if="cutTemplates === 'themeTemplateTow'"></pc-layout-tow>
    <pc-layout-three v-else-if="cutTemplates === 'themeTemplateThree'"></pc-layout-three>
  </div>
</template>

<script>
import { ipView } from '@/api/statistics'
import { getCartListApi } from '@/api/cart'
import { SiteSettingWebGetInfo } from '@/api/home'
import pcLayoutOne from '@/components/themeTemplateOne/index.vue'
import pcLayoutTow from '@/components/themeTemplateTow/index.vue'
import pcLayoutThree from '@/components/themeTemplateThree/index.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { setDocumentTitle } from '@/utils/domUtil'
import mixins from '@/utils/mixins'
export default {
  name: 'App',
  mixins: [mixins],
  components: {
    pcLayoutOne,
    pcLayoutTow,
    pcLayoutThree
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('user', {
      token: state => state.token
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  },
  created() {
    console.log('9999999999 :>> ', 9999999999);
    ipView({ source: 2 }).then(res => {
      console.log(res)
    })
    if (this.userInfo && this.token !== '') {
      getCartListApi({
        userId: this.userInfo.id
      }).then(res => {
        if (res.success) {
          this.setCart(res.data)
        }
      })
    }

    SiteSettingWebGetInfo()
      .then(res => {
        if (res.success) {
          this.setSetting(res.data)
          if (res.data === 'en') {
            setDocumentTitle(res.data.enTitle)
          } else if (res.data === 'ru') {
            setDocumentTitle(res.data.rusTitle)
          } else {
            setDocumentTitle(res.data.title)
          }
        } else {
          this.$message(res.message)
        }
      })
      .catch(err => {
        this.$message({ err })
      })
  },
  methods: {
    ...mapActions('operate', {
      setCart: 'set_cart'
    }),
    ...mapActions('setting', {
      setSetting: 'set_setting'
    })
  }
}
</script>

<style lang="scss">
body,
dl,
dt,
dd,
h3,
p {
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;

  & ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
</style>
