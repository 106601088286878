<!--
 * @Description: 
 * @Author: pcc
 * @Date: 2022-11-02 17:20:32
 * @LastEditors: zml
 * @LastEditTime: 2023-03-23 17:00:05
-->
<template>
  <div>
    <div style="background: #f5f5f5;height:30px"></div>
    <div class="container">
      <div class="order-container">
        <div class="header-box">
          <div class="left">{{ $t('order') }}</div>
          <div class="right">
            <div class="input-box">
              <el-input v-model="queryParam.keywords" :placeholder="$t('DK.PleaseOrderNum')">
                <el-button slot="append" @click="onSearch">{{$t('search')}}</el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="order-list" v-if="orderList.length>0">
          <order-box v-for="(item, index) in orderList" :orderInfo="item" :key="index"></order-box>
          <div class="pagination">
            <el-pagination
              :current-page="queryParam.pageNo"
              :page-size="queryParam.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              :total="totalRows"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
        <el-empty :description="$t('noData')" v-else></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
import { getOrderList } from '@/api/order'
import { mapGetters } from 'vuex'
import orderBox from './orderBox.vue'
export default {
  name: 'pageOrder',
  components: {
    orderBox
  },
  data() {
    return {
      orderList: [],
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        sortField: '',
        sortOrder: '',
        keywords: '',
        status: -1
      },
      totalRows: 0
    }
  },
  computed: {
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  },
  created() {
    this.initData()
  },
  methods: {
    // 初始化
    initData() {
      this.queryParam.userAccount = this.userInfo.userAccount
      getOrderList(this.queryParam).then(res => {
        this.orderList = res.data.rows
        this.totalRows = res.data.totalRows
      })
    },
    onSearch() {
      this.initData()
    },
    // 选择页码大小
    handleSizeChange(pageSize) {
      this.queryParam.pageSize = pageSize
      this.initData()
    },
    // 分页
    handleCurrentChange(pageNo) {
      this.queryParam.pageNo = pageNo
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 10px;
  .left {
    font-size: 24px;
    font-weight: bold;
  }
  .right {
    .input-box {
      width: 358px;
      .el-input-group {
        border-radius: 30px !important;
        overflow: hidden;
        border: 1px solid #0d56bc;
        height: 38px;
        ::v-deep .el-input__inner {
          border: 0 !important;
          font-size: 16px;
        }

        ::v-deep .el-input-group__append {
          border: none;
          width: 90px;
          height: 38px;
          background: #0d56bc;
          text-align: center;
          font-size: 16px;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
.order-container {
  min-height: 400px;

  & .order-list {
    width: 100%;

    & .pagination {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin-top: 15px;
    }
  }
}
</style>