<!--
 * @Description: 新闻（展会计划）详情
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-03-23 14:38:39
-->
<template>
  <div>
    <new-detail-three v-if="cutTemplates === 'themeTemplateThree'"></new-detail-three>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'newDetail',
  mixins: [mixins]
}
</script>
<style lang="scss" scoped>
</style>