<template>
    <div class="order-detail">
        <page-order-detail-one v-if="cutTemplates === 'themeTemplateOne'"></page-order-detail-one>
        <page-order-detail-tow v-if="cutTemplates === 'themeTemplateTow'"></page-order-detail-tow>
        <page-order-detail-three v-if="cutTemplates === 'themeTemplateThree'"></page-order-detail-three>
    </div>
</template>
<script>
// import pageOrderDetail from '@/components/page/order/detail'
import mixins from '@/utils/mixins';
export default {
    name: 'orderDetailPage',
    mixins: [mixins],
    components: {
        // pageOrderDetail
    }
}
</script>
<style lang="scss" scoped>
.order-detail {
    width: 100%;
    padding: 10px 0;
}
</style>