<template>
  <div class="container product-box">
    <div class="tit-box">
      <div class="tit">{{$t('newproducts')}}</div>
      <el-menu :default-active="active" mode="horizontal">
        <el-menu-item v-for="(item, index) in datas" :index="item.id.toString()" :key="index" @click="tabChange(item)">
          <span class="date-tit">{{item.date}}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="product-list">
      <div class="product-item" v-for="(item, index) in activeProduct" :key="index">
        <ul>
          <li v-for="(sitem, ik) in item.products" :key="ik">
            <div class="product-img" @click="$router.push({'path': '/proDetail', query:{ coNu: sitem.co_Nu}})">
              <img :src="sitem.imgUrl" :alt="sitem.pr_Na" />
            </div>
            <div class="product-txt">
              <h3
                @click="$router.push({'path': '/proDetail', query:{ coNu: sitem.co_Nu}})"
              >{{ language === "en" ? sitem.pr_En_Na : language === "ru" ? sitem.basicThreeLanguage : sitem.pr_Na }}</h3>
              <p class="number">{{sitem.co_Nu}}</p>
              <p class="price">{{ userInfo.symbol }}{{ userInfo.status ===1 ? sitem.price : $t('viewPrice') }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'newProduct',
  props: {
    datas: {
      type: Array,
      default: () => []
    },
    defaultActive: {
      type: String,
      default: () => '1'
    }
  },
  data() {
    return {
      active: '1'
    }
  },
  computed: {
    // 用户信息
    ...mapState('user', ['userInfo']),
    // 语言
    ...mapState('operate', ['language']),
    // 获取当前激活的产品列表
    activeProduct() {
      if (this.datas.length > 0) {
        return this.datas.filter(item => item.id.toString() === this.active)
      }
      return []
    }
  },
  created() {
    this.active = this.defaultActive
  },
  methods: {
    tabChange(item) {
      this.active = String(item.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.product-box {
  .tit-box {
    display: flex;
    align-items: center;
    height: 80px;
    line-height: 80px;

    .tit {
      font-size: 28px;
      font-weight: bold;
      padding-right: 40px;
    }

    .el-menu.el-menu--horizontal {
      border: none;
    }

    .el-menu--horizontal > .el-menu-item {
      color: black;
      font-size: 16px;
      height: 35px;
      line-height: 35px;
      padding: 0 20px;
    }

    .el-menu--horizontal > .el-menu-item.is-active {
      color: #fca81e !important;
      border-bottom: #fca81e 2px solid;
    }

    .date-tit {
      font-weight: bold;
    }
  }

  .product-list {
    width: 100%;

    .product-item {
      width: 100%;

      ul {
        width: 100%;
        display: flex;
        padding: 20px 0;

        li {
          width: 23.5%;
          margin-right: 2%;
          box-shadow: 0 2px 2px 2px #f5f5f5;
          padding: 15px 0;

          .product-img {
            cursor: pointer;
            width: 90%;
            margin: 0 5% 5% 5%;
            overflow: hidden;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 200px;
              transition-duration: 0.5s;
              &:hover {
                transition-duration: 0.5s;
                transform: scale(1.1);
              }
            }
          }

          .product-txt {
            padding: 0 5%;

            h3 {
              font-size: 16px;
              font-weight: normal;
              height: 60px;
              line-height: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .number {
              font-size: 14px;
              height: 30px;
              line-height: 30px;
            }

            .price {
              font-size: 14px;
              color: #f24141;
              font-weight: bold;
              height: 25px;
              line-height: 25px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>