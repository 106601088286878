/*
 * @Description: 方法
 * @Author: zml
 * @Date: 2023-03-22 14:15:45
 * @LastEditors: zml
 * @LastEditTime: 2023-03-27 20:01:36
 */
import store from "../store/index";
import { setDocumentTitle } from '@/utils/domUtil'

/**
 * 传值需严格遵行下面的顺序
 * @param {Object} zhValue 中文
 * @param {Object} enValue 英文
 * @param {Object} threelanguages 第三语言
 */
export function filterLanguage(zhValue = '', enValue = '', threelanguages = '') {
  const i18n = store.state.operate.language
  let language = enValue
  switch (i18n) {
    case 'zh': // 中文
      language = zhValue
      setDocumentTitle(store.state.setting.webSetting.title)
      break;
    case 'en': // 英文
      language = enValue
      setDocumentTitle(store.state.setting.webSetting.enTitle)
      break;
    case 'ru': // 中文
      language = threelanguages
      setDocumentTitle(store.state.setting.webSetting.rusTitle)
      break;
  }
  return language
}

