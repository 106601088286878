<template>
  <!-- 商品详情 -->
  <div class="big-box">
    <div class="container">
      <div class="pro-box">
        <div class="title">{{$t('DK.productDetails')}}</div>
        <div class="detail-box">
          <div class="img-info">
            <div class="goods-img">
              <pic-zoom :bigSrc="activeImg || defaultImg" v-if="defaultImg"></pic-zoom>
            </div>
            <div class="swiper">
              <swiper :options="swiperOption" ref="mySwiper" class="swiper-box">
                <swiper-slide class="swiper-slide" v-for="(item, index) in proInfo.imgList" :key="index">
                  <div class="item-img">
                    <img :src="item.filePath" @click.stop="hoverImg(item)" @mouseenter="setImg(item)" class="swiper-img" />
                  </div>
                </swiper-slide>
              </swiper>
              <!-- 左右箭头 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
          <div class="pro-info">
            <div class="right-title">{{ $hs(proInfo.pr_Na,proInfo.pr_En_Na,proInfo.basicThreeLanguage) }}</div>
            <div>{{$t('DK.productPrice')}}： <span style="color: red;">{{ userInfo.symbol }}{{ userInfo.status === 1 ? Number(proInfo.price) : $t('viewPrice')}}</span></div>
            <div>{{$t('DK.ProductNumber')}}：{{ proInfo.co_Nu }}</div>
            <div>{{ $t('packing') }}：{{ $t('language') === 'English' ? proInfo.en_Pa || '' : proInfo.ch_Pa || '' }}</div>
            <div>{{$t('DK.OuterBoxCBM')}}：{{ proInfo.bulk_Stere || 0.00 }} (cbm)</div>
            <div>{{$t('DK.OuterBoxCUFT')}}：{{ proInfo.bulk_Feet || 0.00 }} (cuft)</div>
            <div>{{$t('DK.Innerboxes')}}：{{ proInfo.in_En || 0 }}</div>
            <div>{{$t('DK.OuterCapacity')}}：{{ proInfo.ou_Lo || 0 }}(pcs)</div>
            <div>{{ $t('GWAndNW') }}： {{ proInfo.gr_We || 0 }} / {{ proInfo.ne_We || 0 }}(kg)</div>
            <div>{{ $t('productSize') }}： {{ proInfo.pr_Le || 0 }} x {{ proInfo.pr_Wi || 0 }} x {{ proInfo.pr_Hi || 0 }}(cm)</div>
            <div>{{ $t('packageSize') }}：{{ proInfo.in_Le || 0 }} x {{ proInfo.in_Wi || 0 }} x {{ proInfo.in_Hi || 0 }}(cm)</div>
            <div>{{ $t('cartonSize') }}：{{ proInfo.ou_Le || 0 }} x {{ proInfo.ou_Wi || 0 }} x {{ proInfo.ou_Hi || 0 }}(cm)</div>
            <div class="cart-box">
              <el-badge v-if="userInfo.id" :value="0" class="item" hidden>
                <el-button
                  @click="click(proInfo)"
                  type="primary"
                  icon="el-icon-shopping-cart-2"
                >{{ ifAdd(proInfo.co_Nu) ? $t('delCart') : $t('addCart') }}</el-button>
              </el-badge>
              <el-badge v-else :value="0" class="item" hidden>
                <el-button @click="onEnquiry(proInfo)" type="primary">
                  {{ $t('DK.SendInquiry') }}
                  <span v-if="proInfo._num_">(1)</span>
                </el-button>
              </el-badge>
            </div>
          </div>
        </div>
      </div>
      <div class="product-list">
        <div class="title">{{$t('DK.similarProduct')}}</div>
        <pro-list :productList="productList" v-if="productList.length > 0" :isShowCheck="false"></pro-list>
      </div>
    </div>
  </div>
</template>
<script>
import picZoom from '@/components/themeTemplateThree/pc_components/productBox/picZoom.vue'
import proList from '@/components/themeTemplateThree/pc_components/productBox/proList.vue'
import { getSameProductList } from '@/api/product'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapActions, mapGetters, mapState } from 'vuex'
import { deletCartsApi, addCartsApi } from '@/api/cart'
export default {
  name: 'proDetail',
  components: {
    picZoom,
    swiper,
    swiperSlide,
    proList
  },
  props: {
    proInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      productList: [],
      queryParam: {
        pageNo: 1,
        pageSize: 20,
        sortField: '',
        sortOrder: '',
        cl_Nu: '',
        isAnd: false,
        conditionals: []
      },
      activeImg: '',
      activeIndex: 0,
      swiperOption: {
        slidesPerView: 4,
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        observer: false, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, // 修改swiper的父元素时，自动初始化swiper
        imgArr: [],
        preventLinksPropagation: false // 阻止点击事件冒泡
      }
    }
  },
  computed: {
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    ...mapGetters('operate', {
      gettingCart: 'getting_cart',
      getEnquiry: 'get_enquiry'
    }),
    // 语言
    ...mapState('operate', ['language']),

    defaultImg() {
      return this.proInfo.imgUrl
    },
    // 已加购还是取消加购
    ifAdd() {
      return coNu => {
        return this.gettingCart.findIndex(item => item.co_Nu === coNu) > -1
      }
    }
  },
  created() {},
  watch: {
    proInfo() {
      if (this.proInfo.ma_Nu) {
        this.getSameProduct()
      }
    }
  },
  mounted() {
    sessionStorage.setItem('detail', true)
  },

  methods: {
    ...mapActions('operate', {
      addCart: 'add_cart',
      deleteCart: 'delete_cart',
      setEnquiry: 'set_enquiry'
    }),

    getSameProduct() {
      let obj = {
        num: 10,
        client_Nu: this.userInfo.client_Nu || '',
        ma_Nu: this.proInfo.ma_Nu
      }
      getSameProductList(obj).then(res => {
        this.productList = res.data || []
        this.productList = this.productList.map(item => {
          const ind = this.getEnquiry[0].list.findIndex(
            it => it.co_Nu === item.co_Nu
          )
          if (ind > -1) {
            return { ...item, _num_: true }
          }
          return { ...item, _num_: false }
        })
      })
    },

    onEnquiry(proInfo) {
      proInfo._num_ = !proInfo._num_
      this.setEnquiry(proInfo)
    },
    click(proInfo) {
      if (!this.ifAdd(proInfo.co_Nu)) {
        // 添加商品
        if (this.userInfo) {
          addCartsApi({
            client_Nu: this.userInfo.client_Nu,
            userId: this.userInfo.id,
            co_Nu: proInfo.co_Nu,
            number: 1
          }).then(res => {
            if (res.success) {
              this.addCart({
                client_Nu: this.userInfo.client_Nu,
                userId: this.userInfo.id,
                co_Nu: proInfo.co_Nu,
                fa_No: proInfo.fa_No,
                imgUrl: proInfo.imgUrl,
                in_En: proInfo.in_En,
                number: 1,
                ou_Lo: proInfo.ou_Lo,
                pr_En_Na: proInfo.pr_En_Na,
                pr_Na: proInfo.pr_Na,
                price: proInfo.price,
                bulk_Stere: proInfo.bulk_Stere
              })
            }
          })
        }
      } else {
        // 删除商品
        if (this.userInfo) {
          deletCartsApi({
            userId: this.userInfo.id,
            coNus: [proInfo.co_Nu]
          }).then(res => {
            if (res.success) {
              this.deleteCart(proInfo.co_Nu)
            }
          })
        }
      }
    },
    // 图片经过时，设置大图
    hoverImg(data) {
      this.activeImg = data.filePath
    },
    // 图片点击时，设置大图
    setImg(data) {
      this.activeImg = data.filePath
    }
  }
}
</script>
<style lang="scss" scoped>
.big-box {
  padding: 10px 0;
  background: #f1f3f6;
  .pro-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;

    .title {
      font-size: 24px;
      font-weight: 700;
      color: #222222;
      margin: 0 0 20px 0;
    }
    .detail-box {
      display: flex;
    }
    & .img-info {
      width: 536px;
      & .goods-img {
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #dcdfe6;
      }

      .swiper {
        position: relative;
        box-sizing: border-box;
        margin-top: 10px;
        width: 100%;
        height: 65px;

        & .swiper-container {
          width: 86%;
          padding: 0 7%;
          height: 67px;
          position: relative;

          & .swiper-wrapper {
            height: 67px;
            display: flex;
            align-items: center;
          }

          & .swiper-slide {
            width: 20% !important;

            & .item-img {
              margin: 0 5px;
              height: 61px;
              border: solid 1px #dcdfe6;
              padding: 2px;
              display: flex;
              justify-content: center;

              & img {
                width: auto;
                max-width: 100%;
                max-height: 65px;
              }
            }
          }
        }
      }
    }

    & .pro-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fff;
      padding-left: 40px;
      color: #333;
      .right-title {
        font-size: 20px;
        height: 26px;
        font-weight: 700;
        color: #222222;
      }
    }
  }
}

.product-list {
  width: 100%;

  .title {
    margin: 20px 0 10px 0;
    font-size: 24px;
    font-weight: 700;
    color: #666666;
  }
}
</style>