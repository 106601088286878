<template>
  <!-- 底部布局 -->

  <div class="footer_box">
    <div class="container footer-container">
      <!-- 底部-二维码 左侧 -->
      <div class="footer_item_l">
        <div class="item_l_img">
          <img v-if="getSetting.weChat" :src="getSetting.weChat" alt />
          <img v-else src="@/assets/or_Code.png" alt />
        </div>

        <div class="item_l_p">
          <p>{{ $t('hotLine') }}</p>
          <p>{{ getSetting.mobile }}</p>
        </div>
      </div>

      <!-- 底部-联系我们 中间 -->
      <div class="footer_item_mid">
        <div>
          <dl style>
            <dt>
              <span @click="$router.push({ path: '/about' })">{{ $t('aboutus') }}</span>
            </dt>
            <dd>
              <span @click="$router.push({ path: '/about' })" class="link">{{ $t('companyInfo') }}</span>
            </dd>
            <dd>
              <span @click="$router.push({ path: '/product' })" class="link">{{ $t('productCenter') }}</span>
            </dd>
          </dl>
        </div>
      </div>

      <!-- 底部-联系方式 右侧 -->
      <div class="footer_item_r">
        <dl>
          <dt>
            <span @click="$router.push({ path: '/contact' })">{{ $t('contactUs') }}</span>
          </dt>
          <dd>
            <span class="link">{{ language == 'en' ? getSetting.enAddress : language == 'ru' ? getSetting.rusAddress : getSetting.address}}</span>
          </dd>
        </dl>
      </div>
    </div>

    <!-- 虚线 -->
    <div class="footer_line"></div>

    <!-- 底部 end -->
    <div class="container footer_end">
      <div class="end_info">
        <div class="info_txt">{{ language == 'en' ? getSetting.enCopyRight : language == 'ru' ? getSetting.rusCopyRight : getSetting.copyRight }}</div>

        <!-- 后续再说 -->
        <div class="info_seal">
          <div class="go-top" @click="gotoTop">TOP</div>
          <div class="go-top-txt">Return to the home page</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'layoutFooterTwo',
  computed: {
    ...mapState('operate', ['language']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  },
  methods: {
    gotoTop() {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.footer_box {
  width: 100%;
  color: #f7f7f7;
  background-color: #140770;

  // 二维码、联系我们、联系方式
  .footer-container {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;

    // 底部-二维码
    .footer_item_l {
      display: flex;
      align-items: center;
      width: 35%;
      border-right: 1px solid #f7f7f7;

      .item_l_img {
        width: 80px;
        height: 80px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .item_l_p {
        display: flex;
        flex-direction: column;

        padding-left: 10px;
        padding-top: 38px;

        p {
          padding-bottom: 5px;
        }
      }
    }

    // 底部-联系我们
    .footer_item_mid {
      width: 30%;
      border-right: 1px solid #f7f7f7;
      padding-left: 50px;
      dl {
        display: flex;
        flex-direction: column;
        justify-content: center;

        dt {
          width: 100%;
          font-size: 16px;
          line-height: 40px;
          font-weight: bold;

          span {
            cursor: pointer;
          }
        }

        dd {
          width: 100%;
          font-size: 14px;
          line-height: 40px;

          span {
            &.link {
              cursor: pointer;
            }
          }
        }
      }
    }

    // 底部-联系方式
    .footer_item_r {
      text-align: center;
      width: 50%;

      dl {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 50px;

        dt {
          width: 100%;
          font-size: 16px;
          line-height: 40px;
          font-weight: bold;

          span {
            cursor: pointer;
          }
        }

        dd {
          width: 100%;
          font-size: 14px;
          line-height: 40px;

          &.link {
            cursor: pointer;
          }
        }
      }
    }
  }

  // 虚线
  .footer_line {
    background-color: rgba($color: #fff, $alpha: 0.3);
    height: 1px;
    width: 100%;
  }
  & .footer_end {
    height: 60px;
    & .end_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      & .info_txt {
        font-size: 14px;
        flex: 1;
      }

      & .info_seal {
        height: 60px;
        width: 200px;
        display: flex;
        align-items: center;
        & .go-top {
          background-color: #005eff;
          color: #fff;
          font-size: 18px;
          height: 60px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          cursor: pointer;
        }
        & .go-top-txt {
          width: 100px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          background-color: #f55196;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
