<template>
  <div class="container product-box">
    <div class="group-item" v-for="(item, index) in datas" :key="index">
      <div class="tit-box">
        <div class="tit">{{ language === "en" ? item.enTitle : language === "ru" ? item.rusTitle : item.title }}</div>
        <div class="group-img">
          <img :src="item.imgUrl" :alt="language === 'en' ? item.enTitle : language === 'ru' ? item.rusTitle : item.title" />
        </div>
      </div>
      <item-product :valueInfo="item.valueInfo"></item-product>
    </div>
  </div>
</template>
<script>
import itemProduct from './itemProduct.vue'
import { mapState } from 'vuex'
export default {
  name: 'groupProduct',
  components: {
    itemProduct
  },
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('operate', ['language'])
  }
}
</script>
<style lang="scss" scoped>
.product-box {
  .group-item {
    margin: 40px 0;

    .tit-box {
      width: 100%;

      .tit {
        font-size: 22px;
        font-weight: bold;
        margin: 35px 0px;
      }

      .group-img {
        width: 100%;

        img {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>