import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCropper from 'vue-cropper'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from '@/i18n/index'
import Bus from './utils/bus'
import { filterLanguage } from './utils/method'


Vue.config.productionTip = false

Vue.prototype.$bus = Bus
Vue.prototype.$hs = filterLanguage

Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})
Vue.use(VueCropper)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
