/*
 * @Description: 项目文件路径
 * @Author: pcc
 * @Date: 2022-12-29 17:18:10
 * @LastEditors: pcc
 * @LastEditTime: 2023-04-03 09:03:35
 */

/**
 * 主风格1
 * themeTemplateOne
 */
import contactDetailOne from "@/components/themeTemplateOne/page/detail/index" // about
import aboutDetailOne from "@/components/themeTemplateOne/page/detail/index" // about
import pageCartOne from "@/components/themeTemplateOne/page/cart/index" // cart
import pageHomeOne from "@/components/themeTemplateOne/page/home" // home
import pageOrderDetailOne from "@/components/themeTemplateOne/page/order/detail" // order
import pageOrderOne from "@/components/themeTemplateOne/page/order/index" // order
import pageProDetailOne from "@/components/themeTemplateOne/page/product/proDetail.vue" //product
import pageProductOne from "@/components/themeTemplateOne/page/product/index.vue" //product
import pageAddressOne from "@/components/themeTemplateOne/page/address/index"

/**
 * 蓝色风格
 * themeTemplateTow
 */
import contactDetailTow from "@/components/themeTemplateTow/page/detail/index" // about
import aboutDetailTow from "@/components/themeTemplateTow/page/detail/index" // about
import pageCartTow from "@/components/themeTemplateTow/page/cart/index" // cart
import pageHomeTow from "@/components/themeTemplateTow/page/home" // home
import pageOrderDetailTow from "@/components/themeTemplateTow/page/order/detail" // order
import pageOrderTow from "@/components/themeTemplateTow/page/order/index" // order
import pageProDetailTow from "@/components/themeTemplateTow/page/product/proDetail.vue" //product
import pageProductTow from "@/components/themeTemplateTow/page/product/index.vue" //product
import pageAddressTow from "@/components/themeTemplateTow/page/address/index"


/**
 * 主风格3 德科
 * themeTemplateThree
 */
import contactDetailThree from "@/components/themeTemplateThree/page/detail/contact" // contact
import aboutDetailThree from "@/components/themeTemplateThree/page/detail/index" // about
import pageCartThree from "@/components/themeTemplateThree/page/cart/index" // cart
import pageHomeThree from "@/components/themeTemplateThree/page/home" // home
import pageOrderDetailThree from "@/components/themeTemplateThree/page/order/detail" // order
import pageOrderThree from "@/components/themeTemplateThree/page/order/index" // order
import pageProDetailThree from "@/components/themeTemplateThree/page/product/proDetail.vue" //product
import pageProductThree from "@/components/themeTemplateThree/page/product/index.vue" //product
import pageAddressThree from "@/components/themeTemplateThree/page/address/index"
import newDetailThree from "@/components/themeTemplateThree/page/detail/newDetail" // 展会计划详情
export default {
  components: {
    // 主风格1
    contactDetailOne,
    aboutDetailOne,
    pageCartOne,
    pageHomeOne,
    pageOrderDetailOne,
    pageOrderOne,
    pageProDetailOne,
    pageProductOne,
    pageAddressOne,

    // 蓝色风格
    contactDetailTow,
    aboutDetailTow,
    pageCartTow,
    pageHomeTow,
    pageOrderDetailTow,
    pageOrderTow,
    pageProDetailTow,
    pageProductTow,
    pageAddressTow,

    /**
     * 主风格3 德科
     * themeTemplateThree
     */
    contactDetailThree,
    aboutDetailThree,
    pageCartThree,
    pageHomeThree,
    pageOrderDetailThree,
    pageOrderThree,
    pageProDetailThree,
    pageProductThree,
    pageAddressThree,
    newDetailThree
  },

  data() {
    return {
      cutTemplates: "themeTemplateOne",
      // cutTemplates: 'themeTemplateTow'
      //cutTemplates: 'themeTemplateThree'
    }
  }
}
