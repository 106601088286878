import request from '@/utils/request'

// 获取订单列表
export function getOrderList(data) {
	return request({
		url: 'front/order/queryPage',
		method: 'post',
		data,
	})
}

// 获取订单详情
export function getOrderDetail(data) {
	return request({
		url: 'front/order/getDetail',
		method: 'post',
		data,
	})
}

// 新增订单
export function addOrder(data) {
	return request({
		url: 'front/order/add',
		method: 'post',
		data,
	})
}

// 取消订单
export function closedOrder(data) {
	return request({
		url: 'front/order/closedOrder',
		method: 'post',
		data,
	})
}

// 删除订单
export function delOrder(data) {
	return request({
		url: 'front/order/BatchDelete',
		method: 'post',
		data,
	})
}
