<template>
  <div class="container product-box">
    <div class="tit-box">
      <div class="tit">
        <h3>{{ $t("highRec") }}</h3>
        <p class="sm-tit">{{ $t("slogan") }}</p>
        <el-button class="more">{{ $t("viewmore") }}</el-button>
      </div>
    </div>
    <div class="product-list" v-if="datas.length>0">
      <ul>
        <li v-for="(item, index) in datas" :key="index">
          <div class="product-img" @click="$router.push({'path': '/proDetail', query:{ coNu: item.co_Nu}})">
            <img :src="item.imgUrl" :alt="item.pr_Na" />
          </div>
          <div class="product-txt">
            <h3
              :title="language === 'en' ? item.pr_En_Na : item.pr_Na"
              @click="$router.push({'path': '/proDetail', query:{ coNu: item.co_Nu}})"
            >{{ language === "en" ? item.pr_En_Na : language === "ru" ? item.basicThreeLanguage : item.pr_Na }}</h3>
            <p class="number">{{item.co_Nu}}</p>
            <p class="price">{{ userInfo.symbol }}{{ userInfo.status ===1 ? item.price : $t('viewPrice') }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'recommProduct',
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    // 用户信息
    ...mapState('user', ['userInfo']),
    // 语言
    ...mapState('operate', ['language'])
  }
}
</script>
<style lang="scss" scoped>
.product-box {
  & .tit-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .tit {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & h3 {
        font-size: 48px;
        font-weight: bold;
        color: #222;
        line-height: 60px;
      }

      & .sm-tit {
        font-size: 20px;
        line-height: 30px;
        padding: 20px 0;
        color: #222;
      }

      & .more {
        width: 120px;
        background-color: #2672be;
        color: #f5f5f5;
        border-radius: 6px;
        font-weight: bold;
      }
    }

    & .tit-img {
      width: 250px;
      height: 250px;

      & img {
        width: 100%;
      }
    }
  }

  & .product-list {
    width: 100%;

    & ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;

      & li {
        background-color: #fff;
        width: 18.4%;
        padding: 15px 0;
        margin: 10px 2% 10px 0;
        border-radius: 5px;

        & .product-img {
          width: 90%;
          margin: 0 5% 5% 5%;
          overflow: hidden;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          & img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 150px;
            transition-duration: 0.5s;
            &:hover {
              transition-duration: 0.5s;
              transform: scale(1.1);
            }
          }
        }

        & .product-txt {
          padding: 0 5%;

          & h3 {
            font-size: 16px;
            font-weight: normal;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          & .number {
            font-size: 14px;
            height: 30px;
            line-height: 30px;
          }

          & .price {
            font-size: 14px;
            color: #f24141;
            font-weight: bold;
            height: 25px;
            line-height: 25px;
          }
        }

        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>