<template>
  <!-- 热门推荐 -->

  <div class="container product-box" v-if="datas.length > 0">
    <!-- 标题 -->
    <div class="product_title">
      <div class="title">
        <span class="title_word">
          {{
          $t('popularRecommendation')
          }}
        </span>
      </div>
    </div>

    <!-- 列表 -->
    <div class="product_list">
      <!-- 列表视图 -->
      <div class="list_item" v-if="datas.length > 0">
        <ul>
          <li v-for="(item, index) in datas" :key="index" class="item_li">
            <div class="item_img" @click="imgClick">
              <img :src="item.imgUrl" alt="baby image" @click=" $router.push({ path: '/proDetail', query: { coNu: item.co_Nu }, }) " />
            </div>

            <div class="item_txt">
              <p
                class="txt_words"
              >{{ language == 'en' ? item.en_Pa : language == 'ru' ? item.basicThreeLanguage : item.pr_Na }}</p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 列表右侧精灵图 -->
      <div class="imgs_right" v-if="datas.length > 0">
        <img class="list_img" style="width: 316px; height: 383px" src="@/assets/star2.png" alt="star2 image" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'hotRecommend',
  components: {},
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    // 语言
    ...mapState('operate', ['language'])
  },
  methods: {
    imgClick() {}
  }
}
</script>

<style lang="scss" scoped>
.product-box {
  // 标题
  .product_title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .title {
      margin-top: 72px;
      width: 620px;
      height: 90px;
      line-height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(20, 7, 112, 0.2);
      box-shadow: 0 0 36px #4558ec inset;
      // border: 1px solid #544fff;
      border-radius: 15px;
    }

    .title_word {
      color: #f5f5f5;
      font-size: 35px;
      position: absolute;
      margin: 0 auto;
      text-align: center;
      // padding-top: 40px;
    }

    .title_img {
      width: 100%;
    }
  }

  // 列表
  .product_list {
    // 列表视图
    position: relative;
    margin-top: 40px;

    .list_item {
      width: 100%;
      // padding: 2% 7%;
      border-radius: 40px;
      // margin-top: -30px;
      flex: 1;

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item_li {
          width: 450px;
          height: 500px;
          // width: 300px;
          margin-bottom: 25px;
          background: rgba(20, 7, 112, 0.2);
          box-shadow: 0 0 40px #4558ec inset;
          border-radius: 40px;
          padding: 20px 20px 0 20px;
          box-sizing: border-box;

          &:nth-child(3n) {
            margin-right: 0;
          }

          // 图片
          .item_img {
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgb(255, 255, 255);
            height: 410px;
            width: 410px;
            border-radius: 40px;
            box-sizing: border-box;
            overflow: hidden;
            cursor: pointer;

            img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 410px;
              transition-duration: 0.5s;
              border-radius: 10px;
              box-sizing: border-box;

              &:hover {
                transition-duration: 0.5s;
                transform: scale(1.1);
              }
            }
          }

          // 文字
          .item_txt {
            text-align: center;
            color: #38caf3;
            height: 40px;
            padding: 0 10px;
            margin-top: 6px;
            overflow: hidden;

            .txt_words {
              font-size: 18px;
              height: 21px;
              line-height: 50px;
            }
          }
        }
      }
    }

    // 列表视图右侧精灵图
    .imgs_right {
      width: 270px;
      height: 270px;
      position: absolute;
      top: 314px;
      right: -219px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
