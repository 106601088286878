<!--
 * @Description: 
 * @Author: pcc
 * @Date: 2022-11-02 17:20:32
 * @LastEditors: zml
 * @LastEditTime: 2023-03-22 17:30:43
-->
<template>
<div style="padding: 10px 0;">
    <div class="container">
        <el-card class="order-container">
            <div slot="header">
                <h3>{{ $t('order') }}</h3>
            </div>
            <div class="order-list" v-if="orderList.length>0">
                <order-box v-for="(item, index) in orderList" :orderInfo="item" :key="index"></order-box>
                <div class="pagination">
                    <el-pagination :current-page="queryParam.pageNo" :page-size="queryParam.size"
                        :page-sizes="[10, 20, 30, 40]" :total="totalRows"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                    </el-pagination>
                </div>
            </div>
            <el-empty :description="$t('noData')" v-else></el-empty>
        </el-card>
    </div>
</div>
</template>
<script>
import { getOrderList } from "@/api/order"
import { mapGetters } from 'vuex'
import orderBox from '@/components/public/order/orderBox'
export default {
    name: 'pageOrder',
    components: {
        orderBox
    },
    data() {
        return {
            orderList: [],
            queryParam: {
                pageNo: 1,
                pageSize: 10,
                sortField: '',
                sortOrder: '',
                keywords: '',
                status: -1
            },
            totalRows: 0
        }
    },
    computed: {
        ...mapGetters('user', {
            userInfo: 'get_userInfo'
        })
    },
    created() {
        this.initData()
    },
    methods: {
        // 初始化
        initData() {
            this.queryParam.userAccount = this.userInfo.userAccount
            getOrderList(this.queryParam).then(res => {
                this.orderList = res.data.rows
                this.totalRows - res.data.totalRows
            })
        },
        // 选择页码大小
        handleSizeChange(pageSize) {
            this.queryParam.pageSize = pageSize
            this.initData()
        },
        // 分页
        handleCurrentChange(pageNo) {
            this.queryParam.pageNo = pageNo
            this.initData()
        }
    }
}
</script>
<style lang="scss" scoped>
.order-container {
    min-height: 400px;

    & .order-list {
        width: 100%;

        & .pagination {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin-top: 15px;
        }
    }
}
</style>