<template>
  <div class="logo-box">
    <div class="container logo-container">
      <div class="left">
        <div class="logo" @click="$router.push({path: '/home'})">
          <img :src="getSetting.logo" />
        </div>
        <!-- <div class="company-name">{{language === "en" ? getSetting.enTitle : language === "ru" ? getSetting.rusTitle : getSetting.title}}</div> -->
      </div>
      <div class="right">
        <div class="input-box">
          <el-input v-model="keywords" :placeholder="$t('DK.logoBox.placeholder')">
            <el-button slot="append" @click="onSearch">{{$t('search')}}</el-button>
          </el-input>
        </div>
        <div class="select-box">
          <el-select v-model="language" @change="changeLanguage" v-if="getLanguageConfig.languageList">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              v-show="getLanguageConfig.languageList.includes(item.value)"
              :value="item.name"
            >
              <span style="font-size: 12px;">{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { setDocumentTitle } from '@/utils/domUtil'
export default {
  name: 'logoBox',
  data() {
    return {
      keywords: '',
      options: [
        { name: 'English', value: 'en' },
        { name: '中文', value: 'zh-Hans' },
        { name: 'русский язык', value: 'ru' }
      ],
      language: this.$t('language'),
      languageIcon: require('@/assets/English.png')
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('setting', {
      getSetting: 'get_setting',
      getLanguageConfig: 'get_language_config'
    }),
    ...mapGetters('operate', {
      gettingCart: 'getting_cart'
    }),
  },
  methods: {
    ...mapActions('operate', {
      set_language: 'set_language'
    }),
    ...mapActions('user', ['logout']),
    changeLanguage(e) {
      if (e === 'English') {
        this.$i18n.locale = 'en'
        this.set_language('en')
        this.languageIcon = this.options[0].src
        setDocumentTitle(this.getSetting.enTitle)
        // window.location.reload()
      } else if (e === '中文') {
        this.$i18n.locale = 'zh'
        this.set_language('zh')
        this.languageIcon = this.options[1].src
        setDocumentTitle(this.getSetting.title)
        // window.location.reload()
      } else if (e === 'русский язык') {
        this.$i18n.locale = 'ru'
        this.set_language('ru')
        this.languageIcon = this.options[2].src
        setDocumentTitle(this.getSetting.rusTitle)
        // window.location.reload()
      }
    },
    // 登录
    login() {
      // 打开登录模块
      this.$refs.loginModal.open()
    },
    // 注册
    register() {
      // 打开注册模块
      this.$refs.regModal.open()
    },
    // 跳转订单
    linkOrder() {
      if (Object.keys(this.userInfo).length > 0) {
        this.$router.push({ path: '/order' })
      } else {
        this.$refs.loginModal.open()
      }
    },
    onSearch() {
      if (this.$route.path !== '/product') {
        this.$router.push({
          path: '/product',
          query: { keywords: this.keywords }
        })
      }
      this.$bus.$emit('searchProduct', this.keywords)
    },
    // 跳转地址
    linkAddress() {
      this.$router.push({ path: '/address' })
    }
  }
}
</script>
<style lang="scss" scoped>
.logo-box {
  width: 100%;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;

    .left {
      display: flex;
      .logo {
        padding: 20px 0;
        height: 50px;
        cursor: pointer;

        img {
          width: auto;
          height: 50px;
        }
      }

      .company-name {
        padding-left: 10px;
        flex: 1;
        font-size: 28px;
        line-height: 120px;
        height: 120px;
        overflow: hidden;
        font-weight: bold;
        color: #ca3833;
      }
    }

    .right {
      display: flex;
    }
    .input-box {
      width: 440px;
      margin: 0 40px 0 0;
      .el-input-group {
        border-radius: 30px !important;
        overflow: hidden;
        border: 1px solid #0d56bc;
        height: 38px;
        ::v-deep .el-input__inner {
          border: 0 !important;
          font-size: 16px;
        }

        ::v-deep .el-input-group__append {
          border: none;
          width: 90px;
          height: 38px;
          background: #0d56bc;
          text-align: center;
          font-size: 16px;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .select-box {
      min-width: 100px !important;
      max-width: 100px;
      ::v-deep .el-input__inner {
        border-radius: 20px !important;
        border-color: #0d56bc;
        font-size: 16px;
      }
    }
  }
}
</style>