<template>
  <el-dialog
    :title="$t('registration')"
    :destroy-on-close="true"
    @close="close"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px"
    center
    append-to-body
  >
    <el-form :model="form" ref="form"  label-width="170px" :rules="rules">
      <el-form-item prop="userAccount" :label="$t('useraccount') + ':'">
        <el-input v-model="form.userAccount"></el-input>
      </el-form-item>
      <el-form-item prop="userPassword" :label="$t('password') + ':'">
        <el-input type="password" v-model="form.userPassword"></el-input>
      </el-form-item>
      <el-form-item prop="userName" :label="$t('username') + ':'">
        <el-input v-model="form.userName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('gender') + ':'">
        <el-radio v-model="form.gender" label="1">{{$t('man')}}</el-radio>
        <el-radio v-model="form.gender" label="2">{{$t('woman')}}</el-radio>
      </el-form-item>
      <el-form-item prop="mobile" :label="$t('mobile') + ':'">
        <el-input v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item prop="email" :label="$t('email') + ':'">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item :label="$t('address') + ':'">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item prop="valideCode" :label="$t('vaildecode') + ':'" class="valide-code">
        <el-input v-model="form.valideCode"></el-input>
        <img v-if="JSON.stringify(codeData) !== '{}'" @click="getCode" :src="codeData.valideCode" title="看不清楚？换一张" />
      </el-form-item>
    </el-form>
    <div class="Login" @click="goToLogin">{{ $t("toLogin") }}</div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit" class="btn">{{ $t('registration') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { GetValideCode, RegisterMember } from '@/api/login'
export default {
  name: 'regModal',
  data() {
    // 检验邮件
    let regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
    let checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('inputEmail')))
      }
      if (regEmail.test(value)) {
        // 合法邮箱
        callback()
      } else {
        // 错误邮箱
        callback(new Error(this.$t('inputEmailRules')))
      }
    }
    return {
      visible: false,
      codeData: '',
      form: {
        userAccount: '',
        userPassword: '',
        mobile: '',
        valideCode: '',
        email: '',
        address: '',
        userName: '',
        gender: '1'
      },
      rules: {
        userName: [
          { required: true, message: this.$t('inputUserName'), trigger: 'blur' }
        ],
        email: [{ trigger: 'blur', validator: checkEmail }],
        mobile: [
          { required: true, message: this.$t('inputMobile'), trigger: 'blur' }
        ],
        userAccount: [
          {
            required: true,
            message: this.$t('inputUserAccount'),
            trigger: 'blur'
          }
        ],
        userPassword: [
          {
            required: true,
            message: this.$t('inputPassword'),
            trigger: 'blur'
          },
          { min: 6, message: this.$t('passwordLength'), trigger: 'blur' }
        ],
        valideCode: [
          {
            required: true,
            message: this.$t('inputVaildeCode'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  },
  methods: {
    open() {
      this.visible = true
      this.getCode()
    },
    close() {
      this.visible = false
      this.$refs.form.resetFields()
    },
    submit() {
      let _this = this
      _this.$refs.form.validate(values => {
        if (!values) return false

        // 注册
        RegisterMember({ ..._this.form, keyCode: _this.codeData.keyCode })
          .then(() => {
            _this.$message.success(_this.$t('regSuccess'))
            _this.close()
          })
          .catch(err => {
            if (err.includes('D1632') || err.includes('D1631')) {
              _this.$message.error(_this.$t('CodeError'))
            } else if (err.includes('D1630')) {
              this.$message.error(_this.$t('resUserError'))
            }
            _this.getCode()
          })
      })
    },
    // 去登录
    goToLogin() {
      this.close()
      this.$emit('login')
    },
    // 获取验证码
    getCode() {
      GetValideCode()
        .then(res => {
          this.codeData = res.data
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.Login {
  padding: 20px 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #ff760e;
  }
}

:deep(.el-dialog__header) {
  text-align: left;
  padding: 20px;
}

:deep .el-form {
  padding: 0 40px 0 0;
  .valide-code {
    .el-input {
      margin: 0 10px 0 0;
    }
    .el-form-item__content {
      display: flex;
    }
  }
}

button {
  width: 300px;
  background-color: #ff760e;
  border-color: #ff760e;

  &:hover {
    background-color: #f87c1d;
    border-color: #f87c1d;
  }
}

.dialog-footer {
  .btn {
    margin-bottom: 20px;
  }
}

/* 选中后的字体颜色 */
.el-radio__input.is-checked + .el-radio__label {
  color: #f87c1d !important;
}

/* 选中后小圆点的颜色 */
.el-radio__input.is-checked .el-radio__inner {
  background: #f87c1d !important;
  border-color: #f87c1d !important;
}
</style>