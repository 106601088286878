<template>
  <div>
    <el-card class="cart-container container" style="box-sizing: border-box; min-height: 600px; margin-top: 20px;">
      <div class="detail" v-html="language === 'en' ? datas.enContent : language === 'ru' ? datas.enContent : datas.content"></div>
    </el-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'pageDetail',
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  }
}
</script>
<style lang="scss" scoped>
.detail {
  // padding: 40px 0;

  & img {
    max-width: 100%;
  }
}
</style>
