<template>
  <!-- 视频中心 -->

  <div class="container product-box" v-if="datas.length > 0">
    <!-- 标题 -->
    <div class="title">{{ $t('videocenter') }}</div>

    <!-- 视频内容 -->
    <div class="product_video">
      <!-- 左侧视频 -->
      <div class="video_left">
        <img :src="datas[0].imgUrl" alt="baby image" />
        <div class="video_paly">
          <i class="el-icon-video-play play" @click="playClick(0)"></i>
        </div>
      </div>

      <!-- 右侧视频 -->
      <div class="video_right">
        <div class="right-video">
          <img :src="datas[1].imgUrl" alt="baby image" />
          <div class="video_paly">
            <i class="el-icon-video-play play" @click="playClick(1)"></i>
          </div>
        </div>
        <div class="right-video">
          <img :src="datas[2].imgUrl" alt="baby image" />
          <div class="video_paly">
            <i class="el-icon-video-play play" @click="playClick(1)"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <div class="videoDialog">
      <el-dialog :title="$t('Video_playback')" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
        <span>
          <video ref="videoPlayer" controls style="width:100%">
            <source :src="videoUrl" type="video/mp4" />
          </video>
        </span>
        <span slot="footer" class="dialog-footer"></span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'videoCenter',
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: false,
      videoUrl: ''
    }
  },
  methods: {
    // 关闭视频
    handleClose(done) {
      done()
    },
    // 点击播放icon
    playClick(index) {
      let _this = this
      _this.videoUrl = ''
      _this.videoUrl = _this.datas[index].videoUrl
      _this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.product-box {
  border-radius: 20px;

  // 标题
  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-align: left;
    padding: 20px 0;
    color: #666666;
  }

  // 视频内容
  .product_video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 30px 0;

    // 左侧视频
    .video_left {
      position: relative;
      margin-right: 40px;
      height: 910px;
      width: 680px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }

      .video_paly {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .play {
          font-size: 70px;
          color: rgba(255, 255, 255);
          cursor: pointer;
        }
      }
    }

    // 右侧视频
    .video_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 910px;

      .right-video {
        position: relative;
        border-radius: 15px;
        overflow: hidden;
        height: 440px;

        img {
          width: 100%;
          height: 440px;
        }
        .video_paly {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .play {
            font-size: 70px;
            color: rgba(255, 255, 255);
            cursor: pointer;
          }
        }
      }
    }
  }

  // 弹窗
  //   .videoDialog {
  //     width: 500px;
  //     height: 400px;
  //   }
}
</style>
