<template>
  <div class="product-list">
    <ul>
      <li v-for="(item, ik) in productList" :key="ik">
        <div class="product-img" @click="$router.push({'path': '/proDetail', query:{ coNu: item.co_Nu}})">
          <img :src="item.imgUrl" :alt="item.pr_Na" />
        </div>
        <div class="product-txt">
          <h3
            @click="$router.push({'path': '/proDetail', query:{ coNu: item.co_Nu}})"
          >{{ language === "en" ? item.pr_En_Na : language === "ru" ? item.basicThreeLanguage : item.pr_Na }}</h3>
          <p class="number">{{item.co_Nu}}</p>
          <p class="price">{{ userInfo.symbol }}{{ userInfo.status===1 ? item.price : $t('viewPrice') }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { GetProductListByClass } from '@/api/home'
import transformMark from '@/utils/water'
export default {
  props: {
    valueInfo: {
      type: String,
      require: false
    }
  },
  data() {
    return {
      productList: []
    }
  },
  watch: {
    valueInfo: {
      handler() {
        const data = {
          client_Nu: '',
          cl_Nu: this.valueInfo,
          num: 4
        }
        if (this.userInfo.client_Nu) {
          data.client_Nu = this.userInfo.client_Nu
        }
        GetProductListByClass(data)
          .then(res => {
            this.productList = res.data || []
            this.productList.forEach(item => {
              transformMark(item.imgUrl).then(result => {
                item.imgUrl = result
              })
            })
          })
          .catch(err => {
            this.$message.error(err)
          })
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('operate', ['language']),
    ...mapState('user', ['userInfo'])
  }
}
</script>
<style lang="scss" scoped>
.product-list {
  width: 100%;

  ul {
    width: 100%;
    display: flex;
    padding: 20px 0;

    li {
      width: 23.5%;
      margin-right: 2%;
      box-shadow: 0 2px 2px 2px #f5f5f5;
      padding: 15px 0;

      .product-img {
        width: 90%;
        margin: 0 5% 5% 5%;
        overflow: hidden;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 200px;
          transition-duration: 0.5s;
          &:hover {
            transition-duration: 0.5s;
            transform: scale(1.1);
          }
        }
      }

      .product-txt {
        padding: 0 5%;

        h3 {
          font-size: 16px;
          font-weight: normal;
          height: 60px;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .number {
          font-size: 14px;
          height: 30px;
          line-height: 30px;
        }

        .price {
          font-size: 14px;
          color: #f24141;
          font-weight: bold;
          height: 25px;
          line-height: 25px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>