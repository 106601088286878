<template>
  <div>
    <div class="swiper-box container" :class="getSetting.isFull? 'full': 'container'" v-if="swiperList.length>0">
      <swiper v-if="swiperList" :options="swiperOption">
        <swiper-slide v-for="(item, index) in swiperList" :key="index">
          <img :src="item.imgUrl" :alt="item.name" class="swiper-img" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="new-product">
      <new-product></new-product>
    </div>

    <div class="product-recommend">
      <recomm-product :datas="recommendList"></recomm-product>
    </div>
    <!-- <div class="product-group">
      <group-product :datas="groupList"></group-product>
    </div>-->
    <div class="video-center">
      <video-center :datas="videoList"></video-center>
    </div>
    <div class="side-fixed">
      <el-popover class="btn" placement="left" title width="200" trigger="hover" content>
        <div class="weixin" style="display: flex;justify-content: center;">
          <img :src="getSetting.weChat" style="width: 100%;" alt srcset />
        </div>
        <div style="width: 100%;height:100%" slot="reference">
          <img src="../../../../assets/side_weixin.png" alt srcset />
        </div>
      </el-popover>
      <el-popover class="btn" placement="left" title width trigger="hover" content>
        <div>
          <div>{{getSetting.whatsApp || ''}}</div>
        </div>
        <div style="width: 100%;height:100%" slot="reference">
          <img src="../../../../assets/side_QQ.png" alt srcset />
        </div>
      </el-popover>
      <el-popover class="btn" placement="left" title width trigger="hover" content>
        <div>
          <div>{{getSetting.fax}}</div>
        </div>
        <div style="width: 100%;height:100%" slot="reference">
          <img src="../../../../assets/side_phone.png" alt srcset />
        </div>
      </el-popover>
      <div class="btn" @click="onScrollTop">
        <img src="../../../../assets/side_top.png" alt srcset />
      </div>
    </div>
  </div>
</template>
<script>
import {
  CarouselGetList,
  GetProductListByDate,
  GetProductListByDiscount,
  SiteSettingWebGetSiteList,
  VideoGetList
} from '@/api/home'
import { mapState, mapGetters } from 'vuex'
import newProduct from '@/components/themeTemplateThree/pc_components/productBox/newProduct.vue'
import recommProduct from '@/components/themeTemplateThree/pc_components/productBox/recommProduct.vue'
// import groupProduct from '@/components/themeTemplateThree/pc_components/productBox/groupProduct.vue'
import videoCenter from '@/components/themeTemplateThree/pc_components/video/videoCenter.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import transformMark from '@/utils/water'
export default {
  name: 'pageHome',
  components: {
    swiper,
    swiperSlide,
    newProduct,
    recommProduct,
    // groupProduct,

    videoCenter
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true //允许分页点击跳转
        }
      },
      swiperList: [],
      newProductList: [],
      recommendList: [],
      groupList: [],
      videoList: []
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    }),
    ...mapGetters('operate', {
      getEnquiry: 'get_enquiry'
    })
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      let _this = this
      // 首页轮播
      CarouselGetList()
        .then(res => {
          if (res.success) {
            _this.swiperList = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })

      const data = {
        client_Nu: _this.userInfo.client_Nu || '',
        num: 8
      }

      // 获取最新产品
      GetProductListByDate({ ...data, num: 8 })
        .then(res => {
          if (res.success) {
            _this.newProductList = res.data || []
            _this.newProductList.forEach(item => {
              transformMark(item.imgUrl).then(result => {
                item.imgUrl = result
              })

              item.products.forEach(items => {
                const ind = this.getEnquiry[0].list.findIndex(
                  it => it.co_Nu === items.co_Nu
                )
                if (ind > -1) {
                  items._num_ = true
                } else {
                  items._num_ = false
                }
              })
            })
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })

      // 推荐产品
      GetProductListByDiscount({ ...data, category: 1 })
        .then(res => {
          if (res.success) {
            _this.recommendList = res.data || []
            _this.recommendList.forEach(item => {
              transformMark(item.imgUrl).then(result => {
                item.imgUrl = result
              })
              const ind = this.getEnquiry[0].list.findIndex(
                it => it.co_Nu === item.co_Nu
              )
              if (ind > -1) {
                item._num_ = true
              } else {
                item._num_ = false
              }
            })
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })

      // 分组产品
      SiteSettingWebGetSiteList()
        .then(res => {
          if (res.success) {
            _this.groupList = res.data || []
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })

      // 视频
      const model = {
        success: true,
        code: 0,
        message: 'string',
        data: {},
        extras: {},
        timestamp: new Date()
      }
      VideoGetList(model)
        .then(res => {
          if (res.success) {
            _this.videoList = res.data
            console.log('object :>> ', res.data);
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })
    },
    onScrollTop() {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style lang="scss" scoped>
.full {
  width: 100%;
}

.container {
  margin-top: 10px;
}

.swiper-box {
  height: 560px;

  .swiper-img {
    width: 100%;
    height: 560px;
  }

  :deep(.swiper-pagination-bullet-active) {
    background-color: #fca81e;
  }
}

.product-recommend {
  width: 100%;
  padding: 40px 0;
}

.video-center {
  background-color: #f5f5f5;
}
.weixin{
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    height: 100%;
  }
}
.side-fixed {
  position: fixed;
  top: 400px;
  right: 0px;
  // background-color: #eee;
  .btn {
    width: 48px;
    height: 48px;
    background-color: #eba61a;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ffaa00;
      color: #f5f5f5;
    }
    :deep .el-popover__reference-wrapper {
      width: 100%;
      height: 100%;
      .el-popover__reference {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>