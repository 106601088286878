<template>
    <div class="magnifyingGlass">
      <div
        ref="content"
        class="small-box"
        @mouseleave="mouseleave"
        @mouseenter="mouseenter"
        @mousemove="mousemove"
        :style="{ width: configs.width + 'px', height: configs.height + 'px' }"
      >
        <img :src="bigSrc" alt="" />
        <div
          ref="mask"
          v-show="maskConfig.maskShow"
          class="mask"
          :style="{
            backgroundColor: configs.mBgc,
            width: configs.mW + 'px',
            height: configs.mH + 'px',
            left: maskConfig.left + 'px',
            top: maskConfig.top + 'px'
          }"
        ></div>
      </div>
      <div
        v-show="bingImgConfig.boxShow"
        class="big-box"
        :style="{ width: configs.width + 'px', height: configs.height + 'px' }"
      >
        <div class="img-box">
          <img
            ref="bigImg"
            :src="bigSrc"
            alt=""
            :style="{
              width: configs.width * configs.scale + 'px',
              height: configs.height * configs.scale + 'px',
              left: bingImgConfig.left + 'px',
              top: bingImgConfig.top + 'px'
            }"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      bigSrc: {
        type: String,
        default: ''
      },
      configs: {
        type: Object,
        default: () => {
          return {
            width: 524,
            height: 393,
            mW: 140,
            mH: 110,
            mBgc: 'rgba(0, 21, 41, 0.3)',
            mO: 0.6,
            scale: 3
          }
        }
      }
    },
  
    data() {
      return {
        maskConfig: {
          maskShow: false,
          left: 0,
          top: 0
        },
        bingImgConfig: {
          boxShow: false,
          left: 0,
          top: 0
        }
      }
    },
    methods: {
      mousemove(e) {
        const { content, mask } = this.$refs
        const imgRectNow = content.getBoundingClientRect()
        const objX = e.clientX - imgRectNow.left
        const objY = e.clientY - imgRectNow.top
        let maskX = objX - mask.offsetWidth / 2
        let maskY = objY - mask.offsetHeight / 2
        maskX = maskX < 0 ? 0 : maskX
        maskY = maskY < 0 ? 0 : maskY
        if (maskX + mask.offsetWidth >= imgRectNow.width) {
          maskX = imgRectNow.width - mask.offsetWidth
        }
        if (maskY + mask.offsetHeight >= imgRectNow.height) {
          maskY = imgRectNow.height - mask.offsetHeight
        }
        this.maskConfig.left = maskX
        this.maskConfig.top = maskY
        this.bingImgConfig.top = -(maskY * this.configs.scale)
  
        if (maskX * this.configs.scale <= (this.configs.scale - 1) * this.configs.width) {
          this.bingImgConfig.left = -(maskX * this.configs.scale)
        }
      },
      mouseenter() {
        this.bingImgConfig.boxShow = true
        this.maskConfig.maskShow = true
      },
      mouseleave() {
        this.bingImgConfig.boxShow = false
        this.maskConfig.maskShow = false
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .magnifyingGlass {
    position: relative;
    .small-box {
      position: relative;
      cursor: move;
      img {
        width: 100%;
        height: 100%;
      }
      .mask {
        position: absolute;
      }
    }
  
    .big-box {
      width: 100%;
      height: 100%;
      right: -100%;
      transform: translate(1%);
      top: 0;
      position: absolute;
      overflow: hidden;
      .img-box {
        background-color: white;
        width: 100%;
        height: 100%;
        position: relative;
        img {
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
  }
  img {
    object-fit: contain;
  }
  </style>
  