<template>
  <div class="footer-box">
    <div class="container footer-container">
      <div class="footer-info">
        <div class="left">
          <img :src="footerLogo" />
        </div>
        <div class="right">
          <div class="understand">
            <div class="flex title">{{$t('DK.understandDeke')}}</div>
            <div class="flex" style="line-height:50px">
              <div style="width:180px" @click="$router.push({ path: '/home' })">{{$t('DK.navTitle.Home')}}</div>
              <!-- <div style="flex:1" @click="$router.push({ path: '/about' })">{{$t('DK.navTitle.Message')}}</div> -->
            </div>
            <div class="flex" @click="$router.push({ path: '/contact' })">{{$t('DK.navTitle.Exhibition')}}</div>
          </div>
          <div class="contact">
            <div class="flex title">{{$t('DK.contactUs')}}</div>
            <div class="flex" style="line-height:50px">
              <div style="width:160px">QQ：{{getSetting.whatsApp}}</div>
              <div style="width:220px">{{$t('DK.telephone')}}：{{ getSetting.fax }}</div>
              <div style="flex:1">{{$t('DK.phoneAndWeixin')}}：{{getSetting.mobile}}</div>
            </div>
            <div class="flex">
              <div style="width:160px">Skype：{{getSetting.skype || ''}}</div>
              <div style="width:220px">Email：{{ getSetting.homeMail }}</div>
              <div style="flex:1">{{$t('companyAddress')}}：{{ $hs(getSetting.address,getSetting.enAddress,getSetting.rusAddress) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <span v-html="$hs(getSetting.copyRight, getSetting.enCopyRight,getSetting.rusCopyRight)"></span>
        <span>
          {{$t('Technic')}}：
          <a href="http://www.hsrj.net" target="_blank" style="text-decoration: none;color:#d6d6d6">{{$t('Software')}}</a>
        </span>
      </div>
    </div>
  </div>
</template>
  <script>
import { mapState, mapGetters } from 'vuex'
import { TENANTID } from '@/store/mutation-types'
export default {
  data() {
    return {
      footerLogo: require('@/assets/' + TENANTID + '.png')
    }
  },
  computed: {
    ...mapState('operate', ['language']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  },
}
</script>
  <style lang="scss" scoped>
.footer-box {
  color: #d6d6d6;
  background: #333333;
  width: 100%;
  min-height: 186px;
  max-height: 300px;
  .footer-info {
    width: 100%;
    display: flex;
    min-height: 136px;
    max-height: 250px;
    .left {
      width: 180px;
      height: 100%;
      padding: 40px 30px 30px 0;
      box-sizing: border-box;
      display: flex;
      align-content: center;
      img{
        max-width: 120px;
        max-height: 60px;
      }
    }
    .right {
      flex: 1;
      display: flex;
      padding: 20px 0;
      .understand {
        width: 250px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
      }

      .contact {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .flex {
        display: flex;
        font-size: 14px;

        &.title {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .copyright {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
  }
}
</style>