<template>
  <div class="nav-bar-box" :style="{ backgroundColor: tabColor, height: tabHeight + 'px' }">
    <div class="container nav-box">
      <div class="nav-item">
        <category-menu :datas="getMenus" :height="tabHeight"></category-menu>
        <el-menu
          :background-color="menuBackground"
          :text-color="textColor"
          :default-active="active"
          :active-text-color="activetextColor"
          router
          mode="horizontal"
        >
          <el-menu-item index="/home">{{ $t('home') }}</el-menu-item>
          <el-menu-item index="/about">{{ $t("aboutus") }}</el-menu-item>
          <el-menu-item index="/product" @click="productClick">{{ $t("product") }}</el-menu-item>
          <el-menu-item index="/cart">{{ $t("cart") }}</el-menu-item>
          <el-menu-item index="/contact">{{ $t("contactus") }}</el-menu-item>
        </el-menu>
      </div>
      <div class="search-box" v-if="hasSearchInput">
        <el-input :placeholder="$t('babyToys')" v-model="keywords" @keyup.enter.native="enterSearch"></el-input>
        <i class="el-icon-camera" @click="imgSearch"></i>
        <i class="el-icon-search" @click="enterSearch"></i>
      </div>
    </div>
  </div>
</template>

<script>
import categoryMenu from '@/components/themeTemplateOne/pc_components/navBar/category'
import { mapGetters } from 'vuex'
import bus from '@/utils/bus'
export default {
  name: 'navBar',
  components: {
    categoryMenu
  },
  props: {
    tabColor: {
      type: String,
      default: '#fe6a00'
    },
    tabHeight: {
      type: Number,
      default: 60
    },
    menuBackground: {
      type: String,
      default: '#fe6a00'
    },
    textColor: {
      type: String,
      default: '#fff'
    },
    activetextColor: {
      type: String,
      default: '#fff'
    },
    hasSearchInput: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      keywords: '',
      active: this.$route.path
    }
  },
  watch: {
    $route: {
      handler() {
        this.active = this.$route.path
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('setting', {
      getMenus: 'get_menus'
    })
  },
  methods: {
    productClick() {
      this.$bus.$emit('anewClick', '')
    },
    /* 图搜 */
    imgSearch() {
      if (this.$route.path !== '/product') {
        this.$router.push({ path: '/product', query: { isImg: true } })
      }
      bus.$emit('imgSearch', true)
    },
    enterSearch() {
      if (this.$route.path !== '/product') {
        this.$router.push({
          path: '/product',
          query: { keywords: this.keywords }
        })
      }
      this.$bus.$emit('searchProduct', this.keywords)
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-bar-box {
  width: 100%;

  .nav-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-item {
      flex: 1;
      display: flex;

      .el-menu {
        height: 100%;
        border-bottom: none !important;

        .el-menu-item {
          font-weight: bold;
          font-size: 16px;
          width: 140px;
          text-align: center;
          height: 100%;
          line-height: 60px;
          border-bottom: none !important;
          overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;

          &:hover {
            background-color: #fca81e !important;
          }
        }

        :deep(.is-active) {
          background-color: #fca81e !important;
        }
      }
    }

    .search-box {
      width: 240px;
      display: flex;
      background: #fff;
      align-items: center;
      height: 40px;
      line-height: 40px;

      :deep(.el-input__inner) {
        border: 0;
      }

      .el-icon-camera {
        cursor: pointer;
        padding: 0 10px;
        color: #b2b2b2;
        font-size: 20px;
        border-left: 2px solid #f2f2f2;
      }

      .el-icon-search {
        cursor: pointer;
        padding: 0 10px;
        color: #b2b2b2;
        font-size: 20px;
        border-left: 2px solid #f2f2f2;
      }
    }
  }
}
</style>