<!--
 * @Description: 
 * @Author: zml
 * @Date: 2023-03-21 11:49:51
 * @LastEditors: zml
 * @LastEditTime: 2023-03-25 09:47:04
-->
<template>
  <div>
    <div style="background-color: #f5f5f5;height:20px"></div>
    <div class="container title">{{$t('aboutus')}}</div>
    <div class="container detail" v-html="$hs(datas.content,datas.enContent,datas.enContent)"></div>
    <div style="background-color: #f5f5f5;height:20px"></div>
    <div class="container contact-us">
      <div class="title">{{$t('DK.contactUs')}}</div>
      <div class="info-box">
        <div class="info top-info">
          <div style="min-width:500px">
            <div class="til">{{$t('address')}}</div>
            <div class="value">{{$hs(getSetting.address,getSetting.enAddress,getSetting.rusAddress)||'-'}}</div>
          </div>
          <div>
            <div class="til">{{$t('DK.telephone')}}</div>
            <div class="value">{{ getSetting.fax ||'-' }}</div>
          </div>
          <div>
            <div class="til">{{$t('DK.phoneAndWeixin')}}</div>
            <div class="value">{{getSetting.mobile}}</div>
          </div>
        </div>
        <div class="info bottom-info">
          <div style="min-width:500px">
            <div class="til">QQ</div>
            <div class="value">{{getSetting.whatsApp ||'-'}}</div>
          </div>
          <div>
            <div class="til">Email</div>
            <div class="value">{{ getSetting.homeMail || '-' }}</div>
          </div>
          <div>
            <div class="til">Skype</div>
            <div class="value">{{getSetting.skype || '-'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'pageDetail',
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('operate', ['language']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    })
  }
}
</script>
<style lang="scss" scoped>
.detail {
  padding: 40px 0;

  & img {
    max-width: 100%;
  }
}
.title {
  height: 90px;
  line-height: 90px;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
}
.contact-us {
  display: flex;
  flex-direction: column;
  height: 380px;
  padding: 0 20px;

  .info-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0 20px 0 0;

      .til {
        font-size: 20px;
        color: #222;
      }
      .value {
        font-size: 18px;
        color: #666666;
      }
    }
  }
  .top-info {
    border-bottom: 1px solid #eeeeee;
  }
}
</style>
