<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-03-21 12:19:28
-->
<template>
    <div>
        <about-detail-one v-if="cutTemplates === 'themeTemplateOne'" :datas="aboutData"></about-detail-one>
        <about-detail-tow v-if="cutTemplates === 'themeTemplateTow'" :datas="aboutData"></about-detail-tow>
        <about-detail-three v-if="cutTemplates === 'themeTemplateThree'" :datas="aboutData"></about-detail-three>
    </div>
</template>
<script>
// import aboutDetail from '@/components/page/detail/index'
import { getCompanyInfo } from '@/api/home'
import mixins from '@/utils/mixins';
export default {
    name: 'aboutPage',
    mixins: [mixins],
    components: {
        // aboutDetail
    },
    data () {
        return {
            aboutData: {}
        }
    },
    created () {
        this.init()
    },
    methods: {
        init () {
            // 获取公司简介
            let _this = this
            getCompanyInfo().then(res => {
                if (res.success) {
                    _this.aboutData = res.data
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>

</style>