<template>
  <div class="big-box">
    <div class="container">
      <el-card class="cart-container">
        <div slot="header">
          <h3>{{ $t('myShoppingCart') }}</h3>
        </div>
        <div class="cart-list" v-if="gettingCart.length > 0 && Object.keys(userInfo).length > 0">
          <el-table ref="cartTable" :data="gettingCart" tooltip-effect="dark" @selection-change="selectionChange">
            <el-table-column type="selection" prop="isCheck" width="55" align="center"></el-table-column>
            <el-table-column :label="$t('ID')" type="index" prop="index" sortable="custom" width="50" align="center"></el-table-column>
            <el-table-column :label="$t('picture')" width="120" align="center">
              <template slot-scope="scope">
                <div class="pro-img">
                  <img
                    :src="scope.row.imgUrl || image"
                    alt
                    srcset
                    @click="$router.push({ path: '/proDetail', query: { coNu: scope.row.co_Nu } })"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="co_Nu" :label="$t('ItemNO')" align="center"></el-table-column>
            <el-table-column :label="$t('name')" align="center" width="100">
              <template
                slot-scope="scope"
              >{{ language === 'en' ? scope.row.pr_En_Na : language === 'ru' ? scope.row.basicThreeLanguage : scope.row.pr_Na }}</template>
            </el-table-column>
            <el-table-column prop="price" :label="$t('price')" sortable="custom" align="center">
              <template slot-scope="scope">
                <div class="price">{{ userInfo.symbol }}{{ userInfo.status === 1 ? scope.row.price.toFixed(3) : $t('viewPrice') }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('packingCapacity')" prop="packingCapacity" sortable="custom" width="180" align="center">
              <template slot-scope="scope">{{ scope.row.number * scope.row.ou_Lo }} PCS</template>
            </el-table-column>
            <el-table-column :label="$t('CBM')" align="center">
              <template slot-scope="scope">{{ scope.row.bulk_Stere }}(CBM)</template>
            </el-table-column>
            <el-table-column :label="$t('CTNS')" prop="number" sortable="custom" align="center" width="110">
              <template slot-scope="scope">
                <el-input oninput="value=value.replace(/[^\d.]/g,'')" @blur="updateNum(scope.row)" v-model.number="scope.row.number"></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="$t('total')" prop="total" sortable="custom" align="center">
              <template slot-scope="scope">
                <div
                  class="price"
                >{{ userInfo.symbol }}{{ userInfo.status === 1 ? (scope.row.number * scope.row.price * scope.row.ou_Lo): $t('viewPrice') }}</div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" :label="$t('delete')" width="110" align="center">
              <template slot-scope="scope">
                <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small">{{ $t('delete') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="action-list">
            <div class="action">
              <div class="check-all">
                <el-checkbox v-model="checkAll" @change="selectAll">{{ $t('checkAll') }}</el-checkbox>
              </div>
              <div class="del-cart" @click="clear">
                <i class="el-icon-delete del-icon" :style="{ fontSize: '14px' }"></i>
                {{ $t('clear') }}
              </div>
            </div>
            <div class="total">
              <div class="count">
                <p>
                  {{ $t('totalRecords') }}：
                  <span>{{ selectRows.length }}</span>
                </p>
                <p>
                  {{ $t('totalCTNS') }}：
                  <span>{{ totalNumber }}</span>
                </p>
                <p>
                  {{ $t('totalQuantity') }}：
                  <span>{{ totalPics }}</span>
                </p>
              </div>
              <div class="price">
                {{ $t('totalPrice') }}：
                <span>
                  {{ userInfo.status === 1 ? userInfo.symbol + totalPrice :
                  $t('viewPrice')
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="submit-box">
            <el-button
              type="primary"
              :loading="isLoading"
              @click="submitOrder()"
              :disabled="!selectRows.length && userInfo.client_Nu !== null"
            >
              {{ $t('submit')
              }}({{ selectRows.length }})
            </el-button>
          </div>
        </div>
        <el-empty :description="$t('noData')" v-else></el-empty>
      </el-card>
    </div>
  </div>
</template>
<script>
import { deletCartsApi } from '@/api/cart'
import { mapGetters, mapActions, mapState } from 'vuex'
import { addOrder } from '@/api/order'
export default {
  name: 'pageCart',
  data() {
    return {
      checkAll: false,
      isLoading: false,
      selectRows: []
    }
  },
  computed: {
    ...mapGetters('operate', {
      gettingCart: 'getting_cart'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    ...mapState('operate', ['language']),
    // 总箱数
    totalNumber() {
      return this.selectRows.reduce(
        (total, item) => total + Number(item.number),
        0
      )
    },
    // 总数量
    totalPics() {
      return this.selectRows.reduce(
        (total, item) => total + item.number * item.ou_Lo,
        0
      )
    },
    // 总价格
    totalPrice() {
      let totalPrice = 0
      if (this.selectRows.length > 0) {
        this.selectRows.forEach(item => {
          totalPrice += item.number * item.price * item.ou_Lo
        })
      }
      return totalPrice.toFixed(2)
    }
  },
  created() {
    // 默认全选
    // 加定时器 处理显示执行table选中逻辑但由于DOM并没有更新完，打印this.$refs为空
    if (this.gettingCart.length > 0) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.cartTable.toggleAllSelection()
        })
      }, 500)
    }
  },
  methods: {
    ...mapActions('operate', {
      addCart: 'add_cart',
      deleteCart: 'delete_cart',
      clearCart: 'clear_cart',
      batchDeleteCart: 'batch_delete_cart'
    }),
    // 删除购物车数据
    deleteRow(row) {
      let _this = this
      this.$confirm(this.$t('deleteData'), this.$t('systemTip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      })
        .then(() => {
          if (this.userInfo) {
            deletCartsApi({
              userId: this.userInfo.id,
              coNus: [row.co_Nu]
            }).then(res => {
              if (res.success) {
                this.deleteCart(row.co_Nu)
              }
            })
          }
          _this.$message({
            type: 'success',
            message: _this.$t('deleteSuccess')
          })
        })
        .catch(() => { })
    },
    // 修改数量
    updateNum(row) {
      this.addCart({
        client_Nu: this.userInfo.client_Nu,
        userId: this.userInfo.id,
        co_Nu: row.co_Nu,
        fa_No: row.fa_No,
        imgUrl: row.imgUrl,
        in_En: row.in_En,
        number: row.number,
        ou_Lo: row.ou_Lo,
        pr_En_Na: row.pr_En_Na,
        pr_Na: row.pr_Na,
        price: row.price
      })
    },
    // 选中全部
    selectAll() {
      if (this.gettingCart.length === 0) return
      this.$refs.cartTable.toggleAllSelection()
    },
    // 选中
    selectionChange(rows) {
      this.selectRows = rows
      this.checkAll = rows.length === this.gettingCart.length
    },
    // 清空购物车
    clear() {
      let _this = this
      let newArr = []
      _this.gettingCart.forEach(value => {
        if (value.co_Nu) {
          newArr.push(value.co_Nu)
        }
      })
      this.$confirm(this.$t('clearCartData'), this.$t('systemTip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      })
        .then(() => {
          if (_this.userInfo) {
            deletCartsApi({
              userId: _this.userInfo.id,
              coNus: newArr
            }).then(res => {
              if (res.success) {
                _this.clearCart()
                _this.selectRows = []
              }
            })
          }

          _this.$message({
            type: 'success',
            message: _this.$t('deleteSuccess')
          })
        })
        .catch(() => { })
    },
    // 提交订单
    submitOrder() {
      let _this = this
      let newArr = []
      _this
        .$confirm(_this.$t('submitTip'), _this.$t('systemTip'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        })
        .then(() => {
          const orderParam = {
            userAccount: _this.userInfo.userAccount,
            consignee: _this.userInfo.userName,
            mobile: _this.userInfo.mobile,
            address: _this.userInfo.address,
            orderRemark: '',
            orderDetails: _this.selectRows.map(item => {
              return Object.assign(
                {},
                { co_Nu: item.co_Nu, number: item.number }
              )
            })
          }

          _this.isLoading = true
          addOrder(orderParam)
            .then(res => {
              if (res.success) {
                _this.$message.success(_this.$t('orderSuccess'))
                // 清除vuex缓存
                _this.selectRows.forEach(ite => {
                  _this.gettingCart.forEach(item => {
                    if (ite.co_Nu == item.co_Nu) {
                      newArr.push(ite.co_Nu)
                    }
                  })
                })
                // 购物车商品生成订单成功后
                deletCartsApi({
                  userId: _this.userInfo.id,
                  coNus: newArr
                }).then(res => {
                  if (res.success) {
                    _this.batchDeleteCart(newArr)
                  }
                })
                // 清除表格选中
                _this.$refs.cartTable.clearSelection()
                _this.$router.push({
                  path: '/order/detail',
                  query: { orderNo: res.data }
                })
              } else {
                _this.$message.error(res.message)
              }
            })
            .catch(err => {
              _this.$message.error(err)
            })
            .finally(() => {
              _this.isLoading = false
            })
        })
        .catch(() => { })
    }
  }
}
</script>
<style lang="scss" scoped>
.big-box {
  padding: 10px 0;
  background: #f1f3f6;

  .cart-container {
    min-height: 400px;

    & .cart-list {
      & .pro-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;

        & img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100px;
        }
      }

      & .price {
        color: #ff3e3e;
        font-weight: bold;
      }

      & .action-list {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;

        & .action {
          display: flex;
          height: 32px;
          align-items: center;

          & .del-cart {
            cursor: pointer;
            margin-left: 30px;
            color: #ff760e;
            border: 1px solid #ff760e;
            padding: 5px 15px;
            border-radius: 5px;
            font-size: 14px;

            .del-icon {
              margin-right: 10px;
            }
          }
        }

        & .count {
          display: flex;
          align-content: center;

          & p {
            margin-left: 20px;
            text-align: right;

            & span {
              color: #ff3e3e;
              font-size: 18px;
              margin-left: 5px;
            }
          }
        }

        & .price {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          & span {
            color: #ff3e3e;
            font-size: 24px;
            font-weight: 700;
            margin-left: 5px;
          }
        }
      }

      & .submit-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 60px;
        margin-top: 20px;

        & button {
          width: 200px;
          height: 50px;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>