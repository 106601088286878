<template>
    <div class="search-box">
        <div class="search-tip">
            <p>{{ $t('comprehensiveInquiry') }}</p>
            <p @click="isOpen=!isOpen">{{ $t('advancedSearch') }}<i
                    :class="isOpen? 'el-icon-arrow-up':'el-icon-arrow-down'"></i></p>
        </div>
        <div class="form-box">
            <el-form label-width="170px" :model="searchForm" label-suffix=":">
                <el-row>
                    <el-col :span="isOpen?8:7">
                        <el-form-item :label="$t('productName')">
                            <el-input size="small" v-model="searchForm.pr_Na" :placeholder="$t('inputPlaceholder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="isOpen?8:7">
                        <el-form-item :label="$t('coNuNum')">
                            <el-input size="small" v-model="searchForm.co_Nu" :placeholder="$t('inputPlaceholder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="isOpen?8:7">
                        <el-form-item :label="$t('packing')">
                            <el-select size="small" clearable v-model="searchForm.pa_Nu"
                                :placeholder="$t('selectPlaceholder')" class="select-block">
                                <el-option v-for="item in packList" :key="item.pa_Nu"
                                    :label="$t('packing') === 'Packing' ? item.en_Pa : item.ch_Pa" :value="item.pa_Nu">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="btn-search" v-if="!isOpen">
                        <el-button type="primary" size="small" icon="el-icon-search" @click="search" v-show="!isOpen">{{
                        $t('search') }}</el-button>
                    </el-col>
                </el-row>
                <el-collapse-transition>
                    <div class="advanced-search" v-show="isOpen">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item :label="$t('productSize')">
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.pr_Le" :placeholder="$t('L')"></el-input>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.pr_Wi" :placeholder="$t('W')"></el-input>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.pr_Hi" :placeholder="$t('H')"></el-input>
                                    </el-col>
                                    <el-col class="tip" :span="4">CM</el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item :label="$t('cartonSize')">
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.ou_Le" :placeholder="$t('L')"></el-input>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.ou_Wi" :placeholder="$t('W')"></el-input>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.ou_Hi" :placeholder="$t('H')"></el-input>
                                    </el-col>
                                    <el-col class="tip" :span="4">CM</el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item :label="$t('packageSize')">
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.in_Le" :placeholder="$t('L')"></el-input>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.in_Wi" :placeholder="$t('W')"></el-input>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                            v-model="searchForm.in_Hi" :placeholder="$t('H')"></el-input>
                                    </el-col>
                                    <el-col class="tip" :span="4">CM</el-col>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item :label="$t('packingCapacity')">
                                    <el-input size="small" oninput="value=value.replace(/[^\d.]/g,'')"
                                        v-model="searchForm.ou_Lo" :placeholder="$t('inputPlaceholder')"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <div class="actionList">
                                    <el-button size="small" type="info" plain @click="reset">{{ $t('reset') }}
                                    </el-button>
                                    <el-button size="small" type="primary" icon="el-icon-search" @click="search">{{
                                    $t('search') }}</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-collapse-transition>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            isOpen: false,
            packList: [],
            searchForm: {
                pr_Na: '',
                co_Nu: '',
                pa_Nu: '',
                pr_Hi: '',
                pr_Le: '',
                pr_Wi: '',
                ou_Hi: '',
                ou_Le: '',
                ou_Wi: '',
                in_Le: '',
                in_Wi: '',
                in_Hi: '',
                ou_Lo: ''
            }
        }
    },
    methods: {
        // 搜索
        search () {
            const conditionals = []
            Object.keys(this.searchForm).forEach(item => {
                // 样品名称和出厂货号
                if (this.searchForm[item].trim() !== '' && (item === 'pr_Na' || item === 'co_Nu')) {
                    conditionals.push({
                        conditionalType: 1, // 1为模糊搜索
                        fieldName: item,
                        fieldValue: this.searchForm[item]
                    })
                    return
                }
                // 其他字段构建复杂搜索条件
                if (this.searchForm[item].trim() !== '') {
                    conditionals.push({
                        conditionalType: 0, // 0为精确搜索
                        fieldName: item,
                        fieldValue: this.searchForm[item]
                    })
                }
            })

            this.$emit('search', conditionals)
        },
        // 重置表单
        reset () {
            this.searchForm = {
                pr_Na: '',
                co_Nu: '',
                pa_Nu: '',
                pr_Hi: '',
                pr_Le: '',
                pr_Wi: '',
                ou_Hi: '',
                ou_Le: '',
                ou_Wi: '',
                in_Le: '',
                in_Wi: '',
                in_Hi: '',
                ou_Lo: ''
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.search-box {
    color: #333;
    font-size: 14px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;

    & .search-tip {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    & .form-box {
        padding-top: 20px;

        & .select-block {
            width: 100%;
        }

        & .btn-search {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            & .el-button {
                font-size: 14px;
            }
        }

        & .line {
            text-align: center;
        }

        & .tip {
            text-align: center;
            font-size: 12px;
        }

        .actionList {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;

            & .el-button {
                width: 120px;
                margin: 0 10px;
                font-size: 14px;
            }
        }
    }
}
</style>