<template>
  <!-- 商品详情 -->
  <div class="big-box">
    <div class="container">
      <div class="pro-box">
        <div class="img-info">
          <div class="goods-img">
            <pic-zoom :bigSrc="activeImg || defaultImg" v-if="defaultImg"></pic-zoom>
          </div>
          <div class="swiper">
            <swiper :options="swiperOption" ref="mySwiper" class="swiper-box">
              <swiper-slide class="swiper-slide" v-for="(item, index) in proInfo.imgList" :key="index">
                <div class="item-img">
                  <img :src="item.filePath" @click.stop="hoverImg(item)" @mouseenter="setImg(item)" class="swiper-img" />
                </div>
              </swiper-slide>
            </swiper>
            <!-- 左右箭头 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <div class="pro-info">
          <h3>{{ language === 'en' ? proInfo.pr_En_Na : language === 'ru' ? proInfo.basicThreeLanguage : proInfo.pr_Na }}</h3>
          <dl>
            <dt>{{ $t('price') }}：</dt>
            <dd>
              <span class="symbol">{{ userInfo.symbol }}</span>
              <span class="price">{{ userInfo.status === 1 ? Number(proInfo.price) : $t('viewPrice') }}</span>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('packing') }}：</dt>
            <dd>{{ $t('language') === 'English' ? proInfo.en_Pa || '' : proInfo.ch_Pa || '' }}</dd>
          </dl>
          <dl>
            <dt>{{ $t('productSize') }}：</dt>
            <dd>
              {{ proInfo.pr_Le || 0 }} x {{ proInfo.pr_Wi || 0 }} x {{ proInfo.pr_Hi || 0 }}
              <span class="tip">(cm)</span>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('packageSize') }}：</dt>
            <dd>
              {{ proInfo.in_Le || 0 }} x {{ proInfo.in_Wi || 0 }} x {{ proInfo.in_Hi || 0 }}
              <span class="tip">(cm)</span>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('cartonSize') }}：</dt>
            <dd>
              {{ proInfo.ou_Le || 0 }} x {{ proInfo.ou_Wi || 0 }} x {{ proInfo.ou_Hi || 0 }}
              <span class="tip">(cm)</span>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('innerBoxAndOuterPacking') }}：</dt>
            <dd>
              {{ proInfo.in_En || 0 }} / {{ proInfo.ou_Lo || 0 }}
              <span class="tip">(pcs)</span>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('CBMAndCUFT') }}：</dt>
            <dd>
              {{ proInfo.bulk_Stere || 0.00 }} (cbm) / {{ proInfo.bulk_Feet || 0.00 }}
              <span class="tip">(cuft)</span>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('GWAndNW') }}：</dt>
            <dd>
              {{ proInfo.gr_We || 0 }} / {{ proInfo.ne_We || 0 }}
              <span class="tip">(kg)</span>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('mOQ') }}：</dt>
            <dd>{{ proInfo.moq || '--' }}</dd>
          </dl>
          <div class="cart-box">
            <el-badge :value="0" class="item" hidden>
              <el-button
                @click="click(proInfo)"
                type="primary"
                icon="el-icon-shopping-cart-2"
              >{{ ifAdd(proInfo.co_Nu) ? $t('delCart') : $t('addCart') }}</el-button>
            </el-badge>
          </div>
        </div>
      </div>
      <div class="pro-detail-box">
        <p v-for="(item, index) in proInfo.imgList" :key="index">
          <img class="imgs" :src="item.filePath" alt srcset />
        </p>
        <p v-for="(item, index) in proInfo.packImgList" :key="index">
          <img class="imgs" :src="item.filePath" />
        </p>
        <p v-for="(item, index) in proInfo.realImgList" :key="index">
          <img class="imgs" :src="item.filePath" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import picZoom from "@/components/themeTemplateOne/pc_components/productBox/picZoom.vue"
import "swiper/dist/css/swiper.css"
import { swiper, swiperSlide } from "vue-awesome-swiper"
import { mapActions, mapGetters, mapState } from "vuex"
import { deletCartsApi, addCartsApi } from "@/api/cart"
export default {
  name: "proDetail",
  components: {
    picZoom,
    swiper,
    swiperSlide
  },
  props: {
    proInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      activeImg: "",
      activeIndex: 0,
      swiperOption: {
        slidesPerView: 4,
        initialSlide: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        observer: false, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, // 修改swiper的父元素时，自动初始化swiper
        imgArr: [],
        preventLinksPropagation: false // 阻止点击事件冒泡
      }
    }
  },
  computed: {
    ...mapGetters("user", {
      userInfo: "get_userInfo"
    }),
    ...mapGetters("operate", {
      gettingCart: "getting_cart"
    }),
    // 语言
    ...mapState("operate", ["language"]),

    defaultImg() {
      return this.proInfo.imgUrl
    },
    // 已加购还是取消加购
    ifAdd() {
      return coNu => {
        return this.gettingCart.findIndex(item => item.co_Nu === coNu) > -1
      }
    }
  },
  mounted() {
    sessionStorage.setItem("detail", true)
  },

  methods: {
    ...mapActions("operate", {
      addCart: "add_cart",
      deleteCart: "delete_cart"
    }),

    click(proInfo) {
      if (!this.ifAdd(proInfo.co_Nu)) {
        // 添加商品
        if (this.userInfo) {
          addCartsApi({
            client_Nu: this.userInfo.client_Nu,
            userId: this.userInfo.id,
            co_Nu: proInfo.co_Nu,
            number: 1
          }).then(res => {
            if (res.success) {
              this.addCart({
                client_Nu: this.userInfo.client_Nu,
                userId: this.userInfo.id,
                co_Nu: proInfo.co_Nu,
                fa_No: proInfo.fa_No,
                imgUrl: proInfo.imgUrl,
                in_En: proInfo.in_En,
                number: 1,
                ou_Lo: proInfo.ou_Lo,
                pr_En_Na: proInfo.pr_En_Na,
                pr_Na: proInfo.pr_Na,
                price: proInfo.price
              });
            }
          });
        }
      } else {
        // 删除商品
        if (this.userInfo) {
          deletCartsApi({
            userId: this.userInfo.id,
            coNus: [proInfo.co_Nu]
          }).then(res => {
            if (res.success) {
              this.deleteCart(proInfo.co_Nu);
            }
          });
        }
      }
    },
    // 图片经过时，设置大图
    hoverImg(data) {
      this.activeImg = data.filePath
    },
    // 图片点击时，设置大图
    setImg(data) {
      this.activeImg = data.filePath
    }
  }
};
</script>
<style lang="scss" scoped>
.big-box {
  padding: 10px 0;
  background: #f1f3f6;
  .pro-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    display: flex;

    & .img-info {
      width: 536px;

      & .goods-img {
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #dcdfe6;
      }

      .swiper {
        position: relative;
        box-sizing: border-box;
        margin-top: 10px;
        width: 100%;
        height: 65px;

        & .swiper-container {
          width: 86%;
          padding: 0 7%;
          height: 67px;
          position: relative;

          & .swiper-wrapper {
            height: 67px;
            display: flex;
            align-items: center;
          }

          & .swiper-slide {
            width: 20% !important;

            & .item-img {
              margin: 0 5px;
              height: 61px;
              border: solid 1px #dcdfe6;
              padding: 2px;
              display: flex;
              justify-content: center;

              & img {
                width: auto;
                max-width: 100%;
                max-height: 65px;
              }
            }
          }
        }
      }
    }

    & .pro-info {
      flex: 1;
      padding-left: 40px;
      color: #333;

      & h3 {
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 30px;
      }

      & dl {
        width: 100%;
        display: flex;
        line-height: 40px;

        & dt,
        dd {
          font-size: 16px;
          color: #333;

          & .tip {
            font-size: 14px;
          }
        }

        & .symbol {
          color: #ff3e3e;
          font-size: 16px;
        }

        & .price {
          color: #ff3e3e;
          font-size: 36px;
          font-weight: bold;
        }
      }

      & .cart-box {
        width: 100%;
        padding-top: 20px;
      }
    }
  }

  .pro-detail-box {
    margin-top: 15px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;

    & p {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;

      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>