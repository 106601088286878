<template>
  <div class="header-top">
    <div class="container header-container">
      <div class="cart-info">
        <i class="el-icon-shopping-cart-2 icon"></i>
        <ul class="car-list">
          <li>
            <span>{{gettingCart.length}}</span> type
          </li>
          <li>
            <span>{{sumCartPics}}</span>pieces
          </li>
          <li>
            $
            <span>{{sumCartPrice}}</span>
          </li>
        </ul>
      </div>
      <div class="member-info">
        <el-menu mode="horizontal" default-active="0" background-color="#3b4edb">
          <el-submenu index="0" v-if="userInfo.id">
            <template slot="title">{{userInfo.userName}}</template>
            <el-menu-item @click="linkAddress">{{$t('userAddress')}}</el-menu-item>
          </el-submenu>
          <el-menu-item index="0" v-else @click="login">{{$t("login")}}</el-menu-item>
          <el-menu-item index="1" v-if="!userInfo.id" @click="register">{{ $t("registration") }}</el-menu-item>
          <el-menu-item index="2" @click="linkOrder">{{ $t("order") }}</el-menu-item>
          <el-menu-item index="3" v-if="userInfo.id" @click="logout">{{ $t("logout") }}</el-menu-item>
        </el-menu>
        <div class="language-box" v-if="getLanguageConfig.languageList">
          <img :src="activeIcon" alt="Language" class="lang-icon" />
          <el-select v-model="language" @change="changeLanguage">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              v-show="getLanguageConfig.languageList.includes(item.value)"
              :value="item.name"
              style="display:flex; align-items: center;"
            >
              <img :src="item.src" :alt="item.name" style="width: 18px; height: 12px; margin-right: 5px;" />
              <span style="font-size: 12px;">{{item.name}}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <reg-modal ref="regModal" @login="login"></reg-modal>
    <login-modal ref="loginModal" @register="register"></login-modal>
  </div>
</template> 
<script>
import regModal from '@/components/themeTemplateTow/pc_components/memberModal/regModal.vue'
import loginModal from '@/components/themeTemplateTow/pc_components/memberModal/loginModal.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { setDocumentTitle } from '@/utils/domUtil'

export default {
  name: 'headerTop',
  components: {
    regModal,
    loginModal
  },
  data() {
    return {
      options: [
        { name: 'English', value: 'en', src: require('@/assets/English.png') },
        { name: '中文', value: 'zh-Hans', src: require('@/assets/China.png') },
        {
          name: 'русский язык',
          value: 'ru',
          src: require('@/assets/Russia.png')
        }
      ],
      language: this.$t('language'),
      languageIcon: require('@/assets/English.png')
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('setting', {
      getSetting: 'get_setting',
      getLanguageConfig: 'get_language_config'
    }),
    ...mapGetters('operate', {
      gettingCart: 'getting_cart'
    }),
    // 监听中英变换显示不同的图标
    activeIcon() {
      return this.$t('language') == 'English'
        ? this.options[0].src
        : this.$t('language') == '中文'
        ? this.options[1].src
        : this.options[2].src
    },
    // 购物车总数量
    sumCartPics() {
      let sumPics = 0
      if (this.gettingCart.length === 0) return sumPics
      // 计算购物车总数量（件数*装箱量）
      this.gettingCart.forEach(item => {
        sumPics += item.number * item.ou_Lo
      })
      return sumPics
    },
    // 购物车总金额
    sumCartPrice() {
      let sumPrice = 0
      if (this.gettingCart.length === 0) return sumPrice

      // 计算购物车总数量（件数*装箱量）
      this.gettingCart.forEach(item => {
        sumPrice += item.number * item.ou_Lo * item.price
      })
      return sumPrice.toFixed(2)
    }
  },
  created() {
    // this.changeLanguage()
  },
  methods: {
    // ...mapActions("operate", ["set_language"]),
    ...mapActions('operate', {
      setCart: 'set_cart',
      set_language: 'set_language'
    }),
    ...mapActions('user', ['logout']),
    changeLanguage(e) {
      if (e === 'English') {
        this.$i18n.locale = 'en'
        this.set_language('en')
        this.languageIcon = this.options[0].src
        setDocumentTitle(this.getSetting.enTitle)
        // window.location.reload()
      } else if (e === '中文') {
        this.$i18n.locale = 'zh'
        this.set_language('zh')
        this.languageIcon = this.options[1].src
        setDocumentTitle(this.getSetting.title)
        // window.location.reload()
      } else if (e === 'русский язык') {
        this.$i18n.locale = 'ru'
        this.set_language('ru')
        this.languageIcon = this.options[2].src
        setDocumentTitle(this.getSetting.rusTitle)
        // window.location.reload()
      }
    },
    // 登录
    login() {
      // 打开登录模块
      this.$refs.loginModal.open()
    },
    // 注册
    register() {
      // 打开注册模块
      this.$refs.regModal.open()
    },
    // 跳转订单
    linkOrder() {
      if (Object.keys(this.userInfo).length > 0) {
        this.$router.push({ path: '/order' })
      } else {
        this.$refs.loginModal.open()
      }
    },
    // 跳转地址
    linkAddress() {
      this.$router.push({ path: '/address' })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-menu-item {
  height: 32px !important;
  line-height: 32px !important;
  font-size: 12px;
  color: #fff !important;
}
.header-top {
  width: 100%;
  height: 32px;
  line-height: 32px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: #3b4edb;
  & .header-container {
    height: 32px;
    line-height: 32px;
    display: flex;

    & .cart-info {
      display: flex;
      flex: 1;
      align-items: center;

      & .icon {
        font-size: 20px;
        padding-right: 5px;
        color: #fff;
      }

      & .car-list {
        display: flex;
        align-items: center;

        & li {
          padding: 0 10px;
          font-size: 12px;
          color: #fff;

          & span {
            color: #ca3833;
            padding: 0 5px;
          }
        }
      }
    }

    & .member-info {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & :deep(.el-submenu__title) {
        height: 32px !important;
        line-height: 32px !important;
        color: #fff;
      }

      & .el-menu {
        height: 32px;
        border-bottom: none !important;
        background-color: transparent;

        & :deep(.el-menu-item) {
          font-size: 12px;
          padding: 0 15px;
          color: #fff;
          text-align: center;
          height: 100%;
          line-height: 32px;
          border-bottom: none !important;
        }
      }

      & .language-box {
        display: flex;
        align-items: center;
        padding-left: 10px;

        & .lang-icon {
          width: 18px;
          height: 12px;
          margin-right: 5px;
        }

        & .el-select {
          :deep(.el-input__suffix) {
            right: 25px !important;
            top: -1px;
          }

          :deep(.el-input__inner) {
            padding-left: 5px;
            padding-right: 5px !important;
            font-size: 12px;
            width: 90px;
            height: 26px;
            border: none;
            color: #fff;
            background-color: #3656dc;
          }

          :deep(.el-input__icon) {
            line-height: 26px;
          }
        }

        :deep(.el-select-dropdown__list) {
          :deep(.el-select-dropdown__item) {
            display: flex;
            align-items: center;
            font-size: 12px !important;
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>