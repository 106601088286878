<template>
    <div>
        <page-address-one v-if="cutTemplates === 'themeTemplateOne'"></page-address-one>
        <page-address-tow v-if="cutTemplates === 'themeTemplateTow'"></page-address-tow>
        <page-address-three v-if="cutTemplates === 'themeTemplateThree'"></page-address-three>
    </div>
</template>
<script>
// import pageHome from '@/components/page/home' pageAddressOne
import mixins from '@/utils/mixins';
export default {
    name: 'addressPage',
    mixins: [mixins],
    components: {
        // pageHome
    },
    data() {
        return {
            swiperList: []
        }
    }
}
</script>
<style lang="scss" scoped>

</style>