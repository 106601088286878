<template>
  <!-- 联系我们 -->

  <div class="container product-box">
    <!-- 标题信息 -->
    <div class="product_title">
      <h2>{{ $t('contactus') }}</h2>
      <p>{{ $t('please_contact_us') }}</p>
    </div>

    <!-- 地图展示 -->
    <div class="product-box product_map">
      <img src="@/assets/or_map.png" alt />
    </div>

    <!-- icons 列表 -->
    <div class="container">
      <div class="product_icons">
        <ul>
          <li>
            <div>
              <img src="@/assets/Email.png" alt="Email image" class="li_img" />
            </div>

            <div class="icons_items">
              <p>{{ $t('ock_email') }}</p>
              <p>{{ getSetting.homeMail }}</p>
            </div>
          </li>
          <li>
            <div>
              <img src="@/assets/phone.png" alt="phone image" class="li_img" />
            </div>
            <div class="icons_items">
              <p>{{ $t('hotLine') }}</p>
              <p>{{ getSetting.mobile }}</p>
            </div>
          </li>
          <li>
            <div>
              <img src="@/assets/location.png" alt="location image" class="li_img" />
            </div>

            <div class="icons_items">
              <p>{{ $t('companyAddres') }}</p>
              <p>{{ language == 'en' ? getSetting.enAddress : language == 'ru' ? getSetting.rusAddress : getSetting.address }}</p>
            </div>
          </li>
          <li>
            <div>
              <img src="@/assets/tate.png" alt="tate image" class="li_img" />
            </div>

            <div class="icons_items">
              <p>{{ $t('workingHours') }}</p>
              <p>09:00-24:00</p>
              <p>{{ $t('week') }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'contactUs',
  props: {
    datas: {
      type: null
    }
  },
  computed: {
    ...mapState('operate', ['language']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  }
}
</script>

<style lang="scss" scoped>
.product-box {
  margin-top: 50px;
  width: 100%;

  // 标题信息
  .product_title {
    text-align: center;
    color: rgb(255, 255, 255);

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 15px;
    }
  }

  // 地图
  .product_map {
    margin: 30px 20px 20px 0;
    margin-top: 15px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: aliceblue;
    padding-top: 20px;
    & img {
      max-width: 100%;
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
    height: 500px;
  }

  // icons 列表
  .product_icons {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: left;

    ul {
      height: 80px;
      display: flex;
      justify-content: space-between;

      li {
        width: 24%;
        align-items: center;
        display: flex;

        .icons_items {
          padding-left: 10px;
          color: rgb(255, 255, 255);
          display: flex;
          font-size: 14px;
          flex-direction: column;
          justify-content: center;
          height: 80px;
          p {
            padding-bottom: 3px;
          }

          p:nth-child(1) {
            height: 22px;
          }
          p:nth-child(2) {
            overflow: hidden;
          }
        }
        &:nth-child(1) {
          width: 18%;
        }
        &:nth-child(3) {
          width: 30%;
        }
      }
    }
  }
}
</style>
