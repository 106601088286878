<template>
  <div v-if="datas.length != 0" class="nav-menu" :style="{ height: tabHeight + 'px' }">
    <h3>
      <i class="el-icon-s-unfold"></i>
      {{ $t('categories')}}
    </h3>
    <div class="nav">
      <div ref="divDom" @mouseleave="leaveBox">
        <ul class="left">
          <li>
            <div class="nav-tab" v-for="(item, index) in datas" :key="index">
              <div class="tab-left" @click="navBtn(item)" @mouseenter="enterBox(item)">
                <span class="icon"></span>
                <span>
                  {{ language === "en" ? item.cl_Ne_Na : language === "ru" ? item.threeLanguage : item.cl_Na}}
                </span>
              </div>
              <div v-if="item.children && item.children.length>0" class="tab-right el-icon-arrow-right"></div>
            </div>
          </li>
        </ul>
        <ul class="right" ref="rightBoxDom">
          <li>
            <div class="nav-tab" v-for="(item, index) in childrenList" :key="index">
              <div class="tab-left" @click="navBtn(item)">
                <span class="icon"></span>
                <span>
                  {{ language === "en" ? item.cl_Ne_Na : language === "ru" ? item.threeLanguage : item.cl_Na}}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else class="nav-menu" :style="{ height: tabHeight + 'px' }">
    <h3>
      <i class="el-icon-s-unfold"></i>
      {{ $t('categories')}}
    </h3>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'categoryMenu',
  data() {
    return {
      childrenList: [],
      flag: false
    }
  },
  props: {
    datas: {
      type: Array,
      default: () => []
    },
    tabHeight: {
      type: Number,
      default: () => 66
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  },
  methods: {
    // 点击跳转
    navBtn(value) {
      this.linkUrl(value.cl_Nu)
    },
    //控制hover进入显示
    enterBox(value) {
      const rightBoxDom = this.$refs.rightBoxDom
      if (value.children && value.children.length > 0) {
        rightBoxDom.style.display = 'block'
        this.childrenList = value.children
      } else {
        rightBoxDom.style.display = 'none'
        this.childrenList = []
      }
    },
    //控制hover进入隐藏
    leaveBox() {
      const rightBoxDom = this.$refs.rightBoxDom
      rightBoxDom.style.display = 'none'
    },
    linkUrl(cl_Nu) {
      if (this.$route.path !== '/product') {
        this.$router.push({ path: '/product', query: { clNu: cl_Nu } })
      }
      this.$bus.$emit('categoryClick', cl_Nu)
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-menu {
  width: 300px;
  position: relative;
  margin-right: 10px;

  h3 {
    height: 66px;
    font-size: 16px;
    line-height: 66px;
    box-sizing: border-box;
    border-bottom: 1px solid #20148b;
    color: #fff;
    padding: 0 30px;
    .el-icon-s-unfold {
      margin-right: 6px;
    }
  }

  .nav {
    & > div {
      position: relative;
      display: flex;
      width: 600px;
      z-index: 999;

      ul {
        list-style: none;
        height: 434px;
        border-right: 1px solid #140770;
      }

      .left,
      .right {
        width: 300px;
        // background-color: #140770;
        overflow: auto;
        box-sizing: border-box;

        /*滚动条整体宽度*/
        &::-webkit-scrollbar {
          width: 4px;
          /*宽对应滚动条的尺寸*/
        }

        /*轨道*/
        &::-webkit-scrollbar-track {
          background-color: rgba(241, 243, 244, 0.1);
        }

        /*滑块*/
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #cbbb;
        }

        li {
          width: 100%;
          cursor: pointer;

          .nav-tab {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            line-height: 48px;
            padding: 0 20px 0 30px;
          }

          .tab-left {
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;

            .icon {
              display: inline-block;
              border: 2px solid #eee;
              background-color: #eee;
              width: 1px;
              height: 1px;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }
      }

      .right {
        display: none;
        width: 300px;
        background-color: #140770;
        box-shadow: 0 0 60px #2c3baa inset;
      }
    }

    & > div:hover {
      ul {
        border-right: 1px solid #140770;
      }

      // .right {

      // }
    }
    .nav-tab:hover {
      background-color: #3b4edb;
    }
  }
}
</style>
