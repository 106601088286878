<template>
  <div>
    <div class="container">
      <div class="nav-box">
        <span class="nav-btn" @click="$router.push({path:'/home'})">{{$t('DK.navTitle.Home')}}</span>>
        <span class="nav-btn" @click="$router.push({path:'/contact'})">{{$t('DK.navTitle.Exhibition')}}</span>>
        <span style="color:#4a90f1">{{ $hs(newDetailData.title,newDetailData.enTitle) }}</span>
      </div>
      <div class="news-title">
        <div class="tit">{{ $hs(newDetailData.title,newDetailData.enTitle) }}</div>
        <div class="date">{{$t('DK.ReleaseDate')}}：{{ newDetailData.createdTime }}</div>
      </div>
      <div v-html="$hs(newDetailData.content,newDetailData.enContent)||''"></div>
    </div>
    <div class="product-recommend" v-if="recommendList.length > 0">
      <recomm-product :datas="recommendList" title="展会关联产品"></recomm-product>
      <div class="action-box container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParam.pageNo"
          :page-sizes="[8, 20, 40, 80, 100]"
          :page-size="queryParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRows"
        ></el-pagination>
      </div>
    </div>
    <div class="video-center" v-if="videoList.length > 0">
      <div class="container">
        <div class="title">{{$t('DK.ProductsVideo')}}</div>
        <div class="video-box">
          <div class="video-item" v-for="(item,index) in videoList" :key="index" @click="playClick(item.videoUrl)">
            <img :src="item.imgUrl" alt />
            <i class="el-icon-video-play play"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="videoList.length == 0 && recommendList.length == 0" class="container">
      <el-empty :description="$t('noData')" class="empty"></el-empty>
    </div>-->
    <!-- 弹窗 -->
    <div class="videoDialog">
      <el-dialog :title="$t('Video_playback')" :visible.sync="dialogVisible" :before-close="handleClose">
        <span>
          <video ref="videoPlayer" :src="videoUrl" type="video/mp4" style="max-height:580px;max-width:100%" controls autoplay></video>
        </span>
        <span slot="footer" class="dialog-footer"></span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { GetDetail, queryPageByNewsList, getByNewsList } from '@/api/news'
import { mapState, mapGetters } from 'vuex'
import recommProduct from '@/components/themeTemplateThree/pc_components/productBox/recommProduct.vue'
import videoCenter from '@/components/themeTemplateThree/pc_components/video/videoCenter.vue'
import transformMark from '@/utils/water'
export default {
  name: 'pageHome',
  components: {
    recommProduct,
    videoCenter
  },
  data() {
    return {
      dialogVisible: false,
      videoUrl: '',
      recommendList: [],
      groupList: [],
      videoList: [],
      newDetailData: {},
      queryParam: {
        client_Nu: '',
        pageNo: 1,
        pageSize: 8,
        sortField: '',
        sortOrder: '',
        keywords: '',
        newsId: 0
      },
      totalRows: 0
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    })
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.queryParam.client_Nu = this.userInfo.client_Nu || ''
      this.queryParam.newsId = Number(this.$route.query.num)
      // 推荐产品
      queryPageByNewsList(this.queryParam)
        .then(res => {
          if (res.success) {
            this.recommendList = res.data.rows || []
            this.queryParam.pageNo = res.data.pageNo
            this.queryParam.pageSize = res.data.pageSize
            this.totalRows = res.data.totalRows
            this.queryParam.totalPage = res.data.totalPage
            this.recommendList.forEach(item => {
              transformMark(item.imgUrl).then(result => {
                item.imgUrl = result
              })
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })

      // 渲染html
      GetDetail({ id: this.$route.query.num })
        .then(res => {
          if (res.success) {
            this.newDetailData = res.data || {}
              let date = ''
              date = new Date(this.newDetailData.createdTime)
              let year = date.getFullYear()
              let month = date.getMonth() + 1
              let d = date.getDate()
              this.newDetailData.createdTime = year + '-' + month + '-' + d
   
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })

      // 视频
      getByNewsList({ newsId: Number(this.$route.query.num) })
        .then(res => {
          if (res.success) {
            this.videoList = res.data
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    // 关闭视频
    handleClose(done) {
      done()
      this.videoUrl = ''
    },
    // 点击播放icon
    playClick(videoUrl) {
      this.videoUrl = videoUrl
      this.dialogVisible = true
    },
    // 分页大小操作
    handleSizeChange(pageSize) {
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.initData()
    },
    // 分页翻页操作
    handleCurrentChange(pageNo) {
      // sessionStorage.setItem('activePageNo', pageNo)
      this.queryParam.pageNo = pageNo
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin-top: 10px;
}

.news-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  .tit {
    height: 50px;
    font-size: 24px;
    color: #222222;
  }
  .date {
    font-size: 12px;
    color: #666666;
    margin: 0 0 20px 0;
  }
}

.product-recommend {
  width: 100%;
  padding: 40px 0;
}

.video-center {
  background-color: #f5f5f5;

  .title {
    font-size: 24px;
    font-weight: bold;
    color: #666666;
    padding: 30px 0 20px 0;
  }
  .video-box {
    display: flex;
    flex-wrap: wrap;
    .video-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32.2%;
      height: 270px;
      margin: 0 1.7% 20px 0;
      background-color: #474b4e;
      border-radius: 6px;
      box-shadow: 0 0 10px 3px #ddd;
      overflow: hidden;
      cursor: pointer;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:hover {
        img {
          transition-duration: 0.5s;
          transform: scale(1.1);
        }
      }
      .play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 0px 0px 8px #bbb;
        font-size: 60px;
        color: #eee;
      }
      img {
        max-width: 100%;
        max-height: 270px;
        transition-duration: 0.5s;
      }
    }
  }
}

.nav-box {
  margin: 0 0 30px 0;
  .nav-btn {
    cursor: pointer;
    &:hover {
      color: #4a90f1;
    }
  }
}
:deep .el-dialog__body {
  max-height: 670px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #000;
  padding: 0 !important;
  margin: 0 20px;
}

.action-box {
  background-color: #fff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  // box-shadow: 0 0 10px 3px #ddd;
  box-sizing: border-box;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  .more {
    font-size: 16px;
    font-weight: normal;
  }
}
</style>