<!--
 * @Description: 展会计划
 * @Author: zml
 * @Date: 2023-03-21 11:49:51
 * @LastEditors: zml
 * @LastEditTime: 2023-03-25 10:08:47
-->
<template>
  <div>
    <div style="background-color: #f5f5f5;height:20px"></div>
    <div class="container">
      <div class="items" v-for="(item,index) in newsData" :key="index">
        <div class="left" @click="$router.push({path:'/newDetail',query:{num: item.id}})">
          <img :src="item.imgUrl" alt srcset />
        </div>
        <div class="right">
          <div class="content-title">
            <span style="font-size:30px; margin:0 30px 0 0">{{item.createdTime }}</span>
            <span style="cursor: pointer;" @click="$router.push({path:'/newDetail',query:{num: item.id}})">{{ $hs(item.title,item.enTitle) }}</span>
          </div>
          <div class="content">{{$hs(item.description,item.enDescription)||''}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { QueryPage } from '@/api/news'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'pageDetail',
  data() {
    return {
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        sortField: '',
        sortOrder: '',
        keywords: ''
      },
      newsData: []
    }
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('operate', ['language']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    })
  },
  created() {
    this.queryPage()
  },
  methods: {
    queryPage() {
      QueryPage(this.queryParam)
        .then(res => {
          if (res.success) {
            this.newsData = res.data.rows || []
            this.newsData.forEach(item => {
              let date = ''
              date = new Date(item.createdTime)
              let year = date.getFullYear()
              let month = date.getMonth() + 1
              let d = date.getDate()
              item.createdTime = year + '/' + month + '/' + d
            })
          }
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  padding: 50px 0;
}

.items {
  display: flex;
  height: 300px;
  margin-bottom: 30px;
  // background-color: #eee;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 390px;
    height: 300px;
    box-shadow: 0 0 10px 2px #f5f5f5;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 10px 6px #eee;
    }
    img {
      max-width: 390px;
      max-height: 300px;
    }
  }
  .right {
    flex: 1;
    font-size: 16px;
    padding: 0 20px;
    .content-title {
      margin: 0 0 16px 0;
      font-weight: bold;
    }
    .content {
      line-height: 30px;
      color: #222222;
    }
  }
}
</style>
