<template>
  <el-dialog
    :title="$t('login')"
    @close="close"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px"
    center
    append-to-body
  >
    <el-form :model="form" ref="form" label-width="170px" :rules="rules">
      <el-form-item prop="userAccount" :label="$t('useraccount') + ':'">
        <el-input v-model="form.userAccount"></el-input>
      </el-form-item>
      <el-form-item prop="userPassword" :label="$t('password') + ':'">
        <el-input type="password" v-model="form.userPassword"></el-input>
      </el-form-item>
      <el-form-item prop="valideCode" :label="$t('vaildecode') + ':'" class="valide-code">
        <el-input v-model="form.valideCode"></el-input>
        <img v-if="JSON.stringify(codeData) !== '{}'" @click="getCode" :src="codeData.valideCode" title="看不清楚？换一张" />
      </el-form-item>
    </el-form>
    <div class="Registration" @click="toRegister">{{ $t("toregister") }}</div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit" class="btn">{{ $t('login') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GetValideCode, LoginMember } from '@/api/login'
export default {
  name: 'loginModal',
  data() {
    return {
      visible: false,
      codeData: {},
      form: {
        userAccount: '',
        userPassword: '',
        valideCode: ''
      },
      rules: {
        userAccount: [
          {
            required: true,
            message: this.$t('inputUserAccount'),
            trigger: 'blur'
          }
        ],
        userPassword: [
          {
            required: true,
            message: this.$t('inputPassword'),
            trigger: 'blur'
          },
          { min: 6, message: this.$t('passwordLength'), trigger: 'blur' }
        ],
        valideCode: [
          {
            required: true,
            message: this.$t('inputVaildeCode'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  },
  methods: {
    ...mapActions('user', ['set_userInfo']),
    open() {
      this.visible = true
      this.getCode()
    },
    close() {
      this.visible = false
      this.$refs.form.resetFields()
    },
    // 提交
    submit() {
      let _this = this
      this.$refs.form.validate(values => {
        if (!values) return false
        LoginMember({ ...this.form, keyCode: this.codeData.keyCode })
          .then(res => {
            if (res.success) {
              _this.set_userInfo(res.data)
              _this.$message.success(_this.$t('loginSuccess'))
              _this.close()
              window.location.reload() // 强刷界面
            }
          })
          .catch(err => {
            if (err.includes('D1632') || err.includes('D1631')) {
              _this.$message.error(_this.$t('CodeError'))
            } else if (err.includes('D1629')) {
              _this.$message.error(_this.$t('notreviewed'))
            } else if (err.includes('D1628')) {
              _this.form.userPassword = ''
              _this.$message.error(_this.$t('loginError'))
            }
            _this.getCode()
          })
      })
    },
    // 获取图形验证码
    getCode() {
      GetValideCode()
        .then(res => {
          this.codeData = res.data
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    // 去注册
    toRegister() {
      this.close()
      this.$emit('register')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-dialog__header) {
  text-align: left;
  padding: 20px;
}

:deep .el-form {
  padding: 0 40px 0 0;
  .valide-code {
    .el-input {
      margin: 0 10px 0 0;
    }
    .el-form-item__content {
      display: flex;
    }
  }
}

.Registration {
  padding: 15px 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #ff760e;
  }
}

button {
  width: 300px;
  background-color: #ff760e;
  border-color: #ff760e;

  &:hover {
    background-color: #f87c1d;
    border-color: #f87c1d;
  }
}

.dialog-footer {
  // display: flex;
  .btn {
    margin-bottom: 20px;
  }
}
</style>