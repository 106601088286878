var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"magnifyingGlass"},[_c('div',{ref:"content",staticClass:"small-box",style:({ width: _vm.configs.width + 'px', height: _vm.configs.height + 'px' }),on:{"mouseleave":_vm.mouseleave,"mouseenter":_vm.mouseenter,"mousemove":_vm.mousemove}},[_c('img',{attrs:{"src":_vm.bigSrc,"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.maskConfig.maskShow),expression:"maskConfig.maskShow"}],ref:"mask",staticClass:"mask",style:({
        backgroundColor: _vm.configs.mBgc,
        width: _vm.configs.mW + 'px',
        height: _vm.configs.mH + 'px',
        left: _vm.maskConfig.left + 'px',
        top: _vm.maskConfig.top + 'px'
      })})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bingImgConfig.boxShow),expression:"bingImgConfig.boxShow"}],staticClass:"big-box",style:({ width: _vm.configs.width + 'px', height: _vm.configs.height + 'px' })},[_c('div',{staticClass:"img-box"},[_c('img',{ref:"bigImg",style:({
          width: _vm.configs.width * _vm.configs.scale + 'px',
          height: _vm.configs.height * _vm.configs.scale + 'px',
          left: _vm.bingImgConfig.left + 'px',
          top: _vm.bingImgConfig.top + 'px'
        }),attrs:{"src":_vm.bigSrc,"alt":""}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }