<template>
  <div class="big-box">
    <div class="container">
      <el-card class="cart-container">
        <div slot="header">
          <h3>{{ userInfo.id ? $t('myShoppingCart') : $t('DK.MyRFQ')}}</h3>
        </div>
        <div class="cart-list" v-if="userInfo.id ? gettingCart.length != 0 : getEnquiry[0].list.length != 0">
          <el-table
            ref="cartTable"
            :data="userInfo.id ? gettingCart : getEnquiry[0].list"
            @select="selectRow"
            tooltip-effect="dark"
            @selection-change="selectionChange"
          >
            <el-table-column type="selection" prop="isCheck" align="center" width="42"></el-table-column>
            <el-table-column :label="$t('ID')" type="index" prop="index" sortable="custom" align="center"></el-table-column>
            <el-table-column :label="$t('picture')" align="center" width="110">
              <template slot-scope="scope">
                <div class="pro-img">
                  <img
                    :src="scope.row.imgUrl || image"
                    alt
                    srcset
                    @click="$router.push({ path: '/proDetail', query: { coNu: scope.row.co_Nu } })"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="co_Nu" :label="$t('DK.ArticleNo')" width="110" align="center"></el-table-column>
            <el-table-column :label="$t('DK.Description')" align="center" width="110">
              <template slot-scope="scope">{{ $hs(scope.row.pr_Na,scope.row.pr_En_Na,scope.row.basicThreeLanguage) || ''}}</template>
            </el-table-column>

            <el-table-column :label="$t('DK.OuterQTY') + '(PCS)'" prop="packingCapacity" align="center">
              <template slot-scope="scope">{{ scope.row.ou_Lo }}</template>
            </el-table-column>
            <el-table-column :label="$t('CBM')" align="center" prop="bulk_Stere">
              <template slot-scope="scope">{{ scope.row.bulk_Stere }}</template>
            </el-table-column>
            <el-table-column :label="$t('CTNS')" prop="number" align="center" width="110">
              <template slot-scope="scope">
                <el-input
                  v-if="userInfo.id"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                  @blur="updateNum(scope.row)"
                  v-model.number="scope.row.number"
                ></el-input>
                <div v-else>{{ scope.row.number }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('DK.totalQTY') + '(PCS)'" align="center">
              <template slot-scope="scope">{{ scope.row.number * scope.row.ou_Lo }}</template>
            </el-table-column>
            <el-table-column :label="$t('DK.TotalVolume')" align="center" width="100">
              <template slot-scope="scope">{{ scope.row.bulk_Stere * scope.row.number}}</template>
            </el-table-column>
            <el-table-column v-if="userInfo.id" prop="price" :label="$t('price')" width="80" align="center">
              <template slot-scope="scope">
                <div class="price">{{ userInfo.symbol }}{{ userInfo.status === 1 ? scope.row.price : $t('viewPrice') }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="userInfo.id"
              :label="$t('total') + `(${$hs(userInfo.currencyCn,userInfo.currency)})`"
              prop="total"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <div class="price">
                  {{ userInfo.symbol }}{{ userInfo.status === 1 ? (scope.row.number *
                  scope.row.price * scope.row.ou_Lo).toFixed(4) : $t('viewPrice')
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" :label="$t('delete')" width="70" align="center">
              <template slot-scope="scope">
                <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small">{{ $t('delete') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="action-list">
            <div class="action">
              <div class="check-all">
                <el-checkbox v-model="checkAll" @change="selectAll">{{ $t('checkAll') }}</el-checkbox>
              </div>
              <div class="del-cart" @click="clear">
                <i class="el-icon-delete del-icon" :style="{ fontSize: '14px' }"></i>
                {{ $t('clear') }}
              </div>
            </div>
            <div class="total">
              <div class="count">
                <p>
                  {{ $t('DK.totalItems') }}：
                  <span>{{ selectRows.length }}</span>
                </p>
                <p>
                  {{ $t('totalCTNS') }}：
                  <span>{{ totalNumber }}</span>
                </p>
                <p>
                  {{ $t('DK.TotalVolume') }}：
                  <span>{{ totalCMB }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <el-empty :description="$t('noData')" v-else></el-empty>
      </el-card>
      <div class="submit-box">
        <div class="price" v-if="userInfo.id">
          {{ $t('totalPrice') }}：
          <span>{{ userInfo.status === 1 ? userInfo.symbol + totalPrice : $t('viewPrice')}}</span>
        </div>
        <el-button
          type="primary"
          :loading="isLoading"
          @click="submitOrder()"
          :disabled="!selectRows.length && userInfo.client_Nu !== null"
        >{{userInfo.id ? $t('submit') : $t('DK.enquiry')}}({{ selectRows.length }})</el-button>
      </div>
    </div>
    <el-dialog :title="$t('DK.enquiry')" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <!-- <el-form-item :label="$t('linkman')" prop="consignee">
            <el-input v-model="ruleForm.consignee"></el-input>
          </el-form-item>-->
          <el-form-item :label="$t('email')" prop="email">
            <el-input v-model="ruleForm.email" :placeholder="$t('inputEmail')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('DK.contact')" prop="mobile">
            <el-input v-model="ruleForm.mobile" :placeholder="$t('PleaseContact')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('remark')" prop="inquiryRemark">
            <el-input type="textarea" v-model="ruleForm.inquiryRemark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="onConfirm">{{ $t('confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { deletCartsApi, createInquiry } from '@/api/cart'
import { mapGetters, mapActions, mapState } from 'vuex'
import { addOrder } from '@/api/order'
export default {
  name: 'pageCart',
  data() {
    return {
      ruleForm: {
        consignee: '',
        mobile: '',
        email: '',
        inquiryRemark: ''
      },
      isLoading: false,
      rules: {
        mobile: [{ required: true, trigger: 'blur' }],
        email: [{ required: true, trigger: 'blur' }]
      },
      dialogVisible: false,
      checkAll: true,
      selectRows: []
    }
  },
  computed: {
    ...mapGetters('operate', {
      gettingCart: 'getting_cart',
      getEnquiry: 'get_enquiry'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    ...mapState('operate', ['language']),
    // 总箱数
    totalNumber() {
      return this.selectRows.reduce(
        (total, item) => total + Number(item.number),
        0
      )
    },
    // 总体积
    totalCMB() {
      return this.selectRows.reduce((total, item) => {
        let cbm =
          (total * 10000 + item.number * item.bulk_Stere * 10000) / 10000
        return cbm.toFixed(4)
      }, 0)
    },
    // 总价格
    totalPrice() {
      let totalPrice = 0
      if (this.selectRows.length > 0) {
        this.selectRows.forEach(item => {
          totalPrice += item.number * item.price * item.ou_Lo
        })
      }
      return totalPrice.toFixed(4)
    }
  },
  created() {
    if (this.userInfo.id) {
      this.deleteEnquiry('')
    } else {
      this.clearCart()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.cartTable.toggleAllSelection()
    })
  },
  methods: {
    ...mapActions('operate', {
      addCart: 'add_cart',
      deleteCart: 'delete_cart',
      clearCart: 'clear_cart',
      batchDeleteCart: 'batch_delete_cart',
      deleteEnquiry: 'delete_enquiry'
    }),
    // 删除购物车数据
    deleteRow(row) {
      if (!this.userInfo.id) {
        this.deleteEnquiry(row)
        return
      }
      let _this = this
      this.$confirm(this.$t('deleteData'), this.$t('systemTip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      })
        .then(() => {
          if (this.userInfo) {
            deletCartsApi({
              userId: this.userInfo.id,
              coNus: [row.co_Nu]
            }).then(res => {
              if (res.success) {
                this.deleteCart(row.co_Nu)
              }
            })
          }
          _this.$message({
            type: 'success',
            message: _this.$t('deleteSuccess')
          })
        })
        .catch(() => {})
    },
    // 修改数量
    updateNum(row) {
      this.addCart({
        client_Nu: this.userInfo.client_Nu,
        userId: this.userInfo.id,
        co_Nu: row.co_Nu,
        fa_No: row.fa_No,
        imgUrl: row.imgUrl,
        in_En: row.in_En,
        number: row.number,
        ou_Lo: row.ou_Lo,
        pr_En_Na: row.pr_En_Na,
        pr_Na: row.pr_Na,
        price: row.price,
        bulk_Stere: row.bulk_Stere
      })
    },
    // 选中全部
    selectAll() {
      // if (this.gettingCart.length === 0 || this.getEnquiry[0].list.length === 0)
      //   return
      this.checkAll = this.checkAll
      this.$refs.cartTable.toggleAllSelection()
    },
    // 选中
    selectionChange(rows) {
      this.selectRows = rows
      console.log('rows :>> ', rows)
      // this.checkAll = rows.length === this.gettingCart.length || rows.length === this.getEnquiry[0].list.length
    },

    selectRow(selection, row) {
      if (selection.length === 0) {
        this.checkAll = false
      }
      console.log('selection, row :>> ', selection, row)
    },

    // 清空购物车
    clear() {
      if (!this.userInfo.id) {
        for (let i = 0; i < this.selectRows.length; i++) {
          this.deleteEnquiry(this.selectRows[i])
        }
        return
      }

      let _this = this
      let newArr = []
      _this.gettingCart.forEach(value => {
        if (value.co_Nu) {
          newArr.push(value.co_Nu)
        }
      })
      this.$confirm(this.$t('clearCartData'), this.$t('systemTip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      })
        .then(() => {
          if (_this.userInfo) {
            deletCartsApi({
              userId: _this.userInfo.id,
              coNus: newArr
            }).then(res => {
              if (res.success) {
                _this.clearCart()
                _this.selectRows = []
              }
            })
          }

          _this.$message({
            type: 'success',
            message: _this.$t('deleteSuccess')
          })
        })
        .catch(() => {})
    },

    onConfirm() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = {
            consignee: _this.ruleForm.consignee,
            mobile: _this.ruleForm.mobile,
            email: _this.ruleForm.email,
            inquiryRemark: _this.ruleForm.inquiryRemark,
            coNus: []
          }
          _this.selectRows.forEach(item => {
            obj.coNus.push(item.co_Nu)
          })
          createInquiry(obj)
            .then(res => {
              if (res.success) {
                _this.$message.success('询价成功')
              } else {
                _this.$message.error('询价失败' + res.message)
              }
            })
            .catch(err => {
              _this.$message.error('询价失败' + err.message)
            })
            .finally(() => {
              _this.dialogVisible = false
              _this.$refs.ruleForm.resetFields()
            })
        } else {
          return false
        }
      })
    },

    // 提交订单
    submitOrder() {
      if (!this.userInfo.id) {
        this.dialogVisible = true
        return
      }

      let _this = this
      let newArr = []
      this.$confirm(_this.$t('submitTip'), _this.$t('systemTip'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        })
        .then(() => {
          const orderParam = {
            userAccount: _this.userInfo.userAccount,
            consignee: _this.userInfo.userName,
            mobile: _this.userInfo.mobile,
            address: _this.userInfo.address,
            orderRemark: '',
            orderDetails: _this.selectRows.map(item => {
              return Object.assign(
                {},
                { co_Nu: item.co_Nu, number: item.number }
              )
            })
          }

          _this.isLoading = true
          addOrder(orderParam)
            .then(res => {
              if (res.success) {
                _this.$message.success(_this.$t('orderSuccess'))
                // 清除vuex缓存
                _this.selectRows.forEach(ite => {
                  _this.gettingCart.forEach(item => {
                    if (ite.co_Nu == item.co_Nu) {
                      newArr.push(ite.co_Nu)
                    }
                  })
                })
                // 购物车商品生成订单成功后
                deletCartsApi({
                  userId: _this.userInfo.id,
                  coNus: newArr
                }).then(res => {
                  if (res.success) {
                    _this.batchDeleteCart(newArr)
                  }
                })
                // 清除表格选中
                _this.$refs.cartTable.clearSelection()
                _this.$router.push({
                  path: '/order/detail',
                  query: { orderNo: res.data }
                })
              } else {
                _this.$message.error(res.message)
              }
            })
            .catch(err => {
              _this.$message.error(err)
            })
            .finally(() => {
              _this.isLoading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.big-box {
  padding: 20px 0;
  background: #f5f5f5;

  .cart-container {
    min-height: 400px;

    & .cart-list {
      & .pro-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;

        & img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100px;
        }
      }

      & .price {
        color: #ff3e3e;
        font-weight: bold;
      }

      & .action-list {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;

        & .action {
          display: flex;
          height: 32px;
          align-items: center;

          & .del-cart {
            cursor: pointer;
            margin-left: 30px;
            color: #ff760e;
            border: 1px solid #ff760e;
            padding: 5px 15px;
            border-radius: 5px;
            font-size: 14px;

            .del-icon {
              margin-right: 10px;
            }
          }
        }

        & .count {
          display: flex;
          align-content: center;

          & p {
            margin-left: 20px;
            text-align: right;

            & span {
              color: #ff3e3e;
              font-size: 18px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .submit-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px 3px #ddd;
    border-radius: 4px;

    button {
      width: 200px;
      height: 100%;
      font-size: 18px;
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 20px;

      span {
        color: #ff3e3e;
        font-size: 24px;
        margin-left: 5px;
      }
    }
  }
}
</style>