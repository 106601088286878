import { SET_USERINFO, DEL_USERINFO, INSERT_CART } from '../mutation-types'
import { insertAddCart } from '@/api/statistics'
const user = {
  namespaced: true,
  state: {
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    token: localStorage.getItem('Token') || '',
    // languageInfo: '' // 
  },
  mutations: {

    [SET_USERINFO] (state, data) {
      state.token = data.token
      localStorage.setItem('Token', data.token)
      state.userInfo = data.member
      localStorage.setItem('userInfo', JSON.stringify(data.member))
    },
    [DEL_USERINFO] (state) {
      state.token = ''
      state.userInfo = ''
      localStorage.removeItem('userInfo')
      localStorage.removeItem('Token')
    },
    [INSERT_CART] (state, val) {
        if (Object.keys(state.userInfo).length === 0) return
			const param = {
				mobile: state.userInfo.mobile,
                client_Na: state.userInfo.client_Na,
                userAccount: state.userInfo.userAccount,
                client_Nu: state.userInfo.client_Nu,
                pageUrl: val.pageUrl,
                pr_Na: val.pr_Na,
                co_Nu: val.co_Nu,
                fa_No: val.fa_No,
                ma_Na: val.ma_Na,
                ma_Nu: val.ma_Nu
			}
			insertAddCart(param)
    },
  },
  actions: {
    set_userInfo ({ commit }, data) {
      commit(SET_USERINFO, data)
    },
    logout ({ commit }) {
      commit(DEL_USERINFO)
    },
    insert_cart ({ commit }, data) {
        commit(INSERT_CART, data)
    },
  },
  getters: {
    get_userInfo: state => {
        return state.userInfo
    }
  }
}

export default user
