<template>
  <div>
    <div class="swiper-box container" :class="getSetting.isFull? 'full': 'container'" v-if="swiperList.length>0">
      <swiper v-if="swiperList" :options="swiperOption">
        <swiper-slide v-for="(item, index) in swiperList" :key="index">
          <img :src="item.imgUrl" :alt="item.name" class="swiper-img" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="new-product" v-if="newProductList.length>0">
      <new-product :datas="newProductList" :defaultActive="newProductList[0].id.toString()"></new-product>
    </div>

    <div class="product-recommend" v-if="recommendList.length>0">
      <recomm-product :datas="recommendList"></recomm-product>
    </div>
    <div class="product-group">
      <group-product :datas="groupList"></group-product>
    </div>
    <div class="video-center">
      <video-center :datas="videoList"></video-center>
    </div>
  </div>
</template>
<script>
import {
  CarouselGetList,
  GetProductListByDate,
  GetProductListByDiscount,
  SiteSettingWebGetSiteList,
  VideoGetList
} from '@/api/home'
import { mapState, mapGetters } from 'vuex'
import newProduct from '@/components/themeTemplateOne/pc_components/productBox/newProduct.vue'
import recommProduct from '@/components/themeTemplateOne/pc_components/productBox/recommProduct.vue'
import groupProduct from '@/components/themeTemplateOne/pc_components/productBox/groupProduct.vue'
import videoCenter from '@/components/themeTemplateOne/pc_components/video/videoCenter'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import transformMark from '@/utils/water'
export default {
  name: 'pageHome',
  components: {
    swiper,
    swiperSlide,
    newProduct,
    recommProduct,
    groupProduct,

    videoCenter
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true //允许分页点击跳转
        }
      },
      swiperList: [],
      newProductList: [],
      recommendList: [],
      groupList: [],
      videoList: []
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters("setting", {
      getSetting: 'get_setting'
    })
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      let _this = this
      // 首页轮播
      CarouselGetList().then(res => {
        if (res.success) {
          _this.swiperList = res.data
        } else {
          _this.$message.error(res.message)
        }
      }).catch(err => {
        _this.$message.error(err)
      })

      const data = {
        client_Nu: _this.userInfo.client_Nu || '',
        number: 4
      }

      // 获取最新产品
      GetProductListByDate(data).then(res => {
        if (res.success) {
          _this.newProductList = res.data || []
          _this.newProductList.forEach(item => {
            transformMark(item.imgUrl).then(result => {
              item.imgUrl = result
            })
          })
        } else {
          _this.$message.error(res.message)
        }
      }).catch(err => {
        _this.$message.error(err)
      })

      // 推荐产品
      GetProductListByDiscount(data).then(res => {
        if (res.success) {
          _this.recommendList = res.data || []
          _this.recommendList.forEach(item => {
            transformMark(item.imgUrl).then(result => {
              item.imgUrl = result
            })
          })
        } else {
          _this.$message.error(res.message)
        }
      }).catch(err => {
        _this.$message.error(err)
      })

      // 分组产品
      SiteSettingWebGetSiteList().then(res => {
        if (res.success) {
          _this.groupList = res.data || []
        } else {
          _this.$message.error(res.message)
        }
      }).catch(err => {
        _this.$message.error(err)
      })

      // 视频
      const model = {
        success: true,
        code: 0,
        message: 'string',
        data: {},
        extras: {},
        timestamp: new Date()
      }
      VideoGetList(model).then(res => {
        if (res.success) {
          _this.videoList = res.data
        } else {
          _this.$message.error(res.message)
        }
      }).catch(err => {
        _this.$message.error(err)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.full {
  width: 100%;
}

.container {
  margin-top: 10px;
}

.swiper-box {
  height: 540px;

  & .swiper-img {
    width: 100%;
    height: 540px;
  }

  & :deep(.swiper-pagination-bullet-active) {
    background-color: #fca81e;
  }
}

.new-product {
  width: 100%;
}

.product-recommend {
  width: 100%;
  padding: 40px 0;
  background-color: #badbec;
}
</style>