<template>
    <!-- 视频中心 -->

    <div class="container product-box" v-if="datas.length >= 3" style="box-sizing: border-box;">
        <!-- 标题 -->
        <h1>{{ $t('videocenter') }}</h1>
        <!-- 视频内容 -->
        <div class="product_video">
            <!-- 左侧视频 -->
            <div class="box_left">
                <div>
                    <div class="video_left">
                        <img :src="datas[0].imgUrl" alt="baby image" />
                        <div class="video_paly">
                            <i class="el-icon-video-play play" @click="playClick(0)"></i>
                        </div>
                        <div class="box_shadow"></div>
                    </div>
                </div>
            </div>
            <!-- 右侧视频 -->
            <div class="video_right" style="box-sizing: border-box;">

                <div class="right_top_box">
                    <div>
                        <div class="r_top">
                            <img :src="datas[1].imgUrl" alt="baby image" />
                            <div class="video_paly">
                                <i class="el-icon-video-play play" @click="playClick(1)"></i>
                            </div>
                            <div class="box_shadow"></div>
                        </div>
                    </div>
                </div>
                <div class="right_top_box">
                    <div>
                        <div class="r_top" style="margin-top:40px;">
                            <img :src="datas[2].imgUrl" alt="baby image" />
                            <div class="video_paly">
                                <i class="el-icon-video-play play" @click="playClick(2)"></i>
                                <p>{{}}</p>
                            </div>
                            <div class="box_shadow"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 精灵图 -->
            <div class="product_imgs">
                <!-- 左侧精灵图 -->
                <div class="imgs_left">
                    <img src="@/assets/kid.png" alt="kid image" class="imgs_l_kid" />
                </div>
                <!-- 右下角精灵图 -->
                <div class="imgs_right">
                    <img src="@/assets/cloudRight.png" alt="cloudRight image" class="imgs_r_cloud" />
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="videoDialog">
            <el-dialog :title="$t('Video_playback')" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
                <span>
                    <video ref="videoPlayer" controls style="width:100%;">
                        <source :src="videoUrl" type="video/mp4" />
                    </video>
                </span>
                <span slot="footer" class="dialog-footer"></span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name: 'videoCenter',
    props: {
        datas: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dialogVisible: false,
            videoUrl: '',
        }
    },
    methods: {
        // 关闭视频
        handleClose(done) {
            done()
        },
        // 点击播放icon
        playClick(index) {
            let _this = this
            _this.videoUrl = ''
            _this.videoUrl = _this.datas[index].videoUrl
            _this.dialogVisible = true
        },
    },
}
</script>

<style lang="scss" scoped>
.product-box {

    box-sizing: border-box;

    // 标题
    h1 {
        font-size: 52px;
        margin-top: 70px;
        font-family: Microsoft YaHei;
        color: #ffffff;
        text-shadow: 0px 5px 5px rgba(29, 147, 131, 0.75);
        text-align: center;
    }

    // 视频内容
    .product_video {
        position: relative;
        display: flex;
        height: 700px;

        // 左侧视频
        .video_left {
            position: relative;
            flex: 1;
            height: 700px;
            width: 860px;
            overflow: hidden;
            margin-right: 40px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }

            .box_shadow {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.6);
                border-radius: 10px;
            }
        }

        .video_paly {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                transform: translate(-50%, -50%);

                .play {
                    font-size: 70px;
                    color: rgba(255, 255, 255);
                    cursor: pointer;
                }
            }

        // 右侧视频
        .video_right {
            display: flex;
            flex-direction: column;
            width: 500px;
            height: 700px;

            .r_top {
                position: relative;
                height: 330px;
                // width: 540px;
                // margin-top: -55px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }

               & .box_shadow {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(0, 0, 0, 0.6);
                    border-radius: 10px;
                }
            }

            .r_bottom {
                // flex布局 使其底下两视频并列排
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;

                img {
                    max-width: 100%;
                }

                // 右侧底部左一视频
                .b_left {
                    width: 100%;
                    margin-right: 20px;
                    position: relative;

                    .img_box {
                        height: 100%;

                        img {
                            width: 100%;
                            height: 330px;
                        }
                    }

                    .video_paly {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .play {
                            font-size: 70px;
                            color: rgba(255, 255, 255);
                            cursor: pointer;
                        }
                    }
                }

                // 右侧底部左二视频
                .b_right {
                    width: 100%;
                    position: relative;

                    .img_box {
                        height: 100%;

                        img {
                            width: 100%;
                            height: 330px;
                        }
                    }

                    .video_paly {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .play {
                            font-size: 70px;
                            color: rgba(255, 255, 255);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    // 精灵图
    .product_imgs {

        // 左上角精灵图
        .imgs_left {
            width: 350px;
            height: 350px;
            position: absolute;
            left: -340px;
            top: -266px;

            .imgs_l_kid {
                max-width: 100%;
                max-height: 100%;
            }
        }

        // 右下角精灵图
        .imgs_right {
            width: 197px;
            height: 130px;
            position: absolute;
            right: -220px;
            top: 508px;

            .imgs_r_cloud {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
</style>
