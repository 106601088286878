<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-03-21 12:20:09
-->
<template>
    <div class="cart-box">
        <page-cart-one v-if="cutTemplates === 'themeTemplateOne'"></page-cart-one>
        <page-cart-tow v-if="cutTemplates === 'themeTemplateTow'"></page-cart-tow>
        <page-cart-three v-if="cutTemplates === 'themeTemplateThree'"></page-cart-three>
    </div>
</template>

<script>
// import pageCart from '@/components/page/cart/index'
import mixins from '@/utils/mixins';
export default {
    name: 'cartPage',
    mixins: [mixins],
    components: {
        // pageCart
    }
}
</script>
<style lang="scss" scoped>
.cart-box {
    width: 100%;
    // background: #f1f3f6;
}
</style>