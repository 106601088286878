<!--
 * @Description: 
 * @Author: zml
 * @Date: 2023-03-21 11:49:51
 * @LastEditors: zml
 * @LastEditTime: 2023-03-23 17:12:16
-->
<template>
  <div class="header-box">
    <div class="header-top">
      <header-top></header-top>
    </div>
    <logo-box></logo-box>
    <nav-bar></nav-bar>
  </div>
</template>

<script>
import headerTop from '@/components/themeTemplateThree/pc_components/headerTop/index.vue'
import logoBox from '@/components/themeTemplateThree/pc_components/logoBox/index'
import navBar from '@/components/themeTemplateThree/pc_components/navBar/index'
export default {
  components: {
    headerTop,
    logoBox,
    navBar
  }
}
</script>
<style lang="scss" scoped>
.header-box {
  & .header-top {
    margin-bottom: 33px;
  }
}
</style>