<template>
    <div>
      <el-dialog
      :title="$t('searchPicture')"
      width="35%"
      :visible.sync="visible">
      <div class="uploadWay">
          <el-upload drag :before-upload="beforeUpload" action="http://192.168.1.83" :show-file-list="false">
            <div class="way box_1">
              <div>
                <el-button icon="upload" type="primary">{{ $t('choosePicture') }}</el-button>
                <div class="way1_tips">{{ $t('clickOrDrag') }}</div>
              </div>
            </div>
          </el-upload>
          <div class="way box_2" ref="imageUploadDiv" @paste="handlePaste($event)">
            <div class="way2_tips" v-if="copyImg == null">
              <input @pause="handlePaste" class="imageInput" type="text" />{{ $t('copyPicture') }}
            </div>
            <img v-else :src="copyImg" alt="" srcset="" class="imgs" />
          </div>
        </div>
        <div class="cropper_box">
          <div class="cropper" v-if="options.img !== ''">
            <vue-cropper
              ref="cropper"
              id="pasteImg"
              :img="options.img"
              :info="options.info"
              :outputSize="options.outputSize"
              :outputType="options.outputType"
              :canMove="options.canMove"
              :autoCrop="options.autoCrop"
              :full="options.full"
              :autoCropWidth="options.autoCropWidth"
              :autoCropHeight="options.autoCropHeight"
              :fixedBox="options.fixedBox"
              :original="options.original"
              :infoTrue="options.infoTrue"
              :centerBox="options.centerBox"
              :canMoveBox="options.canMoveBox"
              :canScale="options.canScale"
              :fixed="options.fixed"
              :fixedNumber="options.fixedNumber"
              :enlarge="options.enlarge"
              @realTime="realTime"
            >
            </vue-cropper>
          </div>
          <div class="cropper preview_box" v-else>
            <div>{{ $t('pictureCrop') }}</div>
          </div>
        </div>
        <div class="actionList">
          <el-button @click="deleteImg">{{ $t('delete') }}{{ $t('picture') }}</el-button>
          <el-button icon="el-icon-plus" @click="changeScale(1)"></el-button>
          <el-button icon="el-icon-minus" @click="changeScale(-1)"></el-button>
          <el-button icon="el-icon-refresh-left" @click="rotateLeft"></el-button>
          <el-button icon="el-icon-refresh-left" @click="rotateRight"></el-button>
        </div>
        <template slot="footer">
          <el-button key="back" @click="cancelHandel">
              {{ $t('cancel') }}
          </el-button>
          <el-button key="submit" type="primary" :loading="uploading" @click="finish('blob')">
              {{ $t('confirm') }}
          </el-button>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { searchPicture } from "@/api/product";
  const uidGenerator = () => {
    return '-' + parseInt(Math.random() * 10000 + 1, 10)
  }
  
  export default {
    data() {
      return {
        visible: false,
        id: null,
        confirmLoading: false,
        fileList: [],
        uploading: false,
        copyImg: null,
        options: {
          img: '',
          outputSize: 1, // 裁剪生成图片的质量 0.1 - 1
          outputType: 'png', //	裁剪生成图片的格式 jpeg || png || webp
          canScale: true, // 图片是否允许滚轮缩放 默认true
          autoCrop: true, // 是否默认生成截图框 默认false
          canMove: true, // 上传图片是否可以移动 默认true
          autoCropWidth: 200, // 默认生成截图框宽度	容器的80%	0~max
          autoCropHeight: 200, // 默认生成截图框高度	容器的80%	0~max
          fixedBox: false, // 固定截图框大小 不允许改变	false	true | false
          fixed: false, // 是否开启截图框宽高固定比例
          full: false,
          original: false, // 上传图片按照原始比例渲染	false	true | false
          infoTrue: false, // 为展示真实输出图片宽高 false 展示看到的截图框宽高	false	true | false
          centerBox: true, // 截图框是否被限制在图片里面	false	true | false
          canMoveBox: true, // 截图框能否拖动	true	true | false
          fixedNumber: [1, 1], // 截图框的宽高比例 [宽度, 高度]
          enlarge: 1
        },
        previews: {},
        previewStyle1: {},
        copyImgStatues: false
      }
    },
    props: {
      imgUrl: {
        type: String,
        default: () => ''
      },
      client_Nu: {
          type: String,
          default: () => ''
      }
    },
    methods: {
      openDialog() {
        this.visible = true
      },
      deleteImg() {
        this.options.img = ''
        this.copyImg = null
      },
      /** 点击按钮粘贴 */
      copyClick() {
      },
      /**
       * 在指定区域粘贴或者ctrl+v
       */
      handlePaste(event) {
        const items = event.clipboardData && event.clipboardData.items
  
        let file = null
        if (items && items.length) {
          for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
              file = items[i].getAsFile()
            }
          }
        }
        if (!file) {
          this.$message.error('粘贴内容不是图片')
          return
        }
        this.copyImgStatues = true
        file.uid = uidGenerator()
        this.beforeUpload(file)
      },
      cancelHandel() {
        this.id = null
        this.visible = false
      },
      changeScale(num) {
        num = num || 1
        this.$refs.cropper.changeScale(num)
      },
      rotateLeft() {
        this.$refs.cropper.rotateLeft()
      },
      rotateRight() {
        this.$refs.cropper.rotateRight()
      },
      beforeUpload(file) {
        this.fileList = file
        const reader = new FileReader()
        // 把Array Buffer转化为blob 如果是base64不需要
        // 转化为base64
        reader.readAsDataURL(file)
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          this.options.img = reader.result
          this.copyImg = null
          if (this.copyImgStatues) {
            this.copyImg = reader.result
          }
          img.onload = () => {
            this.options.autoCropWidth = img.width
            this.options.autoCropHeight = img.height
            this.copyImgStatues = false
          }
        }
        return false
      },
      // 上传图片（点击上传按钮）
      finish(type) {
        if (!this.options.img) return
        if (type === 'blob') {
          this.uploading = true
          this.$refs.cropper.getCropBlob(data => {
            if (!data) return
            const files = new window.File([data], this.fileList.name, { type: this.fileList.type })
            const formData = new FormData()
            formData.append('file', files)
            formData.append('client_Nu', this.client_Nu)
            searchPicture(formData)
              .then(res => {
                if (res.success) {
                  if (res.data.length < 1) {
                    this.uploading = false
                    this.$message.error('暂未匹配到数据')
                  } else {
                    this.$emit('ok', res)
                    this.visible = false
                    this.uploading = false
                  }
                } else {
                  this.uploading = false
                  this.$message.error(res.message)
                }
              })
              .catch(err => {
                this.$message.error(err.message)
                this.visible = false
              })
          })
        } else {
          this.$refs.cropper.getCropData(data => {console.log(data);})
        }
      },
  
      realTime(data) {
        this.previews = data
        this.previewStyle1 = {
          width: data.w + 'px',
          height: data.h + 'px',
          overflow: 'hidden',
          margin: '0',
          zoom: 1
        }
      }
    }
  }
  </script>
  
  <style lang="scss">
  .avatar-upload-preview {
        margin-top: 20px;
        display: flex;
        img {
          width: auto !important;
          height: auto !important;
          max-width: 300px;
          max-height: 224px;
          transform: scale(1) translate3d(0, 0, 0) !important;
        }
      }
      .dom_copy {
        width: 100px;
        height: 50px;
        border: 1px black solid;
      }
      
      .uploadWay {
        display: flex;
        align-content: center;
        justify-content: space-between;
        height: 150px;
        width: 100%;
        margin-bottom: 10px;
      
        .way {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f8f8f9;
          border-radius: 4px;
          width: 270px;
          height: 126px;
          padding: 10px;
      
          .way1_tips {
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            color: #666666;
          }
      
          .way2_tips {
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            color: #999999;
      
            .imageInput {
              position: absolute;
              opacity: 0;
              outline: none;
              border: 0;
              cursor: pointer;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
          }
      
          &:hover {
            border: 1px dashed #1890ff;
          }
        }
      
        .box_2 {
          position: relative;
          .imgs {
            max-width: 250px;
            max-height: 106px;
            width: auto;
            height: auto;
          }
        }
      }
      
      .cropper_box {
        width: 100%;
        height: 350px;
        border: 2px solid #dcdcdc;
        border-radius: 4px;
        margin-bottom: 10px;
      
        .cropper {
          width: 100%;
          height: 350px;
        }
      
        .preview_box {
          display: flex;
          align-items: center;
          justify-content: center;
      
          div {
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      
      .actionList {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      
        button {
          margin-right: 10px;
        }
      }
      .el-upload-dragger {
          width: 100% !important;
          height: 100% !important;
      }
  
      .el-dialog__footer {
          padding: 10px !important;
      }
  
      .el-dialog__body {
          padding: 20px !important;
      }
  </style>