<template>
  <div class="big-box">
    <div class="container">
      <classify-search
        :datas="getMenus"
        :active="queryParam.cl_Nu"
        :productCount="productCount"
        class="classify-box"
        @classifyClick="classifyClick"
      ></classify-search>
      <div class="newProduct">
        <div class="date-title">{{$t('DK.LastSevenDays')}}</div>
        <div :class="`all ${activeIndex ==='all' ? 'active': ''}`" @click="onAll">{{$t('DK.All')}}</div>
        <div
          :class="`date ${activeIndex == index ? 'active': ''}`"
          v-for="(item,index) in daytimeArr"
          :key="index"
          @click="onDate(item,index)"
        >{{item}}</div>
      </div>
    </div>
    <div class="container">
      <div v-if="productList.length > 0" class="pitch-title">
        <span class="title">{{$hs(classifyItem.cl_Na,classifyItem.cl_Ne_Na,classifyItem.threeLanguage)||$t('all') }}</span>
        <span>/{{$t('DK.amount')}}：{{totalRows}}</span>
      </div>
      <pro-list :productList="productList" v-if="productList.length > 0"></pro-list>
      <el-empty :description="$t('noData')" v-else class="empty"></el-empty>
    </div>
    <div class="action-box container">
      <div style="display:flex;align-items:center">
        <div class="check-all">
          <el-checkbox v-model="checkAll" @change="selectAll">{{ $t('checkAll') }}</el-checkbox>
        </div>
        <div class="add-cart" @click="addSelectdToCart">
          <i class="el-icon-shopping-cart-2 selectionCart" :style="{ fontSize: '14px' }"></i>
          {{ $t('checkSelect') }}
        </div>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParam.pageNo"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRows"
      ></el-pagination>
    </div>
    <!-- <div class="container pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParam.pageNo"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParam.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRows"
      ></el-pagination>
    </div>-->
    <div class="container">
      <advanced-search ref="advanced" @search="advSearch"></advanced-search>
    </div>
    <!-- <search-pic ref="searchPic" :client_Nu="userInfo.Client_Nu" @ok="tableLoadData"></search-pic> -->
  </div>
</template>
<script>
// import searchPic from '@/components/themeTemplateTow/pc_components/searchPic/searchPic.vue'
import { queryPage, getProductCount, getDateList } from '@/api/product'
import { mapGetters, mapActions } from 'vuex'
import bus from '@/utils/bus'
import { insertSearch } from '@/api/statistics'
import advancedSearch from '@/components/themeTemplateThree/pc_components/searchBox/advancedSearch.vue'
import classifySearch from '@/components/themeTemplateThree/pc_components/searchBox/classifySearch.vue'
import proList from '@/components/themeTemplateThree/pc_components/productBox/proList.vue'
import { batchAddApi } from '@/api/cart'
import transformMark from '@/utils/water'
export default {
  name: 'pageProduct',
  components: {
    advancedSearch,
    classifySearch,
    proList
    // searchPic
  },
  data() {
    return {
      productCount: 0,
      productList: [],
      queryParam: {
        pageNo: 1,
        pageSize: 20,
        sortField: '',
        sortOrder: '',
        cl_Nu: '',
        isAnd: false,
        conditionals: [],
        startTime: null
      },
      totalRows: 0,
      totalPage: 0,
      classifyItem: {},
      daytimeArr: [],
      activeIndex: 'all'
    }
  },
  computed: {
    ...mapGetters('setting', {
      getMenus: 'get_menus'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    ...mapGetters('operate', {
      getEnquiry: 'get_enquiry'
    }),
    // 全选、小选
    checkAll: {
      set(state) {
        this.productList.forEach(item => (item.isCheck = state))
      },
      get() {
        return this.productList.every(item => item.isCheck == true)
      }
    }
  },

  watch: {
    $route(to) {
      if (to.name==='product') {
        this.activeIndex = Number(this.$route.query.ind) || 'all'
        this.queryParam.startTime = this.daytimeArr[this.$route.query.ind] || null
      }
    }
  },
  created() {
    // 路由传参
    this.queryParam.cl_Nu = this.$route.query.clNu || ''
    const keywrods = this.$route.query.keywords || ''
    if (keywrods !== '') {
      this.queryParam.conditionals = this.buildConditionals(keywrods)
    }
    this.getDate()
    this.initData()
    this.getProductCount()
  },
  mounted() {
    this.$bus.$on('anewClick', () => {
      this.queryParam.cl_Nu = ''
      this.initData()
    })
    // Event Bus分类点击事件
    this.$bus.$on('categoryClick', cl_Nu => {
      this.queryParam.cl_Nu = cl_Nu
      this.initData()
    })

    // Event Bus搜索事件
    this.$bus.$on('searchProduct', keywords => {
      this.queryParam.conditionals = this.buildConditionals(keywords)
      this.initData()
    })

    // 图搜
    bus.$on('imgSearch', res => {
      if (res) {
        this.isImg = res
        this.imgsSearch()
      }
    })

    this.getActivePageNo()
  },
  destroyed() {
    sessionStorage.removeItem('detail')
  },
  methods: {
    ...mapActions('operate', {
      batchAddCart: 'batch_add_cart',
      setEnquiry: 'set_enquiry'
    }),

    // getDate() {
    //   var date = new Date()
    //   var base = Date.parse(date) // 转换为时间戳
    //   var year = date.getFullYear() //获取当前年份
    //   var mon = date.getMonth() + 1 //获取当前月份
    //   var day = date.getDate() //获取当前日
    //   var oneDay = 24 * 3600 * 1000
    //   //var daytime = `${year}${mon >= 10 ? mon : '0' + mon}${day >= 10 ? day : '0' + day}`; //今日时间
    //   //this.$data.daytime = daytime; // 今日时间赋值给变量

    //   var daytimeArr = []
    //   for (var i = 0; i < 7; i++) {
    //     //前七天的时间
    //     var now = new Date((base -= oneDay))
    //     var myear = now.getFullYear()
    //     var month = now.getMonth() + 1
    //     var mday = now.getDate() + 1
    //     daytimeArr.push(
    //       [
    //         myear,
    //         month >= 10 ? month : '0' + month,
    //         mday >= 10 ? mday : '0' + mday
    //       ].join('-')
    //     )
    //   }
    //   this.daytimeArr = daytimeArr
    // },

    onAll() {
      this.activeIndex = 'all'
      this.queryParam.startTime = null
      this.initData()
    },

    onDate(date, index) {
      this.activeIndex = index
      this.queryParam.startTime = date
      this.initData()
    },

    getDate() {
      getDateList({ num: 7 }).then(res => {
        this.daytimeArr = res.data
      })
    },
    // 初始化数据
    initData() {
      // 带上客户编号
      this.queryParam.pageNo = Number(sessionStorage.getItem('activePageNo'))
      this.queryParam.client_Nu = this.userInfo.client_Nu || ''
      queryPage(this.queryParam).then(res => {
        if (res.success) {
          this.productList = res.data.rows || []
          this.productList = this.productList.map(item => {
            const ind = this.getEnquiry[0].list.findIndex(
              it => it.co_Nu === item.co_Nu
            )
            if (ind > -1) {
              return { ...item, _num_: true }
            }
            return { ...item, _num_: false }
          })

          this.productList.forEach(item => {
            transformMark(item.imgUrl).then(result => {
              item.imgUrl = result
            })
          })

          this.totalRows = res.data.totalRows
          this.totalPage = res.data.totalPage
        }
      })
    },

    getProductCount() {
      getProductCount({ client_Nu: this.userInfo.client_Nu || '' }).then(
        res => {
          this.productCount = res.data
        }
      )
    },
    // 图搜
    imgsSearch() {
      let _this = this
      this.$nextTick(() => {
        _this.$refs.searchPic.openDialog()
      })
    },
    // 统计搜索埋点
    insertKeywords(keywords) {
      if (Object.keys(this.userInfo).length === 0) return
      if (keywords === '') return
      const param = {
        mobile: this.userInfo.mobile,
        client_Na: this.userInfo.client_Na,
        userAccount: this.userInfo.userAccount,
        client_Nu: this.userInfo.client_Nu,
        keywords
      }
      insertSearch(param)
    },
    //
    tableLoadData(res) {
      this.productList = res.data
      this.totalRows = res.data.length
      this.totalPage = 1
      this.insertKeywords('图搜')
    },

    // 构建复杂查询条件
    buildConditionals(keywords) {
      const conditionals = []
      const fields = ['co_Nu', 'fa_No', 'pr_Na', 'cl_Na', 'pr_En_Na']
      fields.forEach(item => {
        conditionals.push({
          conditionalType: 1,
          fieldName: item,
          fieldValue: keywords
        })
      })
      return conditionals
    },
    // 高级查询
    advSearch(conditionals) {
      // 如果存在其他的复杂查询条件，则合并条件
      this.queryParam.cl_Nu = ''
      this.queryParam.conditionals = []
      this.queryParam.conditionals = conditionals
      this.pageNo = 1
      this.initData()
    },
    // 分页大小操作
    handleSizeChange(pageSize) {
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.initData()
    },
    getActivePageNo() {
      let reg = sessionStorage.getItem('detail')
      if (reg) {
        // 从商品详情回来
        let activePageNo = sessionStorage.getItem('activePageNo')
        this.queryParam.pageNo = Number(activePageNo)
      } else {
        // 从其他页面第一次进入列表页，清掉缓存里面的数据
        this.queryParam.pageNo = 1
        sessionStorage.removeItem('activePageNo')
      }
    },
    // 分页翻页操作
    handleCurrentChange(pageNo) {
      sessionStorage.setItem('activePageNo', pageNo)
      this.queryParam.pageNo = pageNo
      this.initData()
    },
    // 分类模块点击操作
    classifyClick(item) {
      this.queryParam.conditionals = []
      this.queryParam.cl_Nu = item.cl_Nu
      this.classifyItem = item
      this.pageNo = 1
      this.$refs.advanced.reset()
      this.initData()
    },
    // 添加选中的产品到购物车
    addSelectdToCart() {
      if (!this.userInfo.id) {
        // this.$message({
        //   message: this.$t('noLogin'),
        //   type: 'warning'
        // })
        const seletedProducts = this.productList.filter(item => item.isCheck)
        const filterData = seletedProducts.filter(item => !item._num_)

        for (let i = 0; i < filterData.length; i++) {
          filterData[i]._num_ = !filterData[i]._num_
          this.setEnquiry(filterData[i])
        }

        return
      }
      // 必须登陆后才能添加购物车
      // 找出被选中的记录
      const seletedProducts = this.productList.filter(
        item => item.isCheck === true
      )
      let newArr = []
      const proLists = []
      // 判断当前页是否选中过产品
      if (seletedProducts.length > 0) {
        seletedProducts.forEach(item => {
          if (item.co_Nu) {
            newArr.push({
              co_Nu: item.co_Nu,
              number: 1
            })
            proLists.push({
              client_Nu: this.userInfo.client_Nu,
              userId: this.userInfo.id,
              co_Nu: item.co_Nu,
              fa_No: item.fa_No,
              imgUrl: item.imgUrl,
              in_En: item.in_En,
              number: 1,
              ou_Lo: item.ou_Lo,
              pr_En_Na: item.pr_En_Na,
              pr_Na: item.pr_Na,
              price: item.price
            })
          }
        })
        if (this.userInfo) {
          batchAddApi({
            userId: this.userInfo.id,
            client_Nu: this.userInfo.client_Nu,
            cartItem: newArr
          }).then(res => {
            if (res.success) {
              this.$message({
                message: this.$t('success'),
                type: 'success'
              })
              this.batchAddCart(proLists)
            }
          })
        }
      } else {
        this.$message({
          message: this.$t('noSelect'),
          type: 'warning'
        })
      }
    },
    // 选中当前页全部产品
    selectAll() {
      this.productList.forEach(item => {
        item.isCheck = this.checkAll
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.newProduct {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 60px;
  background-color: #fff;
  margin: 10px 0 0 0;
  border-radius: 6px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 18px;

  .date-title {
    font-size: 24px;
    width: 140px;
    color: rgb(102, 102, 102);
  }

  .all {
    width: 80px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #4a90f1;
    }
  }

  .date {
    flex: 1;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #4a90f1;
    }
  }
  .active {
    color: #4a90f1;
  }
}
.big-box {
  background: #f5f5f5;
  padding: 10px 0;
}

.classify-box {
  margin-top: 10px;
}

.price-box {
  margin-top: 10px;
}

.product-list {
  margin-top: 10px;
}

.action-box {
  background-color: #fff;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  & .check-all {
    padding: 0 15px;
  }

  & .add-cart {
    margin-left: 15px;
    color: #ff4d4d;
    border: 1px solid #ff4d4d;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;

    & .selectionCart {
      padding-right: 10px;
    }
  }
}

.empty {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 5px;
}
.pagination {
  border-radius: 5px;
  margin-top: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background: #fff;
}
.pitch-title {
  padding: 20px 0 10px 20px;
  color: #666666;
  .title {
    font-size: 20px;
  }
}
</style>