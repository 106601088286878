<template>
  <div class="header-top">
    <div class="container header-container">
      <div class="left"></div>
      <div class="right">
        <div class="btn" v-if="!userInfo.id" @click="login">{{$t("login")}}/{{ $t("registration") }}</div>
        <div v-else>
          <el-dropdown class="btn" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ userInfo.userName || userInfo.userAccount }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="1">{{$t('userAddress')}}</el-dropdown-item> -->
              <el-dropdown-item command="2">{{$t('logout')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="icon"></div>
        <div class="btn" @click="linkOrder">{{ $t("order") }}</div>
        <div class="icon"></div>
        <div class="btn" @click="$router.push({path:'/cart'})">{{!userInfo.id ? $t('DK.inquirySheet') : $t('cart')}}</div>
      </div>
    </div>
    <login-modal ref="loginModal"></login-modal>
  </div>
</template>
<script>
import loginModal from '@/components/themeTemplateThree/pc_components/memberModal/loginModal.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'headerTop',
  components: {
    loginModal
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('user', ['userInfo'])
  },
  methods: {
    ...mapActions('user', ['logout']),

    // 登录
    login() {
      // 打开登录模块
      this.$refs.loginModal.open()
    },
    // 跳转订单
    linkOrder() {
      if (Object.keys(this.userInfo).length > 0) {
        this.$router.push({ path: '/order' })
      } else {
        this.$refs.loginModal.open()
      }
    },

    handleCommand(command) {
      if (command === '1') {
        this.$router.push({ path: '/address' })
      } else {
        this.logout()
        this.$router.push({ path: '/home' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-menu-item {
  height: 32px !important;
  line-height: 32px !important;
  font-size: 12px;
  color: #303133 !important;
}
.header-top {
  width: 100%;
  height: 36px;
  line-height: 36px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #222222;
  .header-container {
    height: 36px;
    line-height: 36px;
    display: flex;
    justify-content: space-between;
    color: #cccccc;
    font-size: 14px;
    .right {
      flex: 1;
      display: flex;
      justify-content: right;
      align-items: center;
      .icon {
        width: 2px;
        height: 18px;
        background-color: #cccccc;
        border-radius: 2px;
      }
      .btn {
        padding: 0 18px;
        cursor: pointer;
        &:hover {
          color: #fbc21c;
        }
        .el-dropdown-link {
          color: #cccccc;
        }
      }
    }
  }
}
</style>