<!--
 * @Description: 
 * @Author: zml
 * @Date: 2023-03-21 11:49:51
 * @LastEditors: zml
 * @LastEditTime: 2023-03-30 16:48:26
-->
<template>
  <el-dialog @close="close" :visible="visible" :close-on-click-modal="false" width="540px" center append-to-body>
    <el-tabs v-model="activeName" @tab-click="onChangeActiveName">
      <el-tab-pane :label="$t('login')" name="login">
        <el-form :model="form" ref="form" label-position="right" label-width="170px" :rules="rules">
          <el-form-item prop="email" :label="$t('email') + ':'">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item prop="valideCode" :label="$t('vaildecode') + ':'" class="valide-code">
            <el-input v-model="form.valideCode"></el-input>
            <img v-if="JSON.stringify(codeData) !== '{}'" @click="getCode" :src="codeData.valideCode" title="看不清楚？换一张" />
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('registration')" name="signIn">
        <el-form :model="formData" ref="formData" label-position="right" label-width="170px" :rules="rulesData">
          <el-form-item prop="userAccount" :label="$t('useraccount') + ':'">
            <el-input v-model="formData.userAccount"></el-input>
          </el-form-item>
          <el-form-item :label="$t('gender') + ':'" prop="gender">
            <el-radio v-model="formData.gender" label="1">{{$t('man')}}</el-radio>
            <el-radio v-model="formData.gender" label="2">{{$t('woman')}}</el-radio>
          </el-form-item>
          <el-form-item :label="$t('mobile') + ':'" prop="mobile">
            <el-input v-model="formData.mobile"></el-input>
          </el-form-item>
          <el-form-item :label="$t('email') + ':'" prop="email" required>
            <el-input v-model="formData.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('address') + ':'" prop="address">
            <el-input v-model="formData.address"></el-input>
          </el-form-item>
          <el-form-item :label="$t('vaildecode') + ':'" class="valide-code" prop="valideCode">
            <el-input v-model="formData.valideCode"></el-input>
            <img v-if="JSON.stringify(codeData) !== '{}'" @click="getCode" :src="codeData.valideCode" title="看不清楚？换一张" />
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit" class="btn">{{ activeName==='login' ? $t('login') : $t('registration') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GetValideCode, LoginMemberByEmail, RegisterByEmail } from '@/api/login'
export default {
  name: 'loginModal',
  data() {
    // 检验邮件
    let regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
    let checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('inputEmail')))
      }
      if (regEmail.test(value)) {
        // 合法邮箱
        callback()
      } else {
        // 错误邮箱
        callback(new Error(this.$t('inputEmailRules')))
      }
    }
    return {
      visible: false,
      activeName: 'login',
      codeData: {},
      form: {
        email: '',
        valideCode: ''
      },
      rules: {
        email: [{ required: true, trigger: 'blur', validator: checkEmail }],
        valideCode: [
          {
            required: true,
            message: this.$t('inputVaildeCode'),
            trigger: 'blur'
          }
        ]
      },

      formData: {
        userAccount: '',
        mobile: '',
        valideCode: '',
        email: '',
        address: '',
        userName: '',
        gender: '1'
      },
      rulesData: {
        userName: [
          { required: true, message: this.$t('inputUserName'), trigger: 'blur' }
        ],
        email: [{ trigger: 'blur', validator: checkEmail }],
        mobile: [
          { required: true, message: this.$t('inputMobile'), trigger: 'blur' }
        ],
        userAccount: [
          {
            required: true,
            message: this.$t('inputUserAccount'),
            trigger: 'blur'
          }
        ],
        valideCode: [
          {
            required: true,
            message: this.$t('inputVaildeCode'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('operate', ['language']),
    ...mapState('user', ['userInfo'])
  },
  methods: {
    ...mapActions('user', ['set_userInfo']),
    open() {
      this.visible = true
      this.activeName = 'login'
      this.getCode()
    },
    close() {
      this.visible = false
      this.$refs.form.resetFields()
      this.$refs.formData.resetFields()
    },

    onChangeActiveName(e) {
      this.getCode()
      if (e.name === 'login') {
        this.$refs.formData.resetFields()
      } else {
        this.$refs.form.resetFields()
      }
    },
    // 提交
    submit() {
      if (this.activeName === 'login') {
        this.loginFun()
      } else {
        this.signInFun()
      }
    },

    // 登录
    loginFun() {
      let _this = this
      this.$refs.form.validate(values => {
        if (!values) return false
        LoginMemberByEmail({ ...this.form, keyCode: this.codeData.keyCode })
          .then(res => {
            if (res.success) {
              _this.set_userInfo(res.data)
              _this.$message.success(_this.$t('loginSuccess'))
              _this.close()
              _this.$router.push('/home')
              window.location.reload() // 强刷界面
            }
          })
          .catch(err => {
            if (err.includes('D1632') || err.includes('D1631')) {
              _this.$message.error(_this.$t('CodeError'))
            } else if (err.includes('D1629')) {
              _this.$message.error(_this.$t('notreviewed'))
            } else if (err.includes('D1628')) {
              _this.$message.error(_this.$t('loginError'))
            }
            _this.getCode()
          })
      })
    },

    // 注册
    signInFun() {
      let _this = this
      _this.$refs.formData.validate(values => {
        if (!values) return false

        // 注册
        RegisterByEmail({ ..._this.formData, keyCode: _this.codeData.keyCode })
          .then(() => {
            _this.$message.success(_this.$t('regSuccess'))
            _this.close()
          })
          .catch(err => {
            if (err.includes('D1632') || err.includes('D1631')) {
              _this.$message.error(_this.$t('CodeError'))
            } else if (err.includes('D1630')) {
              _this.$message.error(_this.$t('resUserError'))
            }
            
            _this.getCode()
          })
      })
    },
    // 获取图形验证码
    getCode() {
      GetValideCode()
        .then(res => {
          this.codeData = res.data
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-dialog__header) {
  text-align: left;
  padding: 0px;
  .el-dialog__headerbtn {
    z-index: 99;
  }
}

:deep .el-form {
  padding: 0 40px 0 0;
  .valide-code {
    .el-input {
      margin: 0 10px 0 0;
    }
    .el-form-item__content {
      display: flex;
    }
  }
}

.dialog-footer {
  display: flex;
  padding: 0 50px;
  box-sizing: border-box;
  .btn {
    flex: 1;
    margin-bottom: 20px;
  }
}
</style>