export default {
    login: 'Login',
    registration: 'Registration',
    order: 'My Order',
    language: 'English',
    home: 'Home',
    babyToys: 'Keywords',
    aboutus: 'About Us',
    product: 'Product',
    cart: 'Cart',
    contactus: 'Contact Us',
    comprehensiveInquiry: 'Comprehensive Inquiry',
    advancedSearch: 'Advanced Search',
    productName: 'Product Name',
    priceRange: 'Price Range',
    reset: 'Reset',
    search: 'Search',
    classify: 'Classify',
    close: 'Close',
    open: 'Open',
    updateTime: 'Update Time',
    startTime: 'Start Date',
    endTime: 'End Date',
    confirm: 'Confirm',
    minimumPrice: 'Minimum Price',
    highestPrice: 'Highest Price',
    L: 'L',
    W: 'W',
    H: 'H',
    inputPlaceholder: 'Please Input The Content',
    selectPlaceholder: 'Please Select',
    packingCapacity: 'Packing Capacity',
    price: 'Price',
    itemNo: 'Item No',
    packing: 'Packing',
    categories: 'Categories',
    productSize: 'Product Size',
    packageSize: 'Package Size',
    cartonSize: 'Carton Size',
    innerBoxAndOuterPacking: 'Inner Box/Outer Packing',
    OuterPacking: "Outer Packing",
    CBMAndCUFT: 'CBM/CUFT',
    CUFT:'CUFT',
    CBM: 'CBM',
    GWAndNW: 'G.W/N.W',
    mOQ: 'MOQ',
    productInformation: 'Product Information',
    relatedProducts: 'Related Products',
    inputPassword: 'Please Input The Password',
    passwordLength: 'Length cannot be less than 6 digits',
    inputUserName: 'Please Input The User Name',
    inputUserAccount: 'Please Input The User Account',
    inputVaildeCode: 'Please Input The Vailde Code',
    inputMobile: 'Please Input The Mobile',
    inputEmail: 'Please Input The Email',
    inputEmailRules: 'The email is not formatted correctly',
    password: 'Password',
    username: 'User Name',
    vaildecode: 'Vailde Code',
    more: 'More',
    clear: 'Clear All',
    gender: 'Gender',
    useraccount: 'User Account',
    man: 'Man',
    woman: 'Woman',
    mobile: 'Mobile',
    email: 'Email',
    address: 'Address',
    CodeError: 'VaildeCode error',
    resUserError: 'Account already exists',
    notreviewed: 'Account not verified',
    loginError: 'Username or password entered incorrectly',
    loginSuccess: 'Landed successfully',
    regSuccess: 'registration success',
    deleteSuccess: 'Delete the success',
    toregister: "Don't have an account yet? to register",
    toLogin: 'Already have an account? go to login',
    logout: 'Logout',
    myShoppingCart: 'My Shopping Cart',
    ID: 'ID',
    picture: 'Picture',
    ItemNO: 'Item NO.',
    name: 'Name',
    total: 'Total',
    delete: 'Delete',
    CTNS: 'CTNS',
    checkAll: 'CheckAll',
    checkSelect: 'Add selected item to shopping cart',
    page: 'page',
    goTO: 'Go to',
    totalRecords: 'Total Records',
    totalCTNS: 'Total CTNS',
    totalQuantity: 'Total Quantity',
    totalPrice: 'Total Price',
    submit: 'Submit',
    orderDetails: 'Order Details',
    OrderInformation: 'Order Information',
    number: 'Number',
    orderTime: 'Order Time',
    linkman: 'Linkman',
    remark: 'Remark',
    viewDetails: 'View Details',
    companyContact: 'Contact',
    companyTel: 'Tel',
    companyFax: 'Fax',
    companyMail: 'Email',
    companyAddress: 'Address',
    productCenter: 'Product Center',
    helpCenter: 'Help Center',
    member: 'Member',
    productdemand: 'Product Demand',
    newproduct: 'New Product',
    registerhelp: 'Register Help',
    orderhelp: 'Order Help',
    newproducts: 'New Products',
    highRec: 'Highly Recommended',
    slogan:
        '2023 products with the largest sales volume, the fastest sales and the best feedback',
    viewmore: 'View More',
    videocenter: 'Video Center', 
    videoSlogan: 'Come to us to learn more about the toy industry',
    searchPicture: 'search Picture',
    choosePicture: 'Choose Picture',
    clickOrDrag: 'Click Or Drag',
    copyPicture: 'Copy Picture: Right click Paste/Ctrl+V',
    pictureCrop: 'Picture Crop',
    cancel: 'Cancel',
    confirm: 'Confirm',
    pleaseLogin: 'Please Login',
    addCart: "Add To Cart",
    delCart: "Cancel Purchase",
    viewPrice: 'View Price',
    noData: 'Empty',
    systemTip: 'Tip',
    deleteData: 'Do you want to delete the data?',
    clearCartData: 'Do you want to empty your shopping cart?',
    submitTip: 'Confirm submission of order',
    orderSuccess: 'Order created successfully!',
    noLogin: 'Please login before adding shopping cart',
    notImage: 'Pastes are not pictures',
    noSelect: 'Please select data',
    hotSaleProduct: 'HOT-SALE PRODUCT',
    popularRecommendation: 'Popular recommendation',
    contactUs: 'CONTACT US',
    ock_email: 'EMAIL',
    hotLine: 'HOTLINE',
    companyAddres: 'COMPANY ADDRESS',
    workingHours: 'WORKKING HOURS',
    please_contact_us: 'If you have any questions, please feel free to fax or call.',
    intro: 'The Fourth Construction Co.,Ltd.of China Electronics System Engineering (referred to as CEFOC) was founded in 1953 and belongs to CEC China Electronic Systems Engineering Corporation. It is a comprehensive engineering and technical service company focusing on engineering consultation, engineering design, engineering contracting and facility management in the fields of electronic information, biopharmaceutical, chemical environmental protection, data center, automobile, new energy and other high-tech manufacturing industries.',
    week: 'From Monday to Firday',
    companyInfo: 'Company intorduction',
    noName: 'No translation',
    serviceHotine: "24/7 service Hotine",
    Disclaimer: "Disclaimer: All photos and products on this website are from the manufacturer, and the copyright belongs to the manufacturer.",
    success: "Successfully added",
    del: 'Successfully delete',
    Technic: 'Technic Sustain',
    Software: 'HongSheng Soft',
    coNuNum: "Item No",
    all: 'All Classify',
    userAddress: 'Shipping Address',
    consignee: 'Consignee',
    addressDetail: 'Address',
    addressinformation: 'Address Information',
    defaultAddress: 'Default',
    defaultSettings: 'Default Settings',
    edit: 'Edit',
    add: 'Add Address',
    AddinganAddress: 'Adding an Address',
    Edittheaddress: 'Edit the address',
    Ok: 'Save',
    Address_Consignee: 'Please enter the consignee',
    Address_Mobile: 'Please enter your mobile phone number',
    Address_Address: 'Please enter the address',
    orderStatus: 'Order Status',
    sendDate: 'Delivery Date',
    expressNumber: 'Tracking Number',
    unshipped: 'Undelivered',
    PleaseContact: 'Please enter a contact person',
    shipped: 'Shipped',
    Video_playback: 'Video playback',
}   
