<template>
  <div class="footer-box">
    <div class="container footer-container">
      <!-- 图标 -->
      <div class="box">
        <div class="box-iamge">
          <img :src="footerLogo" />
          <p>{{ language == 'en' ? getSetting.enTitle : language == 'ru' ? getSetting.rusTitle : getSetting.title }}</p>
        </div>
        <!-- 信息 -->
        <div class="box-info">
          <div class="info-lsit">
            <div class="footer-item">
              <dl>
                <dt>{{ $t("aboutus") }}</dt>
                <dd @click="$router.push({ path: '/about' })" class="link">{{ $t("aboutus") }}</dd>
                <dd @click="$router.push({ path: '/contact' })" class="link">{{ $t("contactus") }}</dd>
              </dl>
            </div>
            <div class="footer-item">
              <dl>
                <dt>{{ $t("productCenter") }}</dt>
                <dd v-if="Object.keys(userInfo).length > 0" @click="$router.push({ path: '/order' })" class="link">{{ $t("member") }}</dd>
                <dd v-else>{{ $t("member") }}</dd>
                <dd>{{ $t("productdemand") }}</dd>
              </dl>
            </div>
            <div class="footer-item">
              <dl>
                <dt>{{ $t("helpCenter") }}</dt>
                <dd>{{ $t("registerhelp") }}</dd>
                <dd>{{ $t("orderhelp") }}</dd>
              </dl>
            </div>
            <div class="footer-item">
              <ul>
                <li style="font-weight:bold; color:#fff;">{{ $t("address") }}</li>
                <li>{{ language == 'en' ? getSetting.enAddress : language == 'ru' ? getSetting.rusAddress : getSetting.address }}</li>
              </ul>
            </div>
          </div>
          <div class="info-list2">
            <div class="footer-item" style="width: 50%;">
              <dl>
                <dd class="link" style="color: #F7F7F7; font-size: 24px;">{{ getSetting.mobile }}</dd>
                <div>{{ $t('serviceHotine') }}</div>
              </dl>
            </div>

            <div class="footer-item">
              <div>
                <div style="display: flex; padding-bottom: 8px;">
                  <i class="el-icon-phone-outline" style="padding-right: 5px;"></i>
                  <div style="color: #F7F7F7;font-size: 14px;">{{ getSetting.mobile }}</div>
                </div>
                <div style="display: flex;">
                  <i class="el-icon-printer" style="padding-right: 5px;"></i>
                  <div style="color: #F7F7F7;font-size: 14px;">{{ getSetting.fax }}</div>
                </div>
              </div>
            </div>
            <div class="footer-item">
              <div>
                <div style="display: flex;  padding-bottom: 8px;">
                  <i class="el-icon-message" style="padding-right: 5px;"></i>
                  <dd style="color: #F7F7F7; font-size: 14px;">{{ getSetting.homeMail }}</dd>
                </div>
                <div style="display: flex;">
                  <i class="el-icon-chat-line-round" style="padding-right: 5px;"></i>

                  <div style="color: #F7F7F7;font-size: 14px;">{{ getSetting.whatsApp }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-line"></div>
    <div style="background: #fff;">
      <div class="copyright">
        <div style="display:flex; justify-content: center;">
          <p v-html="language == 'en' ? getSetting.enCopyRight : language == 'ru' ? getSetting.rusCopyRight : getSetting.copyRight"></p>
          <p>
            <span>
              {{$t('Technic')}}：
              <a href="http://www.hsrj.net" target="_blank">{{$t('Software')}}</a>
            </span>
          </p>
        </div>
        <p style="padding-top: 10px;">{{ $t('Disclaimer') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { TENANTID } from '@/store/mutation-types'
export default {
  data() {
    return {
      footerLogo: require('@/assets/' + TENANTID + '.png')
    }
  },
  computed: {
    ...mapState('operate', ['language']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  }
}
</script>
<style lang="scss" scoped>
.footer-box {
  color: #f7f7f7;
  background: #fe6a00;
  width: 100%;

  & .footer-container {
    padding: 40px 0;

    & .box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .box-iamge {
        width: 221px;
        height: 163px;
        align-items: center;
        display: flex;
        flex-direction: column;

        & img {
          width: 221px;
          height: 163px;
        }

        & p {
          padding-top: 10px;
          font-size: 14px;
          color: #fff;
        }
      }

      & .box-info {
        flex: 1;

        & .info-lsit {
          display: flex;
          flex: 1;
          margin-left: 60px;
          padding-bottom: 30px;
        }

        & .info-list2 {
          display: flex;
          margin-left: 60px;
          padding-top: 10px;
          border-top: 1px solid #fea463;

          & .footer-item {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    & .footer-item {
      width: 25%;

      & dl {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & dt {
          width: 100%;
          font-size: 16px;
          color: #fff;
          line-height: 40px;
          font-weight: bold;
        }

        & dd {
          width: 100%;
          font-size: 14px;
          line-height: 40px;
          color: #f7f7f7;
          &.link {
            cursor: pointer;
          }
        }
      }

      & ul {
        width: 100%;
        display: flex;
        flex-direction: column;

        & li {
          font-size: 16px;
          line-height: 40px;
        }
      }
    }
  }

  & .footer-line {
    background-color: rgba($color: #fff, $alpha: 0.3);
    height: 1px;
    width: 100%;
  }

  & .copyright {
    color: #494949;
    text-align: center;
    font-size: 14px;
    // height: 100px;
    padding: 16px 0;
    & span {
      font-size: 14px;
      & a {
        color: #494949;
        text-decoration: none;
      }
    }
  }
}
</style>