<!--
 * @Description: 
 * @Author: pcc
 * @Date: 2022-12-07 08:59:40
 * @LastEditors: zml
 * @LastEditTime: 2023-03-27 18:06:59
-->
<template>
  <div class="pro-box">
    <page-pro-detail-one :proInfo="proInfo" v-if="proInfo !== null &&  cutTemplates === 'themeTemplateOne'"></page-pro-detail-one>
    <page-pro-detail-tow :proInfo="proInfo" v-if="proInfo !== null && cutTemplates === 'themeTemplateTow'"></page-pro-detail-tow>
    <page-pro-detail-three :proInfo="proInfo" v-if="proInfo !== null && cutTemplates === 'themeTemplateThree'"></page-pro-detail-three>
    <div class="container empty" v-if="proInfo == null">
      <el-empty description="404，你访问的内容不存在"></el-empty>
    </div>
  </div>
</template>
<script>
import { getDetail } from '@/api/product'
// import pageProDetail from '@/components/page/product/proDetail.vue'
import { mapGetters } from 'vuex'
import mixins from '@/utils/mixins'
import transformMark from '@/utils/water'
export default {
  name: 'proDetailPage',
  mixins: [mixins],
  components: {
    // pageProDetail
  },
  data() {
    return {
      proInfo: {}
    }
  },
  computed: {
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    ...mapGetters('operate', {
      getEnquiry: 'get_enquiry'
    })
  },
  watch: {
    $route(to) {
      if (to.name === 'proDetail') {
        this.initData()
      }
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      const param = {
        client_Nu: this.userInfo.client_Nu,
        co_Nu: this.$route.query.coNu
      }

      getDetail(param).then(res => {
        if (res.data) {
          transformMark(res.data.imgUrl).then(result => {
            res.data.imgUrl = result
          })

          // 产品列表
          res.data.imgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })

          // 包装列表
          res.data.packImgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })

          // 实样图列表
          res.data.realImgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })
        }
        this.proInfo = { ...res.data, _num_: false }
        this.getEnquiry[0].list.forEach(item => {
          if (item.co_Nu === res.data.co_Nu) {
            this.proInfo = { ...res.data, _num_: item._num_ }
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pro-box {
  // background-color: #f1f3f6;
  // padding: 15px 0;

  & .page-pro {
    margin-top: 15px;
  }

  & .empty {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>