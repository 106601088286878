<template>
  <!-- 热销产品 -->
  <div class="container product-box" v-if="datas.length > 0">
    <!-- 标题 -->
    <div class="product_title">
      <!-- 标题左侧精灵图 -->
      <img class="title_left_img" style="width: 113px; height: 86px" src="@/assets/cloudLeft1.png" alt="cloud image" />
      <img class="title_mid_img" src="@/assets/bg2.png" alt="baby image" />

      <span class="title_word">{{ $t('hotSaleProduct') }}</span>
      <!-- 标题右侧精灵图 -->
      <img class="title_right_img" style="width: 352px; height: 435px" src="@/assets/star1.png" alt="star1 image" />
    </div>
    <!-- 绿色竖线 -->
    <div class="green_wire">
      <img src="@/assets/wire.png" alt class="img_wire_l" />
      <img src="@/assets/wire.png" alt class="img_wire_r" />
    </div>
    <!-- 列表 -->
    <div class="produce_list" v-if="datas.length > 0">
      <!-- 列表左侧精灵图 -->
      <div class="list_img">
        <img src="@/assets/star3.png" alt="star3 image" />
      </div>
      <!-- 列表中间视图内容 -->
      <div class="list_item">
        <ul>
          <li v-for="(item, index) in datas" :key="index">
            <div class="item_img">
              <img :src="item.imgUrl" alt="baby image" @click="$router.push({ 'path': '/proDetail', query: { coNu: item.co_Nu } })" />
            </div>
            <div class="item_txt">
              <h3>{{ language === 'en'? item.pr_En_Na : language === 'ru' ? item.basicThreeLanguage : item.pr_Na }}</h3>
              <p class="txt_itemNo">{{ $t('itemNo') }} : {{ item.co_Nu }}</p>
              <p class="txt_price">{{ userInfo.symbol }}{{ userInfo.status === 1 ?item.price : $t('viewPrice')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'hotProduct',
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    // 用户信息
    ...mapState('user', ['userInfo']),
    // 语言
    ...mapState('operate', ['language'])
  }
}
</script>

<style lang="scss" scoped>
.product-box {
  // 标题
  .product_title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 790px;
    height: 240px;

    // 标题左侧图片
    .title_left_img {
      position: absolute;
      left: 0;
    }

    // 标题右侧图片
    .title_right_img {
      position: absolute;
      margin-top: 150px;
      right: 0;
    }

    .title_word {
      color: #f5f5f5;
      font-size: 40px;
      position: absolute;
      margin: 0 auto;
      text-align: center;
      padding-top: 40px;
    }

    .title_mid_img {
      width: 100%;
    }
  }

  .green_wire {
    width: 600px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: -65px;
  }

  // 列表
  .produce_list {
    // 列表左侧精灵图
    .list_img {
      width: 171px;
      height: 1102px;
      position: absolute;
      left: 50px;
      top: 82px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    // 列表中间视图内容
    .list_item {
      padding: 2% 7%;
      background: rgba(20, 7, 112, 0.2);
      box-shadow: 0 0 100px #4558ec inset;
      // border: 1px solid #474ce9;
      border-radius: 40px;
      margin-top: -30px;
      box-sizing: border-box;

      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 37px 0;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 360px;
          height: 335px;
          margin-bottom: 3%;
          margin-right: 5%;
          border-radius: 5px;

          &:nth-child(3n) {
            margin-right: 0;
          }

          // 图片
          .item_img {
            background-color: #fff;
            height: 270px;
            width: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 10px;

            img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 270px;
              transition-duration: 0.5s;
              border-radius: 10px;

              &:hover {
                transition-duration: 0.5s;
                transform: scale(1.1);
              }
            }
          }

          // 文字
          .item_txt {
            color: #fff;
            padding: 15px 1%;
            width: 335px;
            height: 53px;

            h3 {
              font-size: 16px;
              font-weight: normal;
              max-height: 60px;
              line-height: 30px;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .txt_itemNo {
              font-size: 14px;
              font-weight: bold;
              height: 25px;
              line-height: 25px;
            }

            .txt_price {
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
