<template>
  <div class="big-box" ref="productRefs">
    <div class="container">
      <advanced-search ref="advanced" @search="advSearch"></advanced-search>
    </div>
    <div class="container">
      <classify-search :datas="getMenus" :active="queryParam.cl_Nu" class="classify-box" @classifyClick="classifyClick"></classify-search>
    </div>
    <div class="container">
      <pro-list :productList="productList" v-if="productList.length > 0"></pro-list>
      <el-empty :description="$t('noData')" v-else class="empty"></el-empty>
    </div>
    <div class="action-box container" v-if="productList.length > 0">
      <div class="check-all">
        <el-checkbox v-model="checkAll" @change="selectAll">{{ $t('checkAll') }}</el-checkbox>
      </div>
      <div class="add-cart" @click="addSelectdToCart">
        <i class="el-icon-shopping-cart-2 selectionCart" :style="{ fontSize: '14px' }"></i>
        {{ $t('checkSelect') }}
      </div>
    </div>
    <div class="container pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParam.pageNo"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParam.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRows"
      ></el-pagination>
    </div>

    <search-pic ref="searchPic" :client_Nu="userInfo.Client_Nu" @ok="tableLoadData"></search-pic>
  </div>
</template>
<script>
import { batchAddApi } from "@/api/cart"
import searchPic from "@/components/themeTemplateTow/pc_components/searchPic/searchPic.vue"
import { queryPage } from '@/api/product'
import { insertSearch } from '@/api/statistics'
import { mapGetters, mapActions } from 'vuex'
import bus from '@/utils/bus'
import advancedSearch from '@/components/themeTemplateTow/pc_components/searchBox/advancedSearch.vue'
import classifySearch from '@/components/themeTemplateTow/pc_components/searchBox/classifySearch.vue'
import proList from '@/components/themeTemplateTow/pc_components/productBox/proList.vue'
import transformMark from '@/utils/water'
export default {
  name: 'pageProduct',
  components: {
    advancedSearch,
    classifySearch,
    proList,
    searchPic, // 图搜
  },
  data() {
    return {
      productList: [],
      queryParam: {
        pageNo: 1,
        pageSize: 20,
        sortField: '',
        sortOrder: '',
        cl_Nu: '',
        isAnd: false,
        conditionals: [],
      },
      totalRows: 0,
      totalPage: 0,
      isImg: false, // 图搜
    }
  },
  computed: {
    ...mapGetters('setting', {
      getMenus: 'get_menus',
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo',
    }),
    // 小选、全选相互影响
    checkAll: {
      set(state) {
        // 将全选的状态值赋值给小选
        this.productList.forEach((item) => item.isCheck = state)
      },
      get() {
        return this.productList.every((item) => item.isCheck === true)
      },
    },
  },
  created() {
    // 路由传参
    this.queryParam.cl_Nu = this.$route.query.clNu || ''
    const keywrods = this.$route.query.keywords || ''
    if (keywrods !== '') {
      this.queryParam.conditionals = this.buildConditionals(keywrods)
    }
    this.initData()
    if (this.$route.query.isImg) {
      this.isImg = this.$route.query.isImg
      this.imgsSearch()
      return
    }
  },
  mounted() {
    // 点击产品重刷数据
    this.$bus.$on('anewClick', () => {
      this.queryParam.cl_Nu = ''
      this.initData()
    })
    // Event Bus分类点击事件
    this.$bus.$on('categoryClick', (cl_Nu) => {
      this.queryParam.cl_Nu = cl_Nu
      this.initData()
    })
    // Event Bus搜索事件
    this.$bus.$on('searchProduct', (keywords) => {
      this.queryParam.conditionals = this.buildConditionals(keywords)
      this.initData()
    })

    // 图搜
    bus.$on('imgSearch', res => {
      if (res) {
        this.isImg = res
        this.imgsSearch()
      }
    })
    this.getActivePageNo()
  },
  destroyed() {
    sessionStorage.removeItem("detail")
  },
  methods: {
    ...mapActions('operate', {
      batchAddCart: 'batch_add_cart'
    }),
    // 初始化数据
    initData() {
      // 带上客户编号
      // this.queryParam.pageNo = Number(sessionStorage.getItem('activePageNo'))
      this.queryParam.client_Nu = this.userInfo.client_Nu || ''
      queryPage(this.queryParam).then((res) => {
        if (res.success) {
          this.productList = res.data.rows || []
          this.productList.forEach(item => {
            transformMark(item.imgUrl).then(result => {
              item.imgUrl = result
            })
          })
          this.totalRows = res.data.totalRows
          this.totalPage = res.data.totalPage
        }
      })
    },
    // 图搜
    imgsSearch() {
      let _this = this
      this.$nextTick(() => {
        _this.$refs.searchPic.openDialog()
      })
    },
    // 统计搜索埋点
    insertKeywords(keywords) {
      if (Object.keys(this.userInfo).length === 0) return
      if (keywords === '') return
      const param = {
        mobile: this.userInfo.mobile,
        client_Na: this.userInfo.client_Na,
        userAccount: this.userInfo.userAccount,
        client_Nu: this.userInfo.client_Nu,
        keywords
      }
      insertSearch(param)
    },
    // 
    tableLoadData(res) {
      this.productList = res.data
      this.totalRows = res.data.length
      this.totalPage = 1
      this.insertKeywords('图搜')
    },

    // 构建复杂查询条件
    buildConditionals(keywords) {
      const conditionals = []
      const fields = ['co_Nu', 'fa_No', 'pr_Na', 'cl_Na', 'pr_En_Na']
      fields.forEach((item) => {
        conditionals.push({
          conditionalType: 1,
          fieldName: item,
          fieldValue: keywords,
        })
      })
      return conditionals
    },
    // 高级查询
    advSearch(conditionals) {
      this.queryParam.cl_Nu = ''
      this.queryParam.conditionals = []
      this.queryParam.conditionals = conditionals
      this.pageNo = 1
      this.initData()
    },
    // 分页大小操作
    handleSizeChange(pageSize) {
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.initData()
    },
    getActivePageNo() {
      let reg = sessionStorage.getItem('detail')
      if (reg) {
        // 从商品详情回来
        let activePageNo = sessionStorage.getItem('activePageNo')
        this.queryParam.pageNo = Number(activePageNo)
      } else {
        // 从其他页面第一次进入列表页，清掉缓存里面的数据
        this.queryParam.pageNo = 1
        sessionStorage.removeItem('activePageNo')
      }
    },
    // 分页翻页操作
    handleCurrentChange(pageNo) {
      sessionStorage.setItem('activePageNo', pageNo);
      this.queryParam.pageNo = pageNo
      this.initData()
    },
    // 分类模块点击操作
    classifyClick(cl_Nu) {
      this.queryParam.cl_Nu = cl_Nu
      this.queryParam.conditionals = []
      this.$refs.advanced.reset()
      this.pageNo = 1
      this.initData()
    },

    // 添加选中的产品到购物车
    addSelectdToCart() {
      // 必须登陆后才能添加购物车
      if (this.userInfo) {
        this.$message({
          message: this.$t('noLogin'),
          type: 'warning'
        })
        return
      }
      // 找出被选中的记录
      const seletedProducts = this.productList.filter(item => item.isCheck === true)
      let newArr = []
      const proLists = []
      // 判断当前页是否选中过产品
      if (seletedProducts.length > 0) {
        seletedProducts.forEach(item => {
          newArr.push({
            co_Nu: item.co_Nu,
            number: 1
          })
          proLists.push({
            client_Nu: this.userInfo.client_Nu,
            userId: this.userInfo.id,
            co_Nu: item.co_Nu,
            fa_No: item.fa_No,
            imgUrl: item.imgUrl,
            in_En: item.in_En,
            number: 1,
            ou_Lo: item.ou_Lo,
            pr_En_Na: item.pr_En_Na,
            pr_Na: item.pr_Na,
            price: item.price
          })
        })
        batchAddApi({
          userId: this.userInfo.id,
          client_Nu: this.userInfo.client_Nu,
          cartItem: newArr
        }).then(res => {
          if (res.success) {
            this.$message({
              message: this.$t('success'),
              type: 'success'
            })
            this.batchAddCart(proLists)
          }
        })
      }

    },
    // 选中当前页全部产品
    selectAll() {
      this.productList.forEach(item => {
        item.isCheck = this.checkAll
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.big-box {
  padding: 10px 0;
}
.classify-box {
  margin-top: 10px;
}

.price-box {
  margin-top: 10px;
}

.product-list {
  margin-top: 10px;
}

.empty {
  margin-top: 10px;
  border-radius: 5px;
}

.action-box {
  background-color: #fff;
  padding: 15px 0;
  display: flex;
  align-items: center;

  & .check-all {
    padding: 0 15px;
  }

  & .add-cart {
    margin-left: 15px;
    color: #ff760e;
    border: 1px solid #ff760e;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;

    & .selectionCart {
      padding-right: 10px;
    }
  }
}

.pagination {
  border-radius: 5px;
  margin-top: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background: #fff;
}

.el-empty {
  background: #fff;
}
</style>