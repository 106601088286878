// 地址表服务接口 

import request from '@/utils/request'


// 分页查询收货地址 
  export function QueryPage(data) {
    return request({
        url: 'front/Address/QueryPage',
        method: 'post',
        data
      })
  }

  // 获取收货地址信息
  export function addressGetInfoApi(data) {
    return request({
        url: 'front/Address/GetInfo',
        method: 'post',
        data
      })
  }

  // 获取默认收货地址
  export function addressGetDefaultInfoApi(data) {
    return request({
        url: 'front/Address/GetDefaultInfo',
        method: 'post',
        data
      })
  }

  // 新增收货地址
  export function addressAddApi(data) {
    return request({
        url: 'front/Address/Add',
        method: 'post',
        data
      })
  }

  // 更新收货地址
  export function addressUpdateApi(data) {
    return request({
        url: 'front/Address/Update',
        method: 'post',
        data
      })
  }

  // 删除收货地址
  export function addressDeleteApi(data) {
    return request({
        url: 'front/Address/Delete',
        method: 'post',
        data
      })
  }
