<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-03-22 17:31:15
-->
<template>
  <div class="order-box">
    <page-order-one v-if="cutTemplates === 'themeTemplateOne'"></page-order-one>
    <page-order-tow v-if="cutTemplates === 'themeTemplateTow'"></page-order-tow>
    <page-order-three v-if="cutTemplates === 'themeTemplateThree'"></page-order-three>
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
export default {
  name: 'orderPage',
  mixins: [mixins]
}
</script>
<style lang="scss" scoped>
.order-box {
  width: 100%;
}
</style>