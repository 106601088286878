<template>
    <div class="logo-box">
        <div class="container logo-container">
            <div class="logo" @click="$router.push({path: '/home'})"><img :src="getSetting.logo" /></div>
            <div class="company-name">
                {{language === "en" ? getSetting.enTitle : language === "ru" ? getSetting.rusTitle :  getSetting.title}}
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters,mapState } from "vuex"
export default {
    name: 'logoBox',
    computed: {
        ...mapGetters("setting", {
            getSetting: 'get_setting'
        }),
        ...mapState('operate', ['language'])
    }
}
</script>
<style lang="scss" scoped>
.logo-box {
    width: 100%;

    & .logo-container {
        display: flex;
        align-items: center;
        height: 120px;
        line-height: 120px;

        & .logo {
            padding: 20px 0;
            height: 80px;
            cursor: pointer;

            & img {
                width: auto;
                height: 80px;
            }
        }

        & .company-name {
            padding-left: 10px;
            flex: 1;
            font-size: 28px;
            line-height: 120px;
            height: 120px;
            overflow: hidden;
            font-weight: bold;
            color: #ca3833;
        }
    }
}
</style>